import React, { startTransition, useEffect, useState } from "react"
import PageHeader from "../../PageHeader";
import NewsItem from "../../NewsItem";
import Page from "../../Page";
import { Link } from "react-router-dom";
import { femida } from "../../../api/app";
import FemidaLoader from "../../FemidaLoader";
import LoadingScreen from "../../LoadingScreen";
import { useRef } from "react";
import Button from "../../Button";

const NewsPage = (props) => {

    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [error, setError] = useState(false);
    const [newsData, setNewsData] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pagesTotal, setPagesTotal] = useState(null);


    useEffect(() => {
        try {
            startTransition(async () => {
                setLoading(true);
                const data = await femida.getNews({limit: 10});
                setNewsData(data);
                setPagesTotal(Number(data?.pagesTotal || 0));
                setLoading(false);
            });
        } catch(e) {
            startTransition(() => {
                setLoading(false);
                setError(true);
            });
        }
    }, []);

    const handleLoadMore = () => {
        try {
            startTransition(async () => {
                setBtnLoading(true);
                const data = await femida.getNews({
                    page: currentPage + 1,
                    limit: 10
                });
                setBtnLoading(false);
                setNewsData({
                    ...newsData,
                    news: [...newsData?.news, ...data?.news]
                });
                setCurrentPage(currentPage + 1);
            });
        } catch (e) {
            setBtnLoading(false);
        }
    }

    return (
        <Page>
            <PageHeader>Юридические новости</PageHeader>
            {
            error ?
            <span>Не удалось загрузить 😢</span> :
            loading ?
            <LoadingScreen /> :
            !newsData ? <></> :
            <div className="d-flex flex-column gap-3">
                {
                    !newsData?.news?.length ? <></> : 
                    newsData.news.map(item=>  
                        <Link to={item.url} key={item.id}>
                            <NewsItem {...item} />
                        </Link>)
                }
                {
                    !(currentPage < pagesTotal) ? <></> :
                    <Button
                    loading={btnLoading} 
                    onClick={handleLoadMore}>Показать еще</Button>
                }
            </div>
            }
        </Page>
    );
}

export default NewsPage;