import { asyncCatcher, fetchData, getDateString, isBase64, PDec } from "./utils";
import Room from './room';

class Chat {

    #API_URL;
    #config;

    constructor(config) {
        const { API_URL } = config;
        this.#config = config;
        this.#API_URL = API_URL;
    }

    getRooms({token, key, user}) { // key = pvs_private_Key
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + '/api/chat/chat/list-room';
            const [data, err] = await fetchData({url, token, payload: 'array'});
            if (err) reject(err);
            else {
                let decrypted = [...data];
                if (data?.length) {
                    decrypted = data.map(room => {
                        if (room?.title) {
                        //if (isBase64(room?.title)) {
                            return {
                                ...room,
                                title: room?.title//PDec(key, room?.title)
                            }
                        }
                        if (room?.message) {
                        //if (isBase64(room?.message)) {
                            return {
                                ...room,
                                message: room?.message//PDec(user.pvs_private_Key, room?.message)
                            };
                        }
                        return room;
                    });
                }
                decrypted.reverse();
                resolve(decrypted);
            };
        });
    }

    getMessages({id, user}) {
        return asyncCatcher(async (resolve, reject) => {
            try {
                const url = this.#API_URL 
                + `/api/chat/chat/list-msg?room_id=${id}&index=0&count=20&order=1`;
                const [data, err] = await fetchData({
                    url, 
                    token: user.token, 
                    payload: 'array'
                });
                if (err) reject(err);
                else {
                    let decrypted = [...data];
                    if (data?.length) {
                        decrypted = data.map(m => {
                            //if (isBase64(m.message)) {
                                //console.log("m.message");
                                //console.log(m.message);
                                return {
                                    ...m,
                                    message: m.message//PDec(user?.pvs_private_Key, m.message)
                                };
                            //} else return m;
                        });
                        const list = [];
                        const dateList = {
                            date: getDateString(decrypted[0]?.msg_createdon),
                            messages: []
                        };

                        decrypted.forEach(m => {
                            const dateString = getDateString(m.msg_createdon);    

                            if (dateString === dateList.date) {
                                dateList.messages.push(m);
                            } else {
                                list.push({...dateList});
                                dateList.date = dateString;
                                dateList.messages = [];
                                dateList.messages.push(m);
                            }
                        });
                        if (dateList.messages.length !== 0) list.push({...dateList});
                        list.reverse();
                        list.forEach(item => item.messages?.reverse());
                        resolve(list);
                    } else {
                        resolve(null);
                    }
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    room(params) { // == femida.room()
        return new Room({
            ...this.#config,
            ...params
        });
    }
}

export default Chat;