import React from "react"

const TextInputGroup = (props) => {

    const { 
        label, 
        placeholder, 
        required, 
        onChange,
        value,
        name
    } = props;

    return (
        <div className="mb-3 d-flex flex-column">
            <label
            className="mb-1" 
            htmlFor={name}>
                <small>{label}</small>
                { required && <small style={{color: 'red'}}>*</small> }
            </label>
            <input
            id={name}
            value={value}
            onChange={onChange}
            name={name}
            required={required}
            placeholder={placeholder}
            className="femida-input" 
            type="text" />
        </div>
    );
}

export default TextInputGroup;