import React, { useState } from "react"
import { Collapse } from "react-bootstrap";
import './styles.scss';
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";
import * as utils from "../../api/utils";

const FemidaCollapse = (props) => {
    
    const {name, children} = props;
    const [open, setOpen] = useState(false);

    return (
        <motion.div
        {...fadeAnimation} 
        className="femida-collapse">
            <div
            role="button" 
            className="femida-collapse__control" 
            onClick={() => setOpen(!open)}>
                <span className="femida-collapse__name">{name}</span>
                <ArrowIcon className={`femida-collapse__arrow ${open}`} />
            </div>
            <Collapse in={open}>
                <div className="mt-2" dangerouslySetInnerHTML={{__html: utils.simpleClean(children)}} />
            </Collapse>
        </motion.div>
    );
}

export default FemidaCollapse;