import React, { startTransition, useContext, useEffect } from "react"
import Page from "../../../Page";
import PageHeader from "../../../PageHeader";
import s from './styles.module.scss';
import face from '../../ProfilePage/assets/avatar.png';
import I from '../assets/icons';
import doc from './assets/doc.jpg';
import { Link, useParams } from "react-router-dom";
import CategoryItem from "../../../CategoryItem";
import Card from "../../../Card";
import { useState } from "react";
import { femida } from "../../../../api/app";
import LoadingScreen from "../../../LoadingScreen";
import { UserContext } from "../../../../context/UserContext";

const SingleLawyerPage = (props) => {

    const [lawyer, setLawyer] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { user } = useContext(UserContext);

    useEffect(() => {
        startTransition(async () => {
            const data = await femida.getLawyer({
                id,
                token: user?.token 
            }).catch(() => setLoading(false));
            setLoading(false);
            setLawyer(data);
        });
    }, []);

    return (
        loading ?
        <LoadingScreen /> :
        <Page>
            <PageHeader />
            <div className="d-flex flex-column gap-3 align-items-center position-relative">
                <span className={s.badge}>{lawyer?.jurist_type}</span>
                <div className={s.imgWrap}>
                    <img src={face} alt="avatar" />
                </div>
                <div className="d-flex gap-2 align-items-center">
                    <b className="category-title">{lawyer?.name}</b>
                    {
                        false &&
                        <span className="d-flex align-items-center gap-1"><I.StarIcon /> 4.8</span>
                    }
                </div>
                <span>Был в сети: 01.02.2021</span>
            </div>
            <div className="d-flex gap-3 my-3">
                <button className="flex-grow-1 femida-btn slim default">Написать</button>
                <button className="flex-grow-1 femida-btn slim default">Позвонить</button>
            </div>
            <small className="text-secondary d-block mt-3 mb-1">Адвокат рег. номер:</small>
            <span>76/1483</span>
            <small className="text-secondary d-block mt-3 mb-1">Место работы</small>
            <span>{lawyer?.address[0]?.city}</span>
            <small className="text-secondary d-block mt-3 mb-1">Время работы</small>
            <span>Пн - Пт	9:00 — 21:00</span>
            <small className="text-secondary d-block mt-3 mb-1">Номер телефона</small>
            <span>78432364144</span>
            <small className="text-secondary d-block mt-3 mb-1">Почта</small>
            <span>mariadenisova@gmail.com</span>
            <small className="text-secondary d-block mt-3 mb-1">Пол</small>
            <span>Женский</span>
            <small className="text-secondary d-block mt-3 mb-1">Дата рождения</small>
            <span>05.07.1999</span>
            <small className="text-secondary d-block mt-3 mb-1">О себе</small>
            <span>{`Квалифицированный юрист с 5-ти летним стажем успешной 
            работы в юридической отрасли, а также судебным юристом. 90% 
            выигранных судебных дел 70% спорных вопросов решаются по моим 
            письменным претензиям в досудебном порядке в пользу клиентов, 
            т.к. претензии составляю “не по шаблону” и не под “копирку”, 
            а юридически грамотные и обоснованные, с индивидуальным подходом 
            к каждой проблеме клиента.`}</span>
            <small className="text-secondary d-block mt-3 mb-1">Образование и опыт</small>
            <span>{`2008 г. Московский государственный открытый университет,
            юридический факультет, специальность – юриспруденция
            2012 г. Статус адвоката (10 лет)`}</span>
            <small className="text-secondary d-block mt-3 mb-1">Документы и сертификаты (6)</small>
            
            <div className={s.listWrap}>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
                <div className={s.docWrap}>
                    <img src={doc} alt="doc" />
                </div>
            </div>

            <div className="d-flex justify-content-between my-3">
                <span className="category-title">Услуги <span className="text-secondary">(25)</span></span>
                <Link to="/" className="femida-link">Смотреть все</Link>
            </div>

            <div className="d-flex flex-column gap-1">
                <CategoryItem 
                name="Юридическая консультация" 
                description="5 000.00 ₽ /час" 
                url="/" />
                <CategoryItem 
                name="Юридическая консультация" 
                description="5 000.00 ₽ /час" 
                url="/" />
                <CategoryItem 
                name="Юридическая консультация" 
                description="5 000.00 ₽ /час" 
                url="/" />
            </div>

            <div className="d-flex justify-content-between my-3">
                <span className="category-title">Отзывы <span className="text-secondary">(25)</span></span>
                <Link to="/" className="femida-link">Смотреть все</Link>
            </div>

            <div className={s.listWrap}>
                <Card
                image={face}
                name="Александра Романова"
                date="28.04.2022"
                text={`Я хотела поблагодарить Марию за 
                помощь нашей семье. Теперь мы можем спать 
                спокойно. Мне очень понравилось что человек
                 не просто занимается твоей проблемой...`} />
                 <Card
                image={face}
                name="Александра Романова"
                date="28.04.2022"
                text={`Я хотела поблагодарить Марию за 
                помощь нашей семье. Теперь мы можем спать 
                спокойно. Мне очень понравилось что человек
                 не просто занимается твоей проблемой...`} />
                 <Card
                image={face}
                name="Александра Романова"
                date="28.04.2022"
                text={`Я хотела поблагодарить Марию за 
                помощь нашей семье. Теперь мы можем спать 
                спокойно. Мне очень понравилось что человек
                 не просто занимается твоей проблемой...`} />
            </div>
        </Page>
    );
}

export default SingleLawyerPage;