import React, { startTransition, useEffect, useState, useContext } from "react";
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import Article from "../../Article";
import Breadcrumbs from "../../Article/Breadcrumbs";
import Header from "../../Article/Header";
import Paragraph from "../../Article/Paragraph";
import { femida } from "../../../api/app";
import { Link, useLocation } from "react-router-dom";
import LoadingScreen from "../../LoadingScreen";
import { UserContext } from "../../../context/UserContext";
import parse from 'html-react-parser';
import FemidaCollapse from "../../FemidaCollapse";
import PageSearchBar from "../../PageSearchBar";
import * as utils from "../../../api/utils";
import { CallCard2 } from "../../CallCard";
import PremiumAccess from "../../PremiumAccess";

const ArticlePage = (props) => {

    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const page = location.pathname.split('/')[1];
    const {user} = useContext(UserContext);
    const [article, setArticle] = useState(null);
    const [questions, setQuestions] = useState(null);
    //const [breadcrumbs, setBreadcrumbs] = useState(null);
	const [isPremium, setIsPremium] = useState(false);
	const [LoadingState, setLoadingState] = useState(0);

    useEffect(() => {
        // article content
        try {
            startTransition(async () => {
				let tokenAPI = localStorage.getItem('tokenAPI');
                const data = await femida.getArticleContent({
                    query: location.pathname,
                    token: tokenAPI
                });
                if (page === 'codex') {
                    //const bc = await femida.getBreadcrumbs(data.id);
                    //setBreadcrumbs(bc);
                }
                let profileData = localStorage.getItem('profile');
				if(profileData){
					profileData = JSON.parse(profileData);
                    let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
                    setIsPremium(LSisPremium);
                }
                setArticle(data);
                setLoading(false);
            });
            
        } catch(e) {
            setLoading(false);
        }
        // questions
        /*if (page !== 'plenum' && page !== 'qa' && page !== 'situation') {
            try {
                (async () => {
                    console.log("location.pathname");
                    console.log(location.pathname);
                    console.log("article");
                    console.log(article);
                    const data = await femida.getArticleQa(location.pathname);
                    setQuestions(data);
                })();
            } catch(e) {
                console.log(e);
            }
        }/**/
        if (page === 'situation') {
            try {
                startTransition(async () => {
                    let locationPathArray = location.pathname.split('/');
                    //locationPathArray[3] = window.atob(locationPathArray[3]);
                    locationPathArray[3] = Buffer
                        .from(locationPathArray[3], 'base64')
                        .toString('ascii');
                    let NumPathname = locationPathArray.join('/');
                    const data = await femida.getArticleQa(NumPathname);
                    setQuestions(data);
                });
            } catch(e) {
                console.log(e);
            }
        }
    }, []);

    return (
        <Page>
            {
                page === 'codex' ?
                <PageHeader>{parse(article?.pageTitle || '')}</PageHeader> :
				page === 'situation' ? 
				<PageHeader>Практические рекомендации</PageHeader> :				
				page === 'qa' ? <>
                    <PageHeader>Вопрос-ответ</PageHeader>
                </>
				 :
                 <>
                <PageHeader />
                 </>
            }
			
            {
                loading ?
                <LoadingScreen /> : 
				<>
                    <Article>
                        {/*
                            page === 'codex' ?
                            <div>
                            {
                                !breadcrumbs?.length ? '' :
                                breadcrumbs.map((b, i) =>
                                    <small key={i} className="d-block mb-1 text-secondary">
                                        {parse(b.title || '')}
                                    </small>)
                            }
                            </div>
                            : page !== 'plenum' && page !== 'qa' ?
                            <Breadcrumbs path={location.pathname} name={article.title} />
                            : <></>
                        */}

                        {user ? <PageSearchBar sTarget={page} /> : <></>}

                        <Header>{page==='situation' ? (article?.title ?( utils.replaceByTpl(article?.title, 'Статья', 'Рекомендации по Статье')|| parse(article?.title || <></>)):<></>):<></>}</Header>
                        
                        {article?.title ? <h5>{article?.title}</h5> : <></>}
                        
                        {(
                            page === 'codex' || 
                            page === 'qa' || 
                            page === 'situation'
                        ) && (article?.content?.length ?
                            ((
                                typeof article.content === 'string'
                            ) ?
                                <>
                                    <div 
                                        className={`${!isPremium && 'text-restricted'}`}
                                    >
                                        {parse(article?.content || '')}
                                    </div>
                                </>
                            :
                                <div className={`${!isPremium && 'text-restricted'}`}>
                                    <ol>{
                                        article?.content.map((p, i) =>
                                            <li key={i}>{parse(p?.content || '')}</li>)
                                    }</ol>
                                </div>
                            )
                        :
                            <>
                                <div className={`${!isPremium && 'text-restricted'}`}>
                                    {parse(article?.content || '')}
                                </div>
                            </>
                        )}
                        {/*
                            !user &&
                            <div className="text-center pb-4 pt-2">
                                <b className="d-block mb-2">Полный текст доступен для просмотра только авторизованным пользователям.</b>
                                <a href="/login"><button className="femida-btn">Авторизироваться</button></a>
                            </div> 
                        /**/}	
                    
                        <PremiumAccess CheckForPremium={true}/>
                            
                    </Article>
                    {isPremium && questions &&
                        <>
                            <div className="category-title mt-5 mb-2">Вопрос-ответ</div>
                            {
                            questions.map(q => 
                                <div key={q.id} className="mb-2">
                                    <FemidaCollapse name={q.title}>
                                        {q.content}
                                    </FemidaCollapse>
                                </div>)
                            }
                        </>
                    }	
                    <CallCard2 />
                </>
            }


        </Page>
    );
}

export default ArticlePage;