import React from "react"
import { useRef } from "react";
import { useEffect } from "react";
import DateBadge from "./DateBadge";
import s from './s.module.scss';

const MessageList = ({ messages, roomTitle }) => {

    const anchorRef = useRef(null);
    useEffect(() => {
        if (anchorRef.current) {
            anchorRef.current.scrollIntoView();
        }
    });

    return (
        <div className={s.msgListWrap}>
            <div className={s.msgList}>
                {
                    !roomTitle? <></> :
                    <DateBadge date={`Тема: ${roomTitle}`} />
                }
                {
                    !messages?.length && false ? <></> :
                    messages?.map((e, i) =>
                    <DateBadge key={e.date} {...e} />)
                }
                <div ref={anchorRef} />
            </div>
        </div>
    );
}

export default MessageList;