import React from "react"

const Header = (props) => {

    const {children} = props;

    return (
        <h2 className="category-title mb-2 mt-4 fs-5">{children}</h2>
    );
}

export default Header;