import React from "react"
import { useNavigate } from "react-router-dom";
import './styles.css';
import BackSvg from './back.svg'; 

const BackButton = (props) => {

    const { onClick, solid } = props;
    const navigate = useNavigate();
    
    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate(-1);
        }
    }

    return (
        <div 
        role="button"
        onClick={handleClick}
        {...props} 
        className={`back-btn ${solid?'solid':''}`}>
            <img src={BackSvg} alt="back" />
        </div>
    );
}

export default BackButton;