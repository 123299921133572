import React, { startTransition, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import pageData from '../CategoryPage/data';
import PageHeader from "../../PageHeader";
import SearchBar from "../../SearchBar";
import CategoryItem from "../../CategoryItem";
import Page from "../../Page";
import { femida } from "../../../api/app";
import LoadingScreen from "../../LoadingScreen";
import FemidaCollapse from "../../FemidaCollapse";
import { UserContext } from "../../../context/UserContext";
import PageSearchBar from "../../PageSearchBar";

const SingleDocsCategoryPage = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { catid } = useParams();
    const path = location.pathname.split('/')[1];
    const {user} = useContext(UserContext);

    const [items, setItems] = useState(null);
    const cards = pageData[path]?.cards;
    const header = catid && cards?.length ? cards.filter(i => i.url === catid)[0].name : '';
    useEffect(() => {
        /*if (!user) {
            navigate('/login', { replace: true });
            return;
        } */
        try {
            startTransition(async () => {
				           // const url = this.#API_URL + utils.apiUrlFromQuery(query);
				
				let token = localStorage.getItem('tokenAPI');
				//alert(token);
                let headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);
                const res = await fetch("https://api.my-advo.cat/doc/document/list?parent_id=10", { 
				method: "POST",
				data: {parent_id: '10'},
				headers 
				});
                let data = await res.json();
                const list = Object.keys(data.payload).map(key => 
                    data.payload[key]);
					
				
				
				
				
				
               /* const data = await femida.getCategoryContent({
                    query: location.pathname,
                    token: user?.token
                });*/
                setItems(list);
            });
        } catch (e) {
            console.log(e);
        }        
    }, []);


    return (
        <Page>
            {
                path === 'qa' ?
                <PageHeader>Вопрос-ответ</PageHeader> :
                <PageHeader>{header}</PageHeader>
            }		
            <PageSearchBar sTarget={path} />
			{ /*<SearchBar />*/ }
            <div className="my-4 d-flex flex-column gap-2">
                {
                    items && items?.length ?
                    items.map((item, i) => {
                        item.parent_url = catid;
                        if (path === 'qa') {
                            return (
                                <FemidaCollapse key={i} name={item.title}>
                                    {item.content}
                                </FemidaCollapse>
                            );
                        } else if (path === 'doc') {
                            return <CategoryItem 
                            key={i}
                            description={item.description}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.name} />							
                        } else if (path === 'docs') {
                            return <CategoryItem 
                            key={i}
                            description={item.description}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.name} />
                        } else {
                            return <CategoryItem key={i} {...item} />
                        }
                    })
                    : items?.length === 0 ?
                    <div className="mt-5 text-center">Пусто</div>
                    : <LoadingScreen />
                }
            </div>
        </Page>
    );
}

export default SingleDocsCategoryPage;