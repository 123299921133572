import React from "react"
import CategoryCard from "../../CategoryCard";
import CategoryList from "../../CategoryList";
import Page from "../../Page";
import PageHeader from "../../PageHeader";
import icons from '../MainPage/Services/icons/icons';
import PageSearchBar from "../../PageSearchBar";

const ServicesPage = (props) => {
    const data = [
        { 
            name: "Практические правовые рекомендации", 
            description: "",
            Icon: icons.CaseIcon,
            url: '/situation/'
        },
        { 
            name: "Кодексы, законы, судебная практика",
            Icon: icons.CodexIcon,
            url: '/codex/'
        },		
        { 
            name: "Вопрос-ответ",
            Icon: icons.QuestionIcon,
            url: '/qa/'
        },
        { 
            name: "Формы документов",
            Icon: icons.DocsIcon,
            url: '/docs/'
        },
        { 
            name: "Юристы, адвокаты, нотариусы по РФ",
            Icon: icons.LawyerIcon,
            url: '/lawyer/'
        },		
        { 
            name: "Справочная информация",
            description: "",
            Icon: icons.InfoIcon,
            url: '/info/'
        },
        { 
            name: "Call-центр",
            Icon: icons.CourtIcon,
            url: '/call/'
        },
        { 
            name: "Юридические новости",
            Icon: icons.ConstitutionIcon,
            url: '/news/'
        },
    ];
    return (
        <Page>
            <PageHeader>Сервисы</PageHeader>
			<PageSearchBar sTarget="all" />
            <div className="category-inner-wrapper">
                <CategoryList>
                    {
                        data.map((item, i) => <CategoryCard key={i} {...item} />)
                    }
                </CategoryList>
            </div>
        </Page>
    );
}

export default ServicesPage;