import React from "react"
import { ReactComponent as HomeIcon } from './assets/home.svg';  
import { ReactComponent as AppealIcon } from './assets/appeal.svg';  
import { ReactComponent as ProfileIcon } from './assets/profile.svg';  
import { ReactComponent as ServiceIcon } from './assets/services.svg';  
import { ReactComponent as CallIcon } from './assets/call.svg';  
import './styles.scss';
import { useLocation, useNavigate } from "react-router-dom";

const Navigation = () => {

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const handleProfileClick = () => {
        navigate('/profile');
    }

    return (
        <>
        <div className="nav-space"></div>
        <div className="nav" style={{
            padding: "10px",
            justifyContent: "space-between"
        }}>
            <div 
            role="button" 
            className={`nav-button ${pathname === '/' ? 'active' : ''}`} 
            onClick={() => navigate('/')}>
                <HomeIcon className="nav-icon-solid" />
                <small className="nav-text">Главная</small>
            </div>
            <div 
            role="button" 
            className={`nav-button ${pathname === '/services' ? 'active' : ''}`} 
            onClick={() => navigate('/services')}>
                <ServiceIcon  className="nav-icon-solid" />
                <small className="nav-text">Сервисы</small>
            </div>
            <div 
            role="button" 
            className={`nav-button ${pathname === '/chat' ? 'active' : ''}`} 
            onClick={() => navigate('/chat')}>
                <AppealIcon  className="nav-icon-outline" />
                <small className="nav-text">Чат</small>
                { false && <span className="nav-button__badge">5</span> }
            </div>
            <div 
            role="button" 
            className={`nav-button ${pathname === '/call' ? 'active' : ''}`} 
            onClick={() => navigate('/call')}>
                <CallIcon  className="nav-icon-solid" />
                <small className="nav-text">Позвонить</small>
            </div>
            <div 
            role="button" 
            className={`nav-button ${pathname === '/profile' ? 'active' : ''}`} 
            onClick={handleProfileClick}>
                <ProfileIcon className="nav-icon-solid" />
                <small className="nav-text">Профиль</small>
            </div>
        </div>
        </>
    );
}

export default Navigation;