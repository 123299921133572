import React from "react";
import { Link } from "react-router-dom";
import CallCard from "./CallCard";
import Header from "./Header";
import Cases from "./Cases";
import Services from "./Services";
//import ServicesItems from "./ServicesItems";
import News from "./News";
import { motion } from 'framer-motion';
import { fadeAnimation } from "../../../utils/animationSetting";
import Offer from "./Offer";
import Navigation from "../../Navigation";
import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";
import iconCodex from './Services/icons/codex.svg';
import iconQuestion from './Services/icons/question.svg';
import iconCase from './Services/icons/case.svg';
import iconDocs from './Services/icons/docs.svg';
import iconPromoLogo from './Offer/promo-logo.svg';
import './ServicesItems/styles.css';

const HomescreenPrompt = () => {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = React.useState(false);

    const hide = () => setVisibleState(false);

    React.useEffect(
        () => {
        if (prompt) {
            setVisibleState(true);
        }
        },
        [prompt]
    );

    if (!isVisible) {
        return <div className="d-none" />;
    }

    return (
        <div className="style_AddToHomescreenPrompt_wrapper" onClick={hide}>
            <button className="style_AddToHomescreenPrompt_close" onClick={hide}>Закрыть</button>
            <div className="style_AddToHomescreenPrompt_text">Приветствуем! Добавить на главный экран?</div>
            <button className="style_AddToHomescreenPrompt_accept" onClick={promptToInstall}>Да</button>
        </div>
    );
}

const MainPage = (props) => {
    return (
        <motion.div {...fadeAnimation}
        className="d-flex flex-column gap-2 mainpage-wrapper">
            {/*HomescreenPrompt()/**/}
            <Header />
			<div className=" dflex justify-content-between align-items-center" 
				style={{
					textAlign:"center",
					border:"2px solid rgba(4, 182, 245, 0.16)",
					padding:"15px 15px 0",
					borderRadius:"15px",
				}}>
					{/*<h1 className="category-title">МОБИЛЬНАЯ ПРАВОВАЯ СИСТЕМА<br/>«МОЙ АДВОКАТ»</h1>*/}
				<p>
					<h5 style={{fontSize: "1rem", lineHeight: "1.3rem", marginBottom: ".8rem"}}>
						Правовая помощь гражданам: практические рекомендации опытных юристов и адвокатов.<br/>
						Попали в трудную ситуацию?<br />Мы поможем!</h5>
					<a href="/about/" title="Подробнее о нас" className="femida-link" style={{fontSize: ".95rem"}}>Подробнее о правовой системе</a>
				</p>				
					
			</div>
			<CallCard />
			
			<div className="col-12 mt-3 mb-3 about_wrapper section-padding overflow-hidden d-none">
				<div className="row g-0">
					<div className="col-12 mt-0">
						<div className="about_content about_content_2">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h2 className="category-title">О нас</h2>
								<a className="femida-link" href="/about/" title="Подробнее о нас">Подробнее</a>
							</div>
							<h3>Квалифицированная, оперативная, конфиденциальная юридическая помощь людям, попавшим в трудную жизненную ситуацию.</h3>
							<p>«Мой адвокат» — это правовая система, где каждый найдет ответ по любому юридическому вопросу от опытных адвокатов и юристов по всей РФ.</p>
							<div className="row mt-4 g-0">
								<div className="col-3 author_img"></div>
								<div className="col-9" style={{paddingLeft: "15px"}}>
									<p className="author_desc">Получите консультацию в кратчайшие сроки в нашей круглосуточной службе поддержки.</p>
									<div className="row g-0">
										<div className="col-5 g-0">
											<img style={{maxHeight: "39px"}} src="https://my-advo.cat/assets/images/panel/signature.svg" alt="Подпись Вячеслава Чулкова" />
										</div>						
										<div className="col-7">
											<div className="author_name"><h5>Вячеслав Чулков</h5><small>(адвокат)</small></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/*<div className="row">
				<h2 className="category-title mb-1 mt-3">Наши сервисы</h2>
				<div className="col-12 my-2">
					<a href="/situation/">
					<div className="media arrow-hover" style={{border: "2px solid #04b6f529", borderRadius: "1rem"}}>
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
							<img src={iconCase} alt="" />
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-1">Практические рекомендации</h6>
							<span>Cоветы о том, как вести себя в экстренных ситуациях.</span>
						</div>
					</div>
					</a>
				</div>

				<div className="col-12 my-2">
					<a href="/qa/">
					<div className="media arrow-hover" style={{border: "2px solid #04b6f529", borderRadius: "1rem"}}>
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
							<img src={iconQuestion} alt="" />
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-1">Вопрос-ответ</h6>
							<span>Ответы на повседневные правовые вопросы.</span>
						</div>
					</div>
					</a>
				</div>
				<div className="col-12 my-2">
					<a href="/docs/">
					<div className="media arrow-hover" style={{border: "2px solid #04b6f529", borderRadius: "1rem"}}>
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
							<img src={iconDocs} alt="" />
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-1">Формы документов</h6>
							<span>Образцы и шаблоны типовых юридических документов.</span>
						</div>
					</div>
					</a>
				</div>
				<div className="col-12 my-2">
					<a href="/codex/">
					<div className="media arrow-hover" style={{border: "2px solid #04b6f529", borderRadius: "1rem"}}>
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
							<img src={iconCodex} alt="" />
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-1">Кодексы, законы, судебная практика</h6>
							<span>Юридическая библиотека в актуальной редакции.</span>
						</div>
					</div>
					</a>
				</div>			
				<div className="col-12 my-2 d-none">
					<div className="media arrow-hover">
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
						
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-0">Кодексы, законы, судебная практика</h6>
							<span>Юридическая библиотека</span>
						</div>
					</div>
				</div>
				<div className="col-12 my-2 d-none">
					<div className="media arrow-hover">
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
						
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-0">Формы документов</h6>
							<span>Образцы типовых правовых документов</span>
						</div>
					</div>
				</div>
				<div className="col-12 my-2 d-none">
					<div className="media arrow-hover">
						<div className="arrow-icon text-white"></div>
						<div className="only-icon only-icon-lg text-primary">
						
						</div>
						<div className="media-body ps-4 align-self-center">
							<h6 className="mb-0">Call-центр</h6>
							<span>Чаты, аудио/видеозвонки онлайн.</span>
						</div>
					</div>
				</div>
				<Link to="/services/" className="femida-link">Все сервисы</Link>			
			</div>/**/}
	
            <Services />
			{/*<ServicesItems />*/}
			<Offer />
			{/*<Cases />*/}			
            {/*<News />*/}	
            {/*<Navigation />*/}
            <div className="promo-content">
                <a href="/call/" className="promo-trigger">
                    <div className="promo-trigger-img" aria-haspopup="true"><img src={iconPromoLogo} alt="" /></div>
                    <div className="promo-trigger-text" style={{width: "0"}}>Проблемы?<br/>Звоним юристу</div>
				</a>
			</div>			
        </motion.div>
    );
}

export default MainPage;