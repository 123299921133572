 import React, { useRef, useState } from "react"
import { Link, useNavigate, Navigate } from "react-router-dom";
import Page from "../../Page";
import { useEffect } from "react";
import PageHeader from "../../PageHeader";
import { InputMask } from '@react-input/mask';
import validator from 'validator/validator.js'
import LoadingScreen from '../../LoadingScreen';

let APIendpoint = "https://api.my-advo.cat";
const FeedbackPage = (props) => {

    
    const feedback_form = useRef(null);

    // feedback form fields Refs
    const feedback_message = useRef(null);
    const feedback_name = useRef(null);
    const feedback_email = useRef(null);
    const feedback_phone = useRef(null);
    const feedback_privpol = useRef(null);
    const feedback_submit_bttn = useRef(null);

    // captcha Refs
    const yook_captcha = useRef(null);
    const yook_captcha_text = useRef(null);
    const yook_captcha_hash = useRef(null);

    // form submit states
    const [feedbackSubmited, setFeedbackSubmited] = useState(false);
    const [feedbackOnsubmit, setFeedbackOnsubmit] = useState(false);
    const [feedbackSubmitAble, setFeedbackSubmitAble] = useState(true);


    // validation Err states
    const [feedbackMessageErr, setFeedbackMessageErr] = useState("none");
    const [feedbackNameErr, setFeedbackNameErr] = useState("none");
    const [feedbackPhoneErr, setFeedbackPhoneErr] = useState("none");
    const [feedbackEmailErr, setFeedbackEmailErr] = useState("none");
    const [feedbackPrivpolErr, setFeedbackPrivpolErr] = useState("none");
    const [feedbackCaptchaErr, setFeedbackCaptchaErr] = useState("none");

    
    useEffect(() => {
        getUserInfo();
        loadCaptchaImage();
    }, []);

    const navigate = useNavigate();

    const feedback_submit = (e) => {
        e.preventDefault();
        if(feedback_validation() === true)return false;
        setFeedbackOnsubmit(true);
        
        let captcha_err = "none",
            feedback_submited = false;

        let FedbackForm = feedback_form.current;
        const data = new FormData(FedbackForm)
        fetch(APIendpoint + `/api/myac/main/feedback-submit/`, { method: 'POST', body: data })
          .then(res => res.json())
          .then(json => {
            console.log(json);
            if(json.status == 400){
                console.log(json.payload.message);
                captcha_err = "block";
                loadCaptchaImage();
                yook_captcha_text.current.value = "";
            }else if(json.status == 200){
                feedback_submited = true;
            }   
            setFeedbackCaptchaErr(captcha_err);
            setFeedbackSubmited(feedback_submited);
            setFeedbackOnsubmit(false);
          });
    }

    
    const loadCaptchaImage = () => {
        yook_captcha.current.src="/logo512.png";
        let url = APIendpoint+"/api/captcha/captcha/ver1";
        fetch( url )
        .then( response =>response.json() )
        .then( resp=>{
            yook_captcha.current.src = "data:image/png;base64,"+resp.payload.imagedata;
            yook_captcha_hash.current.value = resp.payload.captchaHash
        } );
    }

    const stringFormat = (value, pattern) => {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    const getUserInfo = () => {
        if(localStorage.getItem('tokenAPI') == null)return false;
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let url = APIendpoint + '/api/user/get-profile';
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if (json.status == 200) {
                    if(json.payload.name !== undefined)
                    feedback_name.current.value = json.payload.name;

                    if(json.payload.email !== undefined)
                    feedback_email.current.value = json.payload.email;

                    if(json.payload.phones !== undefined){
                        let phones = "";
                        if(typeof (json.payload.phones) === 'string' && json.payload.phones.length > 0)
                            phones = stringFormat(json.payload.phones, '############');
                        feedback_phone.current.value = phones;
                    }
                }
            });
    }

    const modify = (input) => {
        return { mask: input[0] === '0' ? '+_ (___) ___-__-__' : undefined };
      };
      

    const feedback_validation = () => {
        let text = feedback_message.current.value;
        let name = feedback_name.current.value;
        let email = feedback_email.current.value;
        let phone = feedback_phone.current.value;
        let privpol = feedback_privpol.current;
        let submit_able = false;
        let message_err = "block";
        let name_err = "block";
        let privpol_err = "block";
        let email_err = "block";
        let phone_err = "block";
        if(
            text.length <= 3 || 
            text.length == "" || 
            text === undefined
            )submit_able = true;
        else message_err = "none";

        if(
            name.length <= 3 || 
            name.length == "" || 
            name === undefined
            )submit_able = true;
        else name_err = "none";
        
        if(privpol.checked === false) submit_able = true;
        else privpol_err = "none";

        if (validator.isEmail(email) === false) submit_able = true; 
        else email_err = "none";

        if (validator.isMobilePhone(phone) === false) submit_able = true; 
        else phone_err = "none";
        setFeedbackSubmitAble(submit_able);
        setFeedbackMessageErr(message_err);
        setFeedbackNameErr(name_err);
        setFeedbackPhoneErr(phone_err);
        setFeedbackEmailErr(email_err);
        setFeedbackPrivpolErr(privpol_err);
        return submit_able;
    }
    

    return (
        <Page>
            <PageHeader>Обратная связь</PageHeader>
            {feedbackOnsubmit ? 
                <LoadingScreen />
            : <></>}

                <div className={"mt-4"+(feedbackOnsubmit?" d-none":"")}>
                                    {feedbackSubmited == false ? 
                                    <>
                                    <div className="card mt-3 mb-5">
                                        <div className="card-body">
                                            <h4>Просто напишите нам!</h4>
                                            <p>Если у вас есть какие-либо вопросы или предложения, мы ответим вам в ближайшее время, мы всегда рады вам.</p>
                                        </div>
                                    </div>
                                        <form 
                                            className="" 
                                            action="/" 
                                            method="POST" 
                                            onSubmit={(e)=>feedback_submit(e)}
                                            ref={feedback_form}
                                        >
                                            <div className="row mb-3"> 
                                                <div className="col-md-6">
                                                    <label htmlFor="feedback_name" className="form-label">
                                                        <small className="d-block mb-2">Имя <span className="form-required">*</span></small>
                                                    </label>
                                                    <input type="text" 
                                                    ref={feedback_name} 
                                                    onKeyUp={(e)=>{feedback_validation()}} 
                                                    className={"femida-input-control"+(feedbackNameErr == "block"?" is-danger":"")} 
                                                    name="name"  
                                                    id="feedback_name" placeholder="Иван" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="feedback_fam" className="form-label">
                                                        <small className="d-block mb-2">Фамилия</small>
                                                    </label>
                                                    <input type="text" className="femida-input-control" name="fam"  id="feedback_fam" placeholder="Иванов" />
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="feedback_email" className="form-label">
                                                        <small className="d-block mb-2">Электронная почта <span className="form-required">*</span></small>
                                                    </label>
                                                    <input 
                                                        type="email"
                                                        inputMode="email"
                                                        className={"femida-input-control"+(feedbackEmailErr == "block"?" is-danger":"")} 
                                                        name="email"
                                                        id="feedback_email"
                                                        placeholder="my@mail.ru"
                                                        ref={feedback_email}
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="feedback_phone" className="form-label">
                                                        <small className="d-block mb-2">Номер телефона <span className="form-required">*</span></small>
                                                    </label>
                                                    <InputMask 
                                                        modify={modify} 
                                                        replacement={{ _: /\d/ }}
                                                        mask="+___________"
                                                        type="tel"
                                                        inputMode="tel"
                                                        className={"femida-input-control"+(feedbackPhoneErr == "block"?" is-danger":"")} 
                                                        name="phone"
                                                        id="feedback_phone"
                                                        placeholder="+___________"
                                                        ref={feedback_phone}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="mb-3">
                                                <label htmlFor="feedback_message" className="form-label">
                                                    <small className="d-block mb-2">Сообщение <span className="form-required">*</span></small>
                                                </label>
                                                <textarea 
                                                    id="feedback_message" 
                                                    name="message" 
                                                    className={"femida-input-control"+(feedbackMessageErr == "block"?" is-danger":"")} 
                                                    ref={feedback_message}
                                                    rows="6"
                                                    placeholder="Ваш отзыв или предложение" 
                                                    onKeyUp={(e)=>{feedback_validation()}} 
                                                    required
                                                ></textarea>
                                                <div className="invalid-feedback" style={{display:feedbackMessageErr}}>
                                                    Пожалуйста, введите сообщение в текстовое поле.
                                                </div>
                                            </div>

                                            <div className={"yook-customer-captcha text-center"}>
                                                <img 
                                                    className={"img-thumbnail mt-4" + (feedbackCaptchaErr == "block"?" is-danger":"")} 
                                                    id="yook-captcha"
                                                    style={{height:"104px"}}
                                                    onClick={()=>loadCaptchaImage()}
                                                    ref={ yook_captcha }
                                                    src={"/assets/images/icons/loader1.gif"} />
                                                {/*}<div onClick={()=>loadCaptchaImage()}
                                                    style={{cursor:"pointer"}}>
                                                    Обновить
                                                </div>{/**/}
                                                <div className="row justify-content-center mt-3">
                                                    <div className="col-md-7">
                                                        <input 
                                                            type="hidden" 
                                                            className={"d-none"} 
                                                            id="yook-captcha-hash"
                                                            ref={ yook_captcha_hash }
                                                            name="captchaHash"/>
                                                        <input 
                                                            type="text" 
                                                            className={"femida-input-control yook-captcha-text" + (feedbackCaptchaErr == "block"?" is-danger":"")} 
                                                            id="yook-captcha-text"
                                                            ref={ yook_captcha_text }
                                                            name="captcha" 
                                                            placeholder="Капча" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-center mb-4 mt-3">
                                                <div className="form-check">
                                                    <input
                                                        id="feedback_privpol" 
                                                        name="privpol" 
                                                        ref={feedback_privpol} 
                                                        onChange={(e)=>{feedback_validation()}} 
                                                        type="checkbox" 
                                                        className={"form-check-input me-2"+(feedbackPrivpolErr == "block"?" is-danger":"")}
                                                        style={{float:"none"}}
                                                        value="1"
                                                        required 
                                                    />
                                                    <label className="form-check-label" htmlFor="feedback_privpol">Принять политику конфиденциальности</label>
                                                    <div className="invalid-feedback" style={{display:feedbackPrivpolErr}}>Сообщение не будет отправлено, без принятия "Политики конфиденциальности"</div>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                {feedbackOnsubmit == true ? <>
                                                    <img 
                                                        className={"img-thumbnail mt-4"} 
                                                        style={{height:"50px"}}
                                                        src={"/logo512.png"} />
                                                </>:
                                                <>
                                                <button 
                                                    type="submit"
                                                    ref={feedback_submit_bttn} 
                                                    className="femida-btn"
                                                    disabled={(feedbackSubmitAble)}
                                                >Отправить
                                                </button>
                                                </>}
                                            
                                            </div>
                                        </form>
                                    </> 
                                    : 
                                    <>
                                        <h3 className=''>Сообщение отправлено</h3>
                                        <p>В ближайшее время мы ознакомимся с Вашим письмом и при необходимости отправим ответ на указаный Вами адрес электронной почты.<br />Спасибо, что пользуетесь нашим сервисом. </p>
                                        <a 
                                            href="/"
                                            className="femida-btn"
                                        >На главную
                                        </a>
                                    </>}
                </div>
        </Page>
    );
}

export default FeedbackPage;