import React from "react"
import s from './styles.module.scss';
import I from '../../assets/icons';
import { formatTime } from "../../utils";
import { shortenFileName } from "../MediaPreview/utils";

const Message = (props) => {

    const { 
        id_user,
        id_user_sender,
        msg_createdon,
        message,
        message_type,
        filename
    } = props;

    const you = id_user === id_user_sender;
    let downloadLink = '';
    if (message_type === 'file') {
        downloadLink = process.env.REACT_APP_DOWNLOAD + message;
    }

    return (
        <div className={you ? s.yourMsg : s.theirMsg}>
            <div className={you ? s.yourContent : s.theirContent}>
                { message_type === 'text' ? <span>{message}</span> : '' }
                {
                    message_type === 'image' ?
                    <div className={s.imgWrap}>
                        <img src={`https://api.my-advo.cat/${message}`} alt="img" />
                    </div>
                    : '' 
                }
                {
                    message_type === 'file' ?
                    <a href="/" className="d-flex gap-2 align-items-center">
                        <I.FileIcon />
                        <div>
                            <small className="fw-bold d-block">{shortenFileName(filename)}</small>
                            <a 
                            href={downloadLink || '/'}
                            rel="noreferrer" 
                            target="_blank"><b>Скачать</b></a>
                        </div>
                    </a>
                    : ''
                }
                {
                    message_type === 'video' ?
                    <div className={s.imgWrap}>
                        <video src={`https://api.my-advo.cat/api/chat/chat/get-image?link=` + message} loading={"lazy"} controls style={{ width: "100%", height: "240px", background: "#000"}} />
                        {/*<br /><a href={`https://api.my-advo.cat/api/chat/chat/get-image?link=` + message} target="_blank" style={{ maxWidth: "500px", marginLeft: "10px", display:"block"}}>Скачать</a>/**/}
                    </div>
                    : '' 
                }
                {
                    message_type === 'audio' ?
                    <div className={s.imgWrap}>
                        <audio src={`https://api.my-advo.cat/` + message} controls style={{ margin: "5px 5px 0", minWidth: "280px"}}  loading={"lazy"}/>
                        {/*<br /><a href={`https://api.my-advo.cat/` + message} target="_blank" style={{ maxWidth: "500px", margin: "5px 5px 5px 10px", display:"block"}}>Скачать</a>/**/}
                    </div>
                    : '' 
                }
                {
                    message_type === 'call' ?
                    <span>
                        Просмотреть запись звонка можно в полной версии сайта
                    </span>
                    : '' 
                }
            </div>
            <small className="text-secondary">{formatTime(msg_createdon)}</small>
        </div>
    );
}

const arePropsEqual = (prev, next) => {
    return prev.content === next.content;
};

export default React.memo(Message, arePropsEqual);

// https://api.my-advo.cat/
// •