import React from "react"
import s from './styles.module.css';

const Article = (props) => {

    const {children} = props;

    return (
        <div className={s.article}>
            {children}
        </div>
    );
}

export default Article;