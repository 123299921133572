import React, { startTransition } from "react"
import { useParams } from "react-router-dom";
import PageHeader from "../../../PageHeader";
import Article from "../../../Article";
import Image from "../../../Article/Image";
import Header from "../../../Article/Header";
import Paragraph from "../../../Article/Paragraph";
import Page from "../../../Page";
import { useState } from "react";
import { useEffect } from "react";
import { femida } from "../../../../api/app";
import LoadingScreen from "../../../LoadingScreen";
import { getImageUrl } from "../../../NewsItem/utils";
import parse from "html-react-parser";

const SingleNewsPage = (props) => {

    const { alias } = useParams();
    const [loading, setLoading] = useState(false);
    const [newsData, setNewsData] = useState(null);

    useEffect(() => {
        try {
            startTransition(async () => {
                setLoading(true);
                const data = await femida.getNews(alias);
                setNewsData(data);
                setLoading(false);
            });
        } catch(e) {
            setLoading(false);
        }
    }, []);

    return (
        <Page>
            <PageHeader children="Юридические новости" />
            {
            loading ?
            <LoadingScreen /> :
            <Article>
                <Image src={getImageUrl(newsData?.file)} alt={newsData?.title} />
                <Header>{newsData?.title}</Header>

                {parse(newsData?.full_text || '')}

            </Article>
            }
        </Page>
    );
}

export default SingleNewsPage;