import React, { startTransition, useRef, useContext, useEffect, useState } from "react";
import { fadeAnimation } from "../../../utils/animationSetting";
import Page from "../../Page";
import { PageHeader2 } from "../../PageHeader";
import validator from 'validator/validator.js'
import { InputMask } from '@react-input/mask';

import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import LoadingScreen from "../../LoadingScreen";
import TarifItem from "./TarifItem"
import { isString } from "react-pdf/dist/umd/shared/utils";
import { PayInfoAccordion } from '../../InfoAccordion';


let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + token);
let APIendpoint = "https://api.my-advo.cat";
let WindowOpenTarget = "_blank";
const TarifPage = (props) => {

    const navigate = useNavigate();

    const yook_customer_email = useRef(null);
    const yook_customer_phone = useRef(null);
    const yook_captcha = useRef(null);
    const yook_captcha_text = useRef(null);
    const yook_captcha_hash = useRef(null);
    const yook_privpol = useRef(null);
    const yook_YooLink = useRef(null);

    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [TarifList, setTarifList] = useState(null);
    const [PriceId, setPriceId] = useState(null);
    const [PageTitle, setPageTitle] = useState("Оплата");
    const [IdLastPaydTariff, setIdLastPaydTariff] = useState(null);
    const [FormErrors, setFormErrors] = useState({
        email: false,
        phone: false,
        captcha: false,
        privpol: false,
    });
	
    useEffect(() => {
        startTransition(() => {
            if (!user) {navigate('/login', {replace: true});return;}
            try {
                getPriceIdFromUrl();
                setLoading(true);
                setTimeout(() => {
                    getUserInfo();
                    tarifs_list();
                    setLoading(false);
                }, 300);
                document.addEventListener("deviceready", ()=>{
                    //setPlatformDevice(window.cordova.platformId);
                    console.log("Cordova Loaded");
                    console.log(window.cordova.platformId);
                    window.open = window.cordova.InAppBrowser.open;
                    //WindowOpenTarget = "_system";
                }, false);/**/
        
            } catch (e) {
                setLoading(false);
            }
        });
    }, []);

    

    const getPriceIdFromUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const price_id = queryParams.get("price_id");
        setPriceId(price_id);
    }
    
    const getUserInfo = () => {
        let token = localStorage.getItem('tokenAPI');
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + token);
        let url = APIendpoint + '/api/user/get-profile';
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    if(json.payload.email !== undefined)
                        yook_customer_email.current.value = json.payload.email;


                    if(json.payload.phones !== undefined){
                        let phones = "";
                        if(isString(json.payload.phones) === true && json.payload.phones.length > 0)
                            phones = stringFormat(json.payload.phones, '############');
                        yook_customer_phone.current.value = phones;
                    }
                    loadCaptchaImage();
                }
            });
    }

    const tarifs_list = () => {
        let url = APIendpoint + "/api/prices/list";
        fetch(url, { 
            method:'GET',
            headers:headers,
        })
            .then(response=>response.json())
            .then((json)=>{
                if(json.payload){
                    setTarifList(json.payload.list); 
                    tarifs_action();
                    setLoading(false);				
                }else{
                    setLoading(false);
                }
            });
    }

    

    const loadCaptchaImage = () => {
        yook_captcha.current.src="/logo512.png";
        let url = APIendpoint+"/api/captcha/captcha/ver1";
        fetch(url)
        .then( response =>response.json() )
        .then(resp=>{
            yook_captcha.current.src = "data:image/png;base64,"+resp.payload.imagedata;
            yook_captcha_hash.current.value = resp.payload.captchaHash
        });
    }

    const stringFormat = (value, pattern) => {
        let i = 0;
        const v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
    }

    

    const pay_order_submit = () => {
        let self = this;
        let formErrorState = false;
        let formErrors = {
            email: false,
            phone: false,
            captcha: false,
            privpol: false,
        };
        if(!PriceId){
            alert("Не выбран тариф");
            return ;
        }
        if(yook_privpol.current.checked === false){
            formErrorState = true;
            formErrors.privpol = true;
        }
        if(yook_customer_email.current.value === ""){
            formErrorState = true;
            formErrors.email = true;
        }else{
            if (validator.isEmail(yook_customer_email.current.value) === false) {
                formErrorState = true;
                formErrors.email = true;
             }
        }
        if(yook_customer_phone.current.value === ""){
            formErrorState = true;
            formErrors.phone = true;
        }else{
            if (validator.isMobilePhone(yook_customer_phone.current.value) === false) {
                formErrorState = true;
                formErrors.phone = true;
            }
        }
        
        if(formErrorState === true){
            setFormErrors(formErrors);
            return false;
        }

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let url = APIendpoint + "/api/pay/yookassa-pay/yook-create-payment";
        let request = {
            price_id: PriceId,
            email: yook_customer_email.current.value,
            phone: yook_customer_phone.current.value,
            captcha : yook_captcha_text.current.value,
            captchaHash : yook_captcha_hash.current.value,
            ismobile : true
        };
            fetch(url, { 
                method:'POST',
                headers:headers,
                body: JSON.stringify(request)
            })
            .then(response=>response.json())
            .then((json)=>{
                if(json.status === 200){
                    if(json.payload !== null){
                        if(json.payload.confirmation_url){
                            console.log("json.payload.confirmation_url");
                            console.log(json.payload.confirmation_url);
                            let PlatformName = "other";
                            if(window.cordova !== undefined){
                                PlatformName = window.cordova.platformId;
                                console.log(window.cordova.platformId);
                            }
                            if(PlatformName === "ios"){

                                setTimeout(() => {
                                    console.log(yook_YooLink.current.innerHTML);
                                    console.log(json.payload.confirmation_url);
                                    yook_YooLink.current.href = json.payload.confirmation_url;
                                    yook_YooLink.current.click();
                                }, 1000);
                            }else{
                                window.open(json.payload.confirmation_url, WindowOpenTarget, 'location=yes');
                            }/**/
                            //window.open(json.payload.confirmation_url, WindowOpenTarget, 'location=yes');
                                
                        }
                    }
                }else{
                    formErrorState = true;
                    formErrors.captcha = true;
                    setFormErrors(formErrors);
                }
            });
        
    }

    
    const tarifs_action = () => {
        let url  = APIendpoint + '/api/token/check-token';
        fetch(url, { headers: headers })
            .then( response =>response.json() )
            .then((response)=>{
                let status = parseInt(response.status);
                if(status === 200){
                    url  = APIendpoint + '/api/pay/yookassa-pay/get-id-last-payd-tariff';
                    fetch( url, { method:'GET', headers: headers } )
                        .then( response =>response.json() )
                        .then( (response)=>{
                            if(response.payload !== null && response.status === 200)
                                if(response.payload.price_id !== null && response.payload.price_id)
                                    setIdLastPaydTariff(response.payload.price_id);
                        } );
                }else{
                    navigate('/login', {replace: true});
                    return;}
            })
    }

    


    let Style_wrapper = {
        minHeight: "100vh",
        backgroundColor: "#2B3A4C",
        margin:"0 -1rem"
    };

    const modify = (input) => {
        return { mask: input[0] === '0' ? '+_ (___) ___-__-__' : undefined };
    };

    return (
        <div style={Style_wrapper}>
            {loading ?
                <LoadingScreen />
                :
                <Page {...fadeAnimation}>

                    <PageHeader2>{PageTitle}</PageHeader2>

                    <div className="row">
                        <div className="col-md-12 text-white">
                            <p className="p-3 pb-0 mt-0 mb-0">Выберите тариф и пройдите процедуру оплаты. Получите неограниченный доступ к юридической поддержке в приложении Мой Адвокат.</p>
                        </div>
                    </div>
                    <form 
                        action={APIendpoint + "/api/pay/yookassa-pay/yook-create-payment"}
                        method="POST"
                    >
                        <div className={"d-flex justify-content-between align-items-center mb-0 mt-4 "}>
                            {TarifList ? <>
                                <div className="container">
                                    <div className="row">
                                        {TarifList.map((item, i) => 
                                            <TarifItem 
                                                key={i}
                                                setPriceId = {setPriceId} 
                                                repeatPay = {IdLastPaydTariff === item.id}
                                                selectedPrice = {parseInt(PriceId) === item.id}
                                                {...item} 
                                            />)
                                        }
                                    </div>
                                </div>
                            </> : <>
                                <h3>Нет доступных тарифов</h3>
                            </>}
                        </div>
                        
                        <div className={"row"+(PriceId?"":" d-none")}>
                            <div className="col-md-12 mb-3">
                                <div className="">
                                    <div className="m-3">
                                        <small className="d-block mb-2 text-white">Почта <span className="form-required">*</span></small>
                                        <input 
                                            name="email" 
                                            ref={yook_customer_email}
                                            type="email"
                                            inputMode="email"
                                            autoComplete="off"
                                            className={"femida-input-control" + (FormErrors.email?" is-danger":"")} />
                                    </div>
                                    <div className="m-3">
                                        <small className="d-block mb-2 text-white">Номер телефона <span className="form-required">*</span></small>
                                        <InputMask 
                                            type="tel"
                                            inputMode="tel"
                                            mask="+7__________"
                                            modify={ modify } 
                                            replacement={{ _: /\d/ }}
                                            name="phones"
                                            autoComplete="off"
                                            ref={yook_customer_phone}
                                            className={`femida-input-control` + (FormErrors.phone?" is-danger":"")}
                                        />                                            
                                        </div>
                                        <div className={"yook-customer-captcha text-center text-white m-3"}>
                                            <img 
                                                className={"img-thumbnail mt-4" + (FormErrors.captcha?" is-danger":"")} 
                                                id="yook-captcha"
                                                style={{ height: "104px" }}
                                                ref={ yook_captcha }
                                                alt=""
                                                onClick={()=>loadCaptchaImage()}
                                                src={"/logo512.png"} />
                                            <div className="d-none"
                                            onClick={()=>loadCaptchaImage()}
                                                style={{cursor:"pointer"}}>Обновить</div>
                                            <div className="row justify-content-center mt-3">
                                                <div className="col-12 text-start">
                                                    <input 
                                                        type="hidden" 
                                                        className={"d-none"} 
                                                        id="yook-captcha-hash"
                                                        ref={ yook_captcha_hash }
                                                        name="captchaHash"/>
                                                    <small className="d-block mb-2 text-white">Капча <span className="form-required">*</span></small>
                                                    <input 
                                                        type="text" 
                                                        className={"femida-input-control yook-captcha-text" + (FormErrors.captcha?" is-danger":"")} 
                                                        id="yook-captcha-text"
                                                        ref={ yook_captcha_text }
                                                        name="captcha" 
                                                        autoComplete="off"
                                                        placeholder="Капча" />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="mb-3 form-check text-center">
                                    <label 
                                        style={{color:"#fff"}}  
                                        className="form-check-label" 
                                        htmlFor="privpol_accept">
                                        <input 
                                            type="checkbox"
                                            ref={ yook_privpol }
                                            style={{
                                                marginRight:"10px",
                                                height:"30px",
                                                width:"30px"
                                            }}
                                            className={"form-check-input " + (FormErrors.privpol?" is-danger":"")} 
                                            id="privpol_accept"/>
                                        Я принимаю условия <a href="/privpol/" style={{color:"rgb(0, 184, 242)"}} title="Политика конфиденциальности" target="_blank">политики <br />конфиденциальности</a>
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 mb-5">
                                <div className="price_sale_btn_wrapper price_sale_pay pt-2 text-center">
                                    <button 
                                        type="submit"
                                        className="btn femida-btn mt-1 mb-1 text-white mt-2"
                                        onClick={ (e)=>{ e.preventDefault(); pay_order_submit(); return false; } }
                                                                        
                                    >
                                        <h4 className="ps-5 pe-5 m-0 pt-2 pb-2 text-center">Оплатить</h4>
                                    </button>
                                    <a href="" ref={yook_YooLink} id="paypage_YooLink" style={{display:"none"}}>Перейти к оплате</a>
                                    
                                    <div className="text-center"><noindex><small className="text-secondary">Оплата проводится в системе ЮКасса</small></noindex></div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="col-md-12 pb-3 tarif_page_accordion">
                        <PayInfoAccordion />
                    </div>
                </Page>
            }
        </div>
    );
}

export default TarifPage;