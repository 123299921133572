import React from "react"
import { Link } from "react-router-dom";
import LogoSvg from './assets/logo.svg';
//import { ReactComponent as BellIcon } from './assets/bell.svg';
import PageSearchBar from "../../../PageSearchBar";
import { PageSearchBar2 } from "../../../PageSearchBar";
import './styles.css';

const Header = (props) => {
    return (
	<>
<div className="mainpage-header" style={{borderBottom: "1px solid #e9e9e8", paddingBottom: "1rem"}}>
            <Link to="/" className="mr-2">
                <img src={LogoSvg} alt="Мой Адвокат" />
				<span style={{verticalAlign: "bottom", marginLeft: "1rem",marginBottom: "0", paddingBottom: "0", textTransform: "uppercase", fontSize: "1.8rem", fontWeight: "700", letterSpacing: ".04rem", color: "rgb(43,58,76)"}}>Мой адвокат</span>
            </Link>
            {/*<div className="search-bar-wrap flex-grow-1">
				<PageSearchBar sTarget="all" />
</div>*/}
            {/*<div role="button">
                <BellIcon />
            </div>*/}
        </div>
		<div className="search-bar-wrap --flex-grow-1">
				<PageSearchBar sTarget="all" />
        </div>
	</>
    );
}

export const Header2 = (props) => {

    let style = {
        header:{
            borderBottom: "1px solid #e9e9e8", 
            paddingBottom: "1rem"
        },
        headerLink: {
            display: "flex",
            flexDirection: "row"
        },
        title: {
            verticalAlign: "bottom", 
            marginLeft: "1rem",
            marginBottom: "0", 
            paddingBottom: "0", 
            textTransform: "uppercase", 
            fontSize: "1.67rem", 
            fontWeight: "700", 
            letterSpacing: ".04rem", 
            color: "rgb(43,58,76)",
            marginTop: "-5px"
        },
        subTitle: {
            verticalAlign: "bottom", 
            marginLeft: "1rem",
            marginBottom: "0", 
            paddingBottom: "0", 
            textTransform: "uppercase", 
            fontSize: ".605rem", 
            fontWeight: "700", 
            letterSpacing: ".04rem", 
            color: "rgb(43,58,76)",
            marginTop: "-3px"
        }
    };

    return (
	<>
        <div className="mainpage-header" style={style.header}>
            <Link to="/" className="mr-2" style={style.headerLink}>
                <img src={LogoSvg} alt="Мой Адвокат" />
                <div className="mainpage-header-title-wrapper">
                    <div style={style.title}>Мой адвокат</div>
                    <div style={style.subTitle}>Трудная ситуация? Мы поможем!</div>
                </div>
            </Link>
        </div>
		<div className="search-bar-wrap --flex-grow-1 mt-3">
				<PageSearchBar2 sTarget="all" />
        </div>
	</>
    );
}

export default Header;