import React, { useState } from "react"
//import Button from "../../../Button";
//import Page from "../../../Page";
//import PageHeader from "../../../PageHeader";
import "./tarifitem.css";
import {normalizeCountForm} from "../../../../api/utils"

const TarifItem = (props) => {
    let style = {
        label: {
            position: "relative",
            display: "block",
            background: "#2B3A4C",
            //boxShadow: "rgba(0, 0, 0, 0.27) 0px 0px 11px 0px",
            marginBottom: "15px",
            //height: "365px",
            borderRadius: "5px"
        },
        situationTitle: {
            color: "rgb(255, 255, 255)",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "right",
            padding: "8px 5px",
            background: "rgb(0, 186, 255)",
            position: "absolute",
            bottom: "50px",
            right: "0px"
        },
        card: {height: "100%"}
    };
    console.log(props);

    let styleBackground = (props.repeatPay===true ? "rgba(255,255,255,.2)" : "rgba(255,255,255,.1)"),
        styleColor = (props.repeatPay===true ? "rgb(43, 58, 76)" : "#fff");
        
        if(props.selectedPrice) styleBackground = "rgb(0 184 242)";

        let CountOfDay = Math.ceil(props.sand_time / (60*60*24));
        let CountOfDayTitle = normalizeCountForm(CountOfDay, ["день","дня","дней"]);
        
    return (
    <div className={
        "col-12 col-sm-12 mb-2"+
        (props.selectedPrice?" price_selected":"")
        }
        style={{display:(props.active == 2?"none":"")}}>
        <label 
            htmlFor={"price_sale_" + props.id} 
            className="situation_money grow" 
            style={style.label}>
            <input 
                id={"price_sale_" + props.id}
                className="form-check-input d-none mt-0"
                type="radio"
                onChange={()=>{ if(props.active == 2)props.setPriceId(props.id); }}
                name="price_id[]"
                value={props.id}
                aria-label={props.name} 
            />
            <div className="situation_title d-none" style={style.situationTitle}>{props.name}</div>
            <div className="card" style={{
                height: "100%",
                //background: "rgba(255,255,255,.1)"
                background: styleBackground,
                color: styleColor,
            }}>
                <div className="card-body text-center">

                        <div className="price_sale_icon"></div>

                        <h4 className="text-white mb-0">
                            {props.name}
                        </h4>

                        <div className="text-white mt-0 mb-2">
                            ({CountOfDay + " " + CountOfDayTitle})
                        </div>

                        <div className="price_sale_price">
                            {props.price} руб
                        </div>
                        <div className="col-12" dangerouslySetInnerHTML={{__html: props.description}} />
                </div>
            </div>
        </label>
    </div>
    );
}

export default TarifItem;