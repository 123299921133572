import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import EyeSvg from '../../../assets/eye-svg.svg';
import VkSvg from '../../../assets/vk.svg';
import GoogleSvg from '../../../assets/google.svg';
import Button from "../../Button";
import { motion } from 'framer-motion';
import { fadeAnimation } from "../../../utils/animationSetting";
import BackButton from "../../BackButton";
import { femida } from "../../../api/app";
import { UserContext } from "../../../context/UserContext";
import {FormErrors} from "./FormErrors";
import {FormMsg} from "./FormMsg";

const SignUpPage = (props) => {

    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const [loginEntered, setLoginEntered] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({
        login: '',
        password: '',
        "repeat-password": ''
    });
    const [errors, setErrors] = useState({
        login: '',
        password: '',
        "repeat-password": ''
    });	

    const [isSubmitting, setIsSubmitting] = useState(false);
	
    const [formResponceErrors, setFormResponceErrors] = useState({error: ''});	
	const [formResponceMsg, setFormResponceMsg] = useState({msg: ''});

/*
      function login() {
        console.log('No errors, submit callback called!');
	    console.log(values);
        if (loginEntered) {		
        try {
            setLoading(true);
            const user =  femida.signUpWithCredentials(values); //femida.authWithCredentials(values);
			console.log(user);
			alert('user');
            setLoading(false);
           //setUser(user);
            localStorage.setItem('user_state', JSON.stringify(user));

            navigate('/login');
        } catch(e) {
            console.log(e);
			//setError(true);
            alert('Ошибка регистрации');
			setLoading(false);
        }
		        } else {
            setLoginEntered(true);
        }
  }	*/
  

    const handleChange = (e) => {
		
        if (e.target.name === "login") {
			
	        if (errors && errors.login) {
		    errors.login = '';
	        if (!e.target.value) {
                errors.login = 'Поле обязательно для заполнения.';
            } else if (!/\S+@\S+\.\S+/.test(e.target.value)) {
                errors.login = 'Укажите корректный электронный адрес.';
			}				
        setErrors({
            ...errors,
            [e.target.name]: errors.login || ''
        });  
	} else {
        setErrors({
            ...errors,
            [e.target.name]: ''
        }); 
    }		
			


  }
  
console.log(' after errors from hc==');
console.log(errors);
console.log('== after errors from hc');  
    if (e.target.name === "password") {
	  
	    if (errors && errors.password) {
		    errors.password = '';	  
	  
	  
	if (!e.target.value) {
    errors.login = 'Поле обязательно для заполнения.';
  } else if (e.target.value.length < 6) {
    errors.password = 'Минимальная длина пароля - 6 символов.';
  }

        setErrors({
            ...errors,
            [e.target.name]: errors.password || ''
        });  
	} else {
        setErrors({
            ...errors,
            [e.target.name]: ''
        }); 
    }		
  } 


    if (e.target.name === "repeat-password") {
	    if (errors && errors["repeat-password"]) {
		    errors["repeat-password"] = '';		
		
		
            let el = document.querySelector('input[name="password"]');
  
            if (!e.target.value) {
                errors["repeat-password"] = 'Поле обязательно для заполнения.';
            } else if (e.target.value !== el.value) {
                errors["repeat-password"] = 'Указанные пароли не совпадают.';
            }
  
	
            setErrors({
                ...errors,
                [e.target.name]: errors["repeat-password"] || ''
            });  
	} else {
        setErrors({
            ...errors,
            [e.target.name]: ''
        }); 
    }			
        }

      if (Object.values(errors).join('').length === 0 /*&& isSubmitting*/) {

		//setLoginEntered(true);
		setIsSubmitting(true);

    } else {
		  //setErrors({errors});
		  setIsSubmitting(false);
	}
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        }); 
    }


    const handleSubmitBtn = async () => {
		setFormResponceErrors({
            error: ''
        });
		setFormResponceMsg({
            msg: ''
        });
		
		if (loginEntered) {
			
			errors.password = '';
		
            let el = document.querySelector('input[name=password]');
	        if (!el.value) {
                errors.password = 'Поле обязательно для заполнения.';
            } else if (el.value.length < 6) {
                errors.password = 'Минимальная длина пароля - 6 символов.';
            }
            setErrors({
                ...errors,
                password: errors.password || ''
            });
 			
			errors["repeat-password"] = '';	
			
            let el2 = document.querySelector('input[name="repeat-password"]');
	        if (!el2.value) {
                errors["repeat-password"] = 'Поле обязательно для заполнения.';
            } else if (el2.value !== el.value) {
                errors["repeat-password"] = 'Указанные пароли не совпадают.';
            }
            setErrors({
                ...errors,
                "repeat-password": errors["repeat-password"] || ''
            });
			
            console.log(errors);

            if (Object.values(errors).join('').length === 0) {
		        setIsSubmitting(true);
            } else {
		        setIsSubmitting(false);
	        }

		}  else {
			
            //setErrors({});
		    //let errors = {};
			errors.login = '';
            let el = document.querySelector('input[name=login]');
	        if (!el.value) {
                errors.login = 'Поле обязательно для заполнения.';
            } else if (!/\S+@\S+\.\S+/.test(el.value)) {
                 errors.login = 'Укажите корректный электронный адрес.';
            } 
	        
                setErrors({
                    ...errors,
                    login: errors.login || ''
                }); 

      if (errors.login.length === 0 /*&& isSubmitting*/) {
		setIsSubmitting(true);
    } else {
		  setIsSubmitting(false);
	}
	
		}

		if  (Object.values(errors).join('').length === 0 && isSubmitting) {
            if (loginEntered) {
                
                try {
                    setLoading(true);
                    const user = await femida.signUpWithCredentials(credentials);
                    setUser(user);
                    localStorage.setItem('user_state', JSON.stringify(user));
                    setLoading(false);
                    
                    let successStr = "<span class=\"text-center\">На указанный электроннный адрес отправлена ссылка для подтверждения регистрации."
                    + "<br/><a href='/login'>Авторизироваться</a></span>";
                    setFormResponceMsg({
                        msg: successStr
                    });
                
                } catch(e) {
                    setLoading(false);
                    console.log(e);// errorMessage
                    
                    let errorStr = "Внимание! Регистрация не пройдена: "
                    + e.payload.errorMessage
                    + "<br>Проверьте корректность указанных данных и повторите попытку.";						

                    setFormResponceErrors({
                        error: errorStr
                    });
                }

            } else {
                setLoginEntered(true);
            }
			}  else {
                setLoading(false);
				let errorStr = "<span class=\"text-center\">Внимание! Необходимо корректно заполнить все обязательные поля.</span>";						

				setFormResponceErrors({
                    error: errorStr
                });
                return false;				
			}		
    }

    const handleBackBtn = () => {
        if (loginEntered) setLoginEntered(false);
        else navigate('..');
    }

    const oauth2 = async (type) => {
        try {
            setLoading(true);
            let user = null;
            switch(type) {
                case 'google':
                    user = await femida.authWithGoogle();
                    break;
                case 'vk':
                    user = await femida.authWithVk();
                    break;
                default:
                    break;
            }
            
            if (!user) throw new Error();
            
            console.log(user);

            setLoading(false);
            setUser(user);
            localStorage.setItem('user_state', JSON.stringify(user));
        } catch(e) {
            setLoading(false); 
            console.log(e);
            alert('Ошибка авторизации')
        }
    }

    return (
        <motion.div {...fadeAnimation}
        className="d-flex flex-column align-items-stretch justify-content-center py-5 gap-4">
					{(formResponceMsg.msg).length > 5 ?
			<>
                <motion.div {...fadeAnimation}
                    className="d-flex flex-column align-items-center gap-3" 
                    key="2">
                        <h2 className="mb-0">Завершите регистрацию</h2>
                        
                </motion.div>
				<div className="panel panel-default" id="form_result">
                    <FormMsg formMsg={formResponceMsg} />
                </div>	
			</>
			:
            <>			
		    {
            loginEntered 
			?
			<>
            <div>
                <BackButton onClick={handleBackBtn} />
            </div>
			</>
			: <></>
			}


                {
                    loginEntered ?
                    <motion.div {...fadeAnimation} key="1">
					    <div className="d-flex flex-column align-items-center gap-3">
						    <h2 className="mb-0">Придумайте пароль</h2>
						</div>
                    </motion.div>
                    : 
                    <motion.div {...fadeAnimation}
                    className="d-flex flex-column align-items-center gap-3" 
                    key="2">
                        <h2 className="mb-0">Регистрация</h2>
                        <span className="font-weight-light text-secondary text-center">Введите почту, чтобы зарегистрироваться.</span>
                        <b className="w-100 text-center">Уже есть аккаунт? <Link className="femida-link" to="/login">Войти</Link></b>
                    </motion.div>
                }

			<div className="panel panel-default" id="form_result">
                <FormErrors formErrors={formResponceErrors} />
				<FormMsg formMsg={formResponceMsg} />
            </div>				

            <motion.form layout transition={{ type: "just" }} className="d-flex flex-column gap-3 w-100 align-items-stretch">
                {
                    !loginEntered ?
                    <motion.label {...fadeAnimation} key="login-label">
                        <small className="d-block mb-2">Электронная почта <span className="form-required">*</span></small>
                        <input
                        type="text"
                        autoComplete="off" 
						className={`femida-input-control ${errors.login && 'is-danger'}`}
                        onChange={handleChange} 
						value={credentials.login || ''} 
						required="required"
                        name="login"
                        placeholder="Введите e-mail"
						maxLength={255}
                        />
                    </motion.label>
                    :
                    <>
                    <motion.label {...fadeAnimation} key="password-label">
                        <small className="d-block mb-2">Пароль <span className="form-required">*</span></small>
                        <div className="position-relative">
                            <input
                            type={passwordShown ? "text" : "password"}
                            className={`femida-input-control ${errors.password && 'is-danger'}`}
                            name="password"
                            value={credentials.password || ''}
                            onChange={handleChange}
                            placeholder="Введите пароль"
                            required="required"
							maxLength={255}
                            />
                            <img 
                            src={EyeSvg} 
                            onClick={() => setPasswordShown(!passwordShown)}
                            className="femida-input__eye" 
                            alt="показать/скрыть" />
                        </div>
                    </motion.label>
                    <motion.label {...fadeAnimation} key="passwordConfirm-label">
                        <small className="d-block mb-2">Подтвердите пароль <span className="form-required">*</span></small>
                        <div className="position-relative">
                            <input
                            type={passwordShown ? "text" : "password"}
                            className={`femida-input-control ${errors['repeat-password'] && 'is-danger'}`}
                            name="repeat-password"
                            value={credentials['repeat-password'] || ''}
                            onChange={handleChange}
                            placeholder="Подтвердите пароль"
							required="required"
							maxLength={255}
                            />
                            <img 
                                src={EyeSvg} 
                                onClick={() => setPasswordShown(!passwordShown)}
                                className="femida-input__eye" 
                                alt="показать/скрыть" />
                        </div>
                    </motion.label>
                    </>
                }
                <Button 
                className="femida-btn mt-2"
                loading={loading}
                disabled={credentials.login.trim().length === 0 || loading}
                onClick={handleSubmitBtn} 
                type="button">{loginEntered ? "Зарегистирироваться" : "Продолжить"}</Button>
            </motion.form>
            {
                !loginEntered &&
                <motion.div {...fadeAnimation}
                transition={{ duration: 0.3 }}
                key="oauth" 
                className="d-flex flex-column w-100 align-items-stretch gap-4">
				{ /*
                    <span className="text-center">Или зарегистрироваться через:</span>
                    <div className="d-flex gap-3 justify-content-center">
                        <div
                        onClick={() => oauth2('vk')} 
                        role="button"><img className="oauth-img" src={VkSvg} alt="vk"/></div>
							<div
                        onClick={() => oauth2('google')} 
			role="button"><img className="oauth-img" src={GoogleSvg} alt="google"/></div>
                    </div>
				*/ }
                        <small className="text-secondary">
                            Регистрируясь, Вы
                            <ul>
                                <li>соглашаетесь с условиями в отношении <a href="/privpol">обработки персональных данных</a>,</li>
                                <li>получаете возможность приобретать услуги нашего портала по подписке,</li>
                                <li>обязуетесь соблюдать правила нашего портала и закон об авторском праве.</li>
                            </ul>
                        </small>					
                </motion.div>
            }
						</>

			}
        </motion.div>
    );
}

export default SignUpPage;