import React from "react"
import { Link } from "react-router-dom";
import './styles.scss';

const CategoryLine = (props) => {
    const {name, Icon, description, url} = props;
    return (
        <Link to={url} className="category-card-line">
		<div className="row g-0">
		    <div className="col-12">
			    <div className="row g-0">
				    <div className="col-2">
                        <Icon className="" />
			        </div>
					<div className="col-10 category-card-line-body">
                        <p dangerouslySetInnerHTML={{__html: name}}></p>
                        {description && 
                            <>
                            <span className="text-secondary category-card-line__description" dangerouslySetInnerHTML={{__html: description}}></span>
							</>
                        }
			        </div>
			    </div>
			</div>
        </div>			
        </Link>
    );
}

export default CategoryLine;