import React from "react"
import './styles.css';
import { motion } from "framer-motion";
import { slideDownAnimation } from "../../utils/animationSetting";

const Notification = (props) => {

    const {children} = props;

    return (
        <motion.div 
        {...slideDownAnimation}
        drag="y"
        className="notification-wrap"
        dragConstraints={{bottom: 0}}>
            <div className="notificaton">
                <span className="text-white">{children}</span>
            </div>
        </motion.div>
    );
}

export default Notification;