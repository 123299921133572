import React from "react"
import { formatTime } from "../../../utils";
import Message from "../../Message";
import s from '../s.module.scss';

const DateBadge = ({date, messages}) => {
    return (
        <>
            <div className={s.dateBadge}>{date}</div>
            {
                !messages?.length ? <></> :
                messages.map(m => <Message key={m.msg_id} {...m} />)
            }
        </>
    );
}

export default DateBadge;