import React, { useContext, useEffect, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import LogInPage from "./components/pages/LogInPage";
import MainPage from './components/pages/MainPage';
import MainPage2 from './components/pages/MainPage2';
import RequisitesPage from './components/pages/RequisitesPage';
import SignUpPage from "./components/pages/SignUpPage";
//import VerifyByEmail from "./components/pages/SignUpPage/VerifyByEmail";
import NewsPage from "./components/pages/NewsPage";
import CategoryPage from "./components/pages/CategoryPage";
import SingleCategoryPage from "./components/pages/SingleCategoryPage";
import SingleDocsCategoryPage from "./components/pages/SingleDocsCategoryPage";
import ArticlePage from "./components/pages/ArticlePage";
import TemplatePage from "./components/pages/TemplatePage";
import TarifsPage from "./components/pages/TarifsPage";
import TarifPage from "./components/pages/TarifPage";
import YooPayResultPage from "./components/pages/TarifPage/YooPayResultPage";
import ProfilePage from "./components/pages/ProfilePage";
import Navigation from "./components/Navigation";
import EditProfilePage from "./components/pages/ProfilePage/EditProfilePage";
import ChangePassPage from "./components/pages/ProfilePage/ChangePassPage";
import PremiumPage from "./components/pages/ProfilePage/PremiumPage";
import ServicesPage from "./components/pages/ServicesPage";
import AboutPage from "./components/pages/AboutPage";
import PolicyPage from "./components/pages/PolicyPage";
import ChatPage from "./components/pages/ChatPage";
import FeedbackPage from "./components/pages/FeedbackPage";
import RoomPage from "./components/pages/ChatPage/RoomPage";
import SingleLawyerPage from "./components/pages/LawyerPage/SingleLawyerPage";
//import InfoPage from "./components/pages/LawyerPage";
import InfoPage from "./components/pages/InfoPage";
import CodexPage from "./components/pages/CodexPage";
import SingleCodexPage from "./components/pages/CodexPage/SingleCodexPage";
import { UserContext } from "./context/UserContext";
import CreateRoomPage from "./components/pages/ChatPage/CreateRoomPage";
import SingleNewsPage from "./components/pages/NewsPage/SingleNewsPage";
import CallPage from "./components/pages/CallPage";
import Auth from "./components/pages/Auth";
import PullToRefresh from 'pulltorefreshjs';
import SearchPage from "./components/pages/SearchPage";
import AppPopupWindow from "./components/AppPopupWindow";
import CheckToken from "./components/CheckToken";
import SpaceToBottom from "./components/SpaceToBottom";
import RootDivScrollTop from "./components/RootDivScrollTop";

import { faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const App = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    let wrapper_style = {};
    //const [platformDevice, setPlatformDevice] = useState(null);

    useEffect(() => {
        
        if (location.pathname.includes('profile') && !user) {
            navigate('/login');   
        }

		if (location.pathname.includes('/is/')) {
            navigate('/lawyer/');   
        }

        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let DesctopBrowserLinux = window.navigator.platform.indexOf("Linux");
        let BrowserVendorGoogle = window.navigator.vendor.indexOf("Google");
        if(
            isSafari === true && 
            DesctopBrowserLinux === false && 
            BrowserVendorGoogle === false
            ){
            let j = document.createElement("script");
            j.src = "/assets/cordova/cordova.js";
            document.body.appendChild(j);/**/
        }
        localStorage.removeItem('isMobileApplication');
        document.addEventListener("deviceready", ()=>{
            //setPlatformDevice(window.cordova.platformId);
            console.log("Cordova Loaded");
            console.log(window.cordova.platformId);
            localStorage.setItem('isMobileApplication', window.cordova.platformId);
        }, false);/**/

        /*let root_div = document.querySelector("#root>div");
        console.log("root_div.scrollTop");
        console.log(root_div.scrollTop);
        root_div.scrollTo(0,0);/**/
        

            /*PullToRefresh.init({
                mainElement: 'body',
                instructionsRefreshing: "Обновляем...",
                instructionsReleaseToRefresh: "Отпусти...",
                instructionsPullToRefresh: "Потяни вниз, для обновления...",
                onRefresh() {
                    let DoPullToRefresh = true;
                    if (location.pathname.includes('chat'))DoPullToRefresh = false;
                    if (location.pathname.includes('call'))DoPullToRefresh = false;
                    if (location.pathname.includes('docx'))DoPullToRefresh = false;
                    if(DoPullToRefresh == true){
                        window.location.reload();
                    }
                },
                iconArrow: ReactDOMServer.renderToString(
                    <FontAwesomeIcon icon={faSyncAlt} />
                ),
                iconRefreshing: ReactDOMServer.renderToString(
                    <FontAwesomeIcon icon={faSyncAlt} spin={true} />
                ),
            });
            return () => {
                PullToRefresh.destroyAll();
            }/**/



    }, []);
    if(location.pathname.includes('chat') || 
    location.pathname.includes('call') || 
    location.pathname.includes('tarifs') || 
    location.pathname.includes('intarifs')){
        wrapper_style = {minHeight:"100vh"};
    }

    return (
        <div className="p-3 position-relative overflow-x-hidden pt-0 pb-0" style={wrapper_style}>

            <CheckToken />
            <RootDivScrollTop />

            {!location.pathname.includes('chat') && 
            !location.pathname.includes('call') && 
            !location.pathname.includes('tarifs') && 
            !location.pathname.includes('intarifs') && 
                <AppPopupWindow />
            }

            <AnimatePresence exitBeforeEnter>
                <Routes 
                    onUpdate={() => {
                        window.scrollTo(0, 0);
                    }}
                    location={location} 
                    key={location.pathname}>
                    <Route index element={<MainPage2 />} />
                    <Route path="/home2" element={<MainPage />} />
                    <Route path="/requisites" element={<RequisitesPage />} />
                    <Route path="/login" element={<LogInPage />} />
                    <Route path="/auth/:token" element={<Auth />} />
                    <Route path="/signup" element={<SignUpPage />} />
					<Route path="/registration" element={<SignUpPage />} />
						{ /*<Route path="/registration/verify" element={<VerifyByEmail />} />*/ }
					
                    <Route path="/news">
                        <Route index element={<NewsPage />} />
                        <Route path=":alias" element={<SingleNewsPage />} />
                    </Route>
                    <Route path="/qa">
                        <Route index element={<SingleCategoryPage />} />
                        <Route path=":id" element={<ArticlePage />} />
                    </Route>
                    { /*<Route path="/situation">
                        <Route index element={<CategoryPage />} />
                        <Route path=":catid" element={<SingleCategoryPage />} />
                        <Route path=":id" element={<ArticlePage />} />
						<Route path=":alias" element={<ArticlePage />} />
                    </Route>*/ }
                    <Route path="/situation">
                        <Route index element={<CategoryPage />} />
                        <Route path=":catid">
                            <Route index element={<SingleCategoryPage />} />
                            <Route path=":alias" element={<ArticlePage />} />
                        </Route>
                     </Route>
                    <Route path="/cases">
                        <Route index element={<CategoryPage />} />
                        <Route path=":catid">
                            <Route index element={<SingleCategoryPage />} />
                            <Route path=":id" element={<ArticlePage />} />
                        </Route>
                    </Route>
                    <Route path="/docs">
                        <Route index element={<CategoryPage />} />
                        <Route path=":id">
                            <Route index element={<SingleCategoryPage />} />
                            <Route path=":id" element={<TemplatePage />} />
                        </Route>
                    </Route>					
                    <Route path="/doc">
                        <Route index element={<CategoryPage />} />
                        <Route path=":id">
                            <Route index element={<SingleDocsCategoryPage />} />
                            <Route path=":id" element={<TemplatePage />} />
                        </Route>
                    </Route>
                    <Route path="/profile">
                        <Route index element={<ProfilePage />} />
                        <Route path="edit" element={<EditProfilePage />} />
                        <Route path="change-password" element={<ChangePassPage />} />
                        <Route path="premium" element={<PremiumPage />} />
                    </Route>
                    <Route path="/intarifs" element={<TarifsPage />} />
					<Route path="/tarifs">
                        <Route index element={<TarifPage />} />
                        {/*<Route path="edit" element={<EditProfilePage />} />
                        <Route path="change-password" element={<ChangePassPage />} />
                        <Route path="premium" element={<PremiumPage />} />*/}
                    </Route>
                    <Route path="/pay_yook" element={<YooPayResultPage />} />
                    <Route path="/services" element={<ServicesPage />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="/privpol" element={<PolicyPage />} />
					<Route path="/feedback" element={<FeedbackPage />} />
                    <Route path="/chat">
                        <Route index element={<ChatPage />} /> 
                        <Route path="create" element={<CreateRoomPage />} />
                        <Route path=":id" element={<RoomPage />} />
                    </Route>
                    <Route path="/lawyer">
                        <Route index element={<InfoPage />} />
                        <Route path=":id" element={<SingleLawyerPage />} />
                    </Route>
                    <Route path="/info" element={<InfoPage />} />
                    <Route path="/call" element={<CallPage />} />
                    <Route path="/codex">
                        <Route index element={<CodexPage />} /> 
                        <Route path=":alias">
                            <Route index element={<SingleCodexPage />} />
                            <Route path=":alias" element={<ArticlePage />} />
                        </Route>
                    </Route>
                    <Route path="/plenum">
                        <Route index element={<CodexPage />} /> 
                        <Route path=":id" element={<ArticlePage />} />
                    </Route>
					<Route path="/search" element={<SearchPage />} />
                    <Route path="*" element={<></>} />
                </Routes>
            <Routes>
                <Route path="/call/" element={<></>} />
                <Route path="/tarifs/" element={<></>} />
                <Route path="/intarifs/" element={<></>} />
                <Route path="/profile/premium" element={<></>} />
                <Route path="*" element={<SpaceToBottom />} />
            </Routes>
            </AnimatePresence>
            <Routes>
                <Route path="/chat/create" element={<Navigation />} />
                <Route path="/chat/:id" element={<Navigation />} />
                <Route path="/call/" element={<></>} />
                <Route path="/tarifs/" element={<></>} />
                {/*}<Route path="/intarifs/" element={<></>} />{/**/}
                <Route path="/profile/premium" element={<></>} />
                <Route path="/*" element={<Navigation />} />
            </Routes>
        </div>
    );
    /* 
                
                    
                 */
}

export default App;
