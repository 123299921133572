import React from "react";
import { Link } from "react-router-dom";
import CategoryCard from "../../../CategoryCard";
import CategoryList from "../../../CategoryList";
import icons from './icons/icons';

const Services = (props) => {
    const data = [
        { 
            name: "Юридическая проблема", 
            description: "позвони или напиши - мы поможем",
            Icon: icons.CodexIcon,
            url: '/situation/'
        },
        { 
            name: "Наши Рекомендации",
            description: "посмотри ответы на частые вопросы",
            Icon: icons.CaseIcon,
            url: '/qa'
        },		
        { 
            name: "Каталог юристов",
            description: "выбери себе адвоката/юриста/нотариуса",
            Icon: icons.QuestionIcon,
            url: '/lawyer'
        },
        { 
            name: "Формы документов",
            description: "если нужно - составь сам документ из шаблона",
            Icon: icons.DocsIcon,
            url: '/doc'
        },
        { 
            name: "НАШИ СЕРВИСЫ",
            url: '/services'
        }/*,
        { 
            name: "Справочная информация",
            description: "",
            Icon: icons.InfoIcon,
            url: '/info/'
        },
        { 
            name: "Call-центр",
            Icon: icons.CourtIcon,
            url: '/call/'
        },
        { 
            name: "Юридические новости",
            Icon: icons.ConstitutionIcon,
            url: '/news/'
        },/**/
    ];	
    return (
        <div className="category-services-wrapper">
            <div className="d-flex justify-content-between align-items-center mb-3">
                {/*<h2 className="category-title">Наши сервисы</h2>
                <Link to="/services/" className="femida-link">Все сервисы</Link>*/}
            </div>
            <CategoryList>
                {
                    data.map((item, i) => <CategoryCard key={i} {...item} id={i}/>)
                }
            </CategoryList>
        </div>
    );
}

export default Services;