import { asyncCatcher, encryptMessage, fetchData, PDec } from "./utils";
import { Peer } from 'peerjs';

class Room {

    #API_URL;
    #WS_URL;
    #roomId;
    #token;
    #senderId;
    #publicKey;
    #user;
    #callOptions;
    #SIGNAL_URL;

    static onClose;
    static onMessage;
    static onError;
    
    static socket;

    constructor(config) {
        this.#WS_URL = config.WS_URL;
        this.#API_URL = config.API_URL;
        this.#SIGNAL_URL = config.SIGNAL_URL;
        this.#roomId = config?.roomId;
        this.#token = config.user?.token;
        this.#senderId = config.user?.id_user;
        this.#publicKey = config.user?.public_key;
        this.#user = config.user;
        this.#callOptions = {
            host: "api.my-advo.cat",
            port: "2087",
            path: '/',
            config: { iceServers: [{urls: "stun:stun.l.google.com:19302"}] }
        }

        Room.onClose = config.onClose || (() => {});
        Room.onMessage = config.onMessage || (() => {});
        Room.onError = config.onError || (() => {});

        if (!Room.socket || 
           (Room.socket?.readyState !== Room.socket?.OPEN &&
            Room.socket?.readyState !== Room.socket?.CONNECTING)) this.#createSocket();
    }

    #createSocket() {
        const socket = new WebSocket(this.#WS_URL + '?token=' + this.#token);

        socket.addEventListener('open', () => console.log('ws: connection opened'));
        socket.addEventListener('close', () => {
            console.log('ws: connection closed');
            Room.onClose();
        });
        socket.addEventListener('error', (e) => {
            console.log('ws: error ', e);
            Room.onError(e);
        });
        socket.addEventListener('message', (e) => {
            const data = JSON.parse(e?.data || '{}');
            if (data?.status === 200) {
                let message = data?.payload;
                if (message?.action === 'takePublic') {
                    //const decrypted = PDec(this.#publicKey, message?.msg);
                    message = {
                        ...message,
                        msg: message?.msg //decrypted
                    }
                } 
                Room.onMessage(message);
            } else {
                console.log(data);
            }
        });
        
        Room.socket = socket;
    }

    #send(data) {
        if (Room.socket.readyState === Room.socket.OPEN) {
            try {
                Room.socket.send(JSON.stringify(data));
            } catch(e) {
                console.log(e);
            }
        } else if (Room.socket.readyState === Room.socket.CONNECTING) {
            setTimeout(() => this.#send(), 1500);
        } else {
            this.#createSocket();
            setTimeout(() => this.#send(), 1500);
        }
    }

    sendMessage(message, ws = false, func = null) {
        if (ws) { // WebSocket message sending
            if (Room.socket.readyState === Room.socket.OPEN) {
            //const encrypted = encryptMessage({message, key: this.#user?.pvs_private_Key});
                try {
                    const data = {
                        token: this.#token,
                        senderid: this.#senderId,
                        roomid: Number(this.#roomId),
                        action: 'AddMsg',
                        msg: message
                    };
                    Room.socket.send(JSON.stringify(data));
                    console.log(data);
                    setTimeout(() => {
                        if(func !== null)func({state:"sent"});
                    }, 750);
                } catch (e) {
                    console.log(e);
                    setTimeout(() => {
                        if(func !== null)func({state:"error"});
                    }, 750);
                }
            } else {
                this.#createSocket();
                setTimeout(() => {
                    this.sendMessage(message);
                    if(func !== null)func({state:"tryagain"});
                }, 750);
            }
        } else {/**/ // http sending

            return asyncCatcher(async (resolve, reject) => {
                const url = this.#API_URL 
                + `/api/chat/chat/add-msg?room_id=${this.#roomId}&message=${message}`;
                const [data, err] = await fetchData({ 
                    url, 
                    token: this.#token, 
                    payload: 'message' 
                });
                if (err) reject(err);
                else {
                    resolve(data);
                    if(func !== null)func({state:"sent"});
                }
            });/**/
        }
    }

    sendFile(file, func = null) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL
            + `/api/chat/chat/upload-file-to-room?room_id=${this.#roomId}`;
            const body = new FormData();
            body.set('file', file, file.name);
            const headers = new Headers();
            headers.append('Authorization', `Bearer ${this.#token}`);
            try {
                const res = await fetch(url, { 
                    method: 'POST',
                    headers, 
                    body 
                });
                const data = await res.json();
                if (data?.status === 200) {
                    resolve(data?.payload?.array);
                    if(func !== null)func(data);
                    if (Room.socket.readyState !== Room.socket.OPEN) {
                        this.#createSocket();
                    }
                    if (Room.socket.readyState === Room.socket.OPEN) {
                            try {
                                const data = {
                                    token: this.#token,
                                    roomid: Number(this.#roomId),
                                    action: 'refreshMessage',
                                };
                                Room.socket.send(JSON.stringify(data));
                                console.log(data);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                } else if (data?.status === 400) {
                    if(data?.payload === "Too much size"){
                        if(func !== null)func(data);
                    }
                } else {
                    reject(data);
                }
            } catch (error) {
                if(func !== null)func();
            }
        });
    }

    createRoom({title, message}) {
        const data = {
            token: this.#token,
            senderid: this.#senderId,
            action: "AddRoom",
            title,
            msg: message,
        }
        this.#send(data);
    }

    getTitle() {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL 
            + `/api/chat/chat/get-room?id=${this.#roomId}`;
            const [data, err] = await fetchData({ url, token: this.#token });
            if (err) reject(err);
            else {
                const title = data?.roominfo?.title;
                if (title) {
                    const decrypted = PDec(this.#user?.pvs_private_Key, title);
                    resolve(decrypted);
                } else {
                    reject(title);
                }
            }
        });
    }

    call({mediaStream, peerEvents}) {
        return asyncCatcher(async (resolve, reject) => {    
            const peerUrl = `${this.#SIGNAL_URL}?token=${this.#token}`;
            const peerRes = await fetch(peerUrl);
            const peerData = await peerRes.json();
            
            console.log(peerData);
    
            if (!peerData || !peerData?.success) { 
                reject({message: 'Нет свободных операторов'});
                return;
            }
            const peerId = peerData.peer_id;
            const userId = peerData.user_id;
            if (!peerId || !userId) {
                reject({message: peerId});
                return;
            }

            const peer = new Peer(peerId, this.#callOptions);
            if (peerEvents?.length) {
                peerEvents.forEach(e => peer.on(e.event, e.function));
            }
    
            const data = {
                type:'createRoom',
                info: {
                    token: this.#token,
                    admin_id: userId
                }
            };
            this.#send(data);

            console.log(peer);
            // const call = peer.call(peerId, mediaStream);

            // resolve(call);
        });
    }
}

export default Room;