export const translateType = (type) => {
    switch(type) {
        case 'video':
            return 'Видео';
        default:
            return 'Документ';
    }
}

export const shortenFileName = (fileName) => {
    const arr = fileName.toString().split('.');
    const ext = arr[arr.length - 1];
    arr.pop();
    let name = arr.join('.');
    if (name.length > 25) {
        name = name.slice(0, 23).trim() + '...';
    };
    return `${name}${ext}`;
}

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}