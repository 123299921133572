import React, { useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Page from "../../Page";
import SearchBar from "../../SearchBar";
import contactImg from './assets/face.png';
import s from './styles.module.scss';
import I from './assets/icons';
import { ReactComponent as LogoIcon } from '../MainPage/Header/assets/logo.svg';  
import { femida } from "../../../api/app";
import { useEffect } from "react";
import { useState } from "react";
import LoadingScreen from "../../LoadingScreen";
import { startTransition } from "react";
import { UserContext } from "../../../context/UserContext";
import { formatDate, formatMessagePreview } from "./utils";
import Notification from "../../Notification";
import { AnimatePresence } from "framer-motion";

const ChatPage = (props) => {

    const {state} = useLocation();
    const navigate = useNavigate();
    const chat = femida.chat();
    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [rooms, setRooms] = useState(null);
    //const [WSocket, setWSocket] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [socketStatus, setSocketStatus] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        if (!user) navigate('/login', {replace: true});
        startTransition(async () => {
                
            getRoomList();
            const socket = chat.room({
                    user, 
                    onClose: () => {
                        setTimeout(() => setSocketStatus('Соединение с сервером закрыто. Подключение...'), 0);
                        setTimeout(() => setSocketStatus(null), 6000);
                    },
                    onError: () => {
                        setTimeout(() => setSocketStatus('Ошибка соединения с WebSocket'), 0);
                        setTimeout(() => setSocketStatus(null), 6000);
                    },
                    onMessage: (e) => {
                        if  (
                            e.action === 'refreshMsgList' ||
                            e.action === 'refreshRoomList' ||
                            e.action === 'getNewMsg'
                        ) {
                            getRoomList();
                        }
                        if (e.action === 'heartbeat') {
                            //
                        }
                    }
                });
            //setWSocket(socket);/**/
        });

        if (state?.roomCreated) {
            setTimeout(() => setShowNotification(true), 2000);
            setTimeout(() => setShowNotification(false), 7000);
        } 
    }, []);

    let getRoomList = async () => {
        const data = await chat.getRooms({
            token: user?.token,
            key: user?.pvs_private_Key,
            user
        })
        .catch(() => setLoading(false));
        setLoading(false);
        setRooms(data);
    }

    return (
        loading ?
        <LoadingScreen /> :
        <Page>
                    <div className={s.stickyTop}>
                        <div className="mb-4 position-relative d-flex align-items-center justify-content-end">
                            <span className="category-title w-100 text-center position-absolute pe-none">Чат</span>
                            <Link to="create">
                                <I.AddIcon />
                            </Link>
                        </div>
                        {rooms?.length ? <SearchBar
                            onChange={e => setSearchText(e.target.value)}
                            value={searchText} /> : <></>}
                    </div>
            {
                !rooms?.length ?
                    <div className={s.noChats}>
                        <LogoIcon height={120} width={120} />
                        <b>У вас пока нет обращений</b>
                    </div> 
                :
                <>
                    

                    <div className="my-2">
                    {
                        !rooms?.length ? <></> :
                        rooms.filter((room) => {
                            const t = room.title.trim().toLowerCase();
                            const m = room.message?.trim().toLowerCase();
                            const s = searchText.trim().toLowerCase();
    
                            return t.includes(s) || m.includes(s);
                        })
                        .map((room, i) => 
                            <Link key={i} to={ ("./"+room.id) || '/'}>
                                <div className="d-flex gap-2 py-3 border-bottom">
                                    {
                                        false && 
                                        <div className={s.contactImgWrap}>
                                            <img src={contactImg} alt="contact" />
                                        </div>
                                    }
                                    <div className="flex-grow-1 d-flex flex-column gap-1 justify-content-center overflow-hidden">
                                        <b className="d-flex justify-content-between">
                                            {formatMessagePreview(room.title)} 
                                            <span className={`text-secondary ${s.timeText}`}>
                                                {formatDate(room.createdon)}
                                            </span>
                                        </b>
                                        <span className={`text-secondary ${s.lastRoomMessage}`}>
                                            {formatMessagePreview({
                                                type: room.message_type,
                                                content: room.message
                                            })}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column gap-2 align-items-end">
                                        {
                                            false &&
                                            <>
                                            <I.ReadIcon /> 
                                            <div className={`${s.unread} ${s.mute}`}>
                                                <span>40</span>
                                            </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Link>)
                    }
                    </div>
                </>
            }
            <AnimatePresence>
                {
                    showNotification &&
                    <Notification>Обращение успешно создано!</Notification>
                }
                {socketStatus && <Notification>{socketStatus}</Notification>}
            </AnimatePresence>
        </Page>
    );
}

export default ChatPage;