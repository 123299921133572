import { ReactComponent as CaseIcon } from './case.svg';
import { ReactComponent as CodexIcon } from './codex.svg';
import { ReactComponent as ConstitutionIcon } from './constitution.svg';
import { ReactComponent as CourtIcon } from './court.svg';
import { ReactComponent as DocsIcon } from './docs.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as LawyerIcon } from './lawyer.svg';
import { ReactComponent as QuestionIcon } from './question.svg';
import { ReactComponent as GridOutlineIcon } from './grid-outline.svg';

const icons = {
    CaseIcon,
    CodexIcon,
    ConstitutionIcon,
    CourtIcon,
    DocsIcon,
    InfoIcon,
    LawyerIcon,
    QuestionIcon,
    GridOutlineIcon,
}

export default icons;

