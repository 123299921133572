import React, { useState } from "react"
import { Collapse } from "react-bootstrap";
import './styles.scss';
import I from '../pages/LawyerPage/assets/icons';
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";

const LawyerCollapse = (props) => {
    
    const {item} = props;
    const [open, setOpen] = useState(false);

	console.log(item.schedule.json);

    return (
						<>
						
        <motion.div
        {...fadeAnimation} 
        className="femida-collapse d-flex gap-2 py-3 border-bottom">
			<div role="button" className="femida-collapse__control" onClick={() => setOpen(!open)}>
				<div className="femida-collapse__name">
					<p>{item.name}</p>
					<div className="d-block mt-1">
						<I.MarkSmallIcon className="me-1" />
						<small>{item.str_address || item.value}</small>
					</div>					
				</div>
				<ArrowIcon className={`femida-collapse__arrow ${open}`} />
			</div>
            <Collapse in={open}>
				<div className="mt-2">
					{item.reg_num &&
						<>
							<small className="text-secondary d-block mt-3 mb-1">Адвокат рег. номер:</small>
							<span>{item.reg_num}</span>
						</>
					}
					{item.addresses &&
						<>
							<small className="text-secondary d-block mt-3 mb-1">Место работы</small>
							{item.addresses.map( (el, ii) => {
								return(<>
									<span key={`a${ii}`}>{el.state && el.state}{el.street && ", " + el.street}{el.house && ", " + el.house}{el.office && ", " + el.office}</span>
								</>)}
							)}
						</>
					}
					{item.contacts &&
						<>
							<small className="text-secondary d-block mt-3 mb-1">Телефоны</small>
							{item.contacts.map( (el, ii) => {
								return(<>
									<a key={`t${ii}`} href={`tel:${el.data}`}>
										{el.data}
									</a>
								</>)}
							)}
						</>
					}
					{/*item.schedule &&
						<>
							<small className="text-secondary d-block mt-3 mb-1">Время работы</small>
							{item.schedule.parseJSON().map( (el, ii) => {
								return(<>
									<span key={`a${ii}`}>{el}</span>
								</>)}
							)}
						</>
					/**/}

					{item.phones && 
						<>
							<div className="femida-collapse__phones">
							<small className="text-secondary d-block mt-3 mb-1">Телефоны</small>
							{item.phones.map( (el, ii) => {
								return(<>
									<a key={`t${ii}`} href={`tel:${el.number}`}>
										{el.number}
									</a>
								</>);
							})}
							</div>
						</>
					}										
					{item.services && <>
						<div className="femida-collapse__services">
							<small className="text-secondary d-block mt-3 mb-1">Специализация</small>
							<div className="mt-0 mb-3">
								{item.services && item.services.map( (el, ii) => {
									return(<>
										<span key={el.id} dangerouslySetInnerHTML={{__html: el.title}}></span>
									</>);
								})}
							</div>
						</div>
					</>}
				</div>
            </Collapse>
        </motion.div>						
</>		
    );
}

export default LawyerCollapse;