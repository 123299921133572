import React, { useState, useEffect, startTransition } from "react"
import LoadingScreen from '../../../LoadingScreen';
import BackButton from "../../../BackButton";
import Page from "../../../Page";
import I from '../assets/icons';
import s from './styles.module.scss';
import face from './bot-face.png';
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { femida } from "../../../../api/app";
import MessageList from "./MessageList";
import FemidaLoader from "../../../FemidaLoader";
import { AnimatePresence } from "framer-motion";
import Notification from "../../../Notification";
import MediaPreview from "./MediaPreview";
import { ReactMediaRecorder } from "react-media-recorder";

const RoomPage = (props) => {

    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const {id} = useParams(); // room id
    const chat = femida.chat();
    const [room, setRoom] = useState(null);
    // TODO: check if room ws connection opened
    
    const [loading, setLoading] = useState(true);
    const [socketStatus, setSocketStatus] = useState(null);
    const [chatWarnings, setChatWarnings] = useState(null);
    
    const [isSending, setIsSending] = useState(false);

    const [messages, setMessages] = useState('');
    const [roomTitle, setRoomTitle] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [mediaAttached, setMediaAttached] = useState(null);
    const [AudioMessageAvailable, setAudioMessageAvailable] = useState(false);

    let CordovaJS = {};

    useEffect(() => {
        startTransition(async () => {
            const room = chat.room({
                user, 
                roomId: id, 
                onClose: () => {
                    setTimeout(() => setSocketStatus('Соединение с сервером закрыто. Подключение...'), 0);
                    setTimeout(() => setSocketStatus(null), 6000);
                },
                onError: () => {
                    setTimeout(() => setSocketStatus('Ошибка соединения с WebSocket'), 0);
                    setTimeout(() => setSocketStatus(null), 6000);
                },
                onMessage: (e) => {
                    if (e.action === 'refreshMsgList') {
                        setIsSending(false);
                        refreshMessages();
                    }
                    if (e.action === 'heartbeat') { }
                }
            });
            setRoom(room);
            const data = await chat.getMessages({id, user})
            .catch();
            setMessages(data);
            const title = await room.getTitle().catch();
            setRoomTitle(title);
            setLoading(false);
            //checkVisibleHeight();
            
            navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia
            );

            navigator.getMedia({audio: true}, function() {
                setAudioMessageAvailable(true);
            }, function() {
                setAudioMessageAvailable(false);
            });

        });

        document.addEventListener("deviceready", onDeviceReady, false);

        function onDeviceReady() {
            if (!window.cordova) {
                console.log("React Loaded");
            } else {
                console.log("Cordova Loaded");
                CordovaJS = window.cordova;
                //Cordova_Navigator = navigator;
            }
        }
    }, []);
    
    const checkVisibleHeight = (ePage) => {
        try{
            console.log("ePage.id2");
            console.log(ePage.id);
            if(ePage.id !== "chet_page_wrapper")return;
            let controlHeightElement = document.createElement("div");
            controlHeightElement.id = "control-height";
            controlHeightElement.style.position = "absolute";
            controlHeightElement.style.height = "100vh";
            document.body.appendChild(controlHeightElement);

                console.log("controlHeightElement.offsetHeight");
                console.log(controlHeightElement.offsetHeight);
                console.log("window.outerHeight");
                console.log(window.outerHeight);

            if(controlHeightElement.offsetHeight > window.outerHeight){
                let correctionLength = controlHeightElement.offsetHeight - window.outerHeight;
                
                console.log("ePage");
                console.log(ePage.style.height);
                ePage.style.height = (window.outerHeight - correctionLength) + "px";
                console.log(ePage.style.height);
                console.log("correctionLength");
                console.log(correctionLength);
            }
        }catch(e){console.log(e);}
    }

    const refreshMessages = async () => {
        //console.log('refreshing messages...');
        const data = await chat.getMessages({id, user})
        .catch();
        //console.log(data);
        setMessages(data);

    }

    const clearMessageText = (text) => {
        let output = (text).replace(/(<([^>]+)>)/gi, '');
        return output.replace(/\s{2,}/gi, ' ');
    }

    const handleSend = (e) => {
        if (!room) return;
        try {
            startTransition(async () => {
                setIsSending(true);
                if (mediaAttached) {
                    setTimeout(async () => {
                        await room.sendFile(mediaAttached,
                            (data)=>{
                                setTimeout(() => refreshMessages(), 300);
                                setIsSending(false);
                                if (data?.status === 400) {
                                    if (data?.payload === "Too much size") {
                                        setTimeout(() => setChatWarnings('Ответ сервера: Выберите файл менее 6Мб'), 0);
                                        setTimeout(() => setChatWarnings(null), 6000);
                                    }
                                }
                            });
                    }, 1300);
                }
                    if (newMessage?.trim().length) {
                        //console.log("Новое сообщение:");
                        let TagSplitedMessage = clearMessageText(newMessage);
                        room.sendMessage(TagSplitedMessage, 'ws',
                            (res)=>{
                                if(res.state === "sent"){}else{
                                    setIsSending(false);
                                    setTimeout(() => setChatWarnings('Ошибка отправки сообщения'), 0);
                                    setTimeout(() => setChatWarnings(null), 6000);
                                }
                                setTimeout(() => refreshMessages(), 300);
                                setIsSending(false);
                            }
                        );
                    }else if (!mediaAttached) {
                        setIsSending(false);
                        setTimeout(() => setChatWarnings('Поле сообщения не может быть пустым'), 0);
                        setTimeout(() => setChatWarnings(null), 6000);
                    }
            });
        } catch (e) {
            console.log(e);
        }

        setNewMessage('');
        setMediaAttached(null);
    }

    const handleBackBtn = () => {
        navigate("/chat", { state: {}, replace: true });
    }

    const BlobToFile = (theBlob, fileName) => {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const BlobUrltoWav = async (audioBlob) => {
        var timeInMs = Date.now();
        let blob = await fetch(audioBlob).then(r => r.blob());
        audioBlob = BlobToFile(blob, timeInMs+".wav");
        if(window.cordova !== undefined)
        if(window.cordova.platformId === "android"){
            blob.lastModifiedDate = new Date();
            blob.name = "audiofile_"+timeInMs+".wav";
            blob.filename = "audiofile_"+timeInMs+".wav";
            return blob;
        }/**/
        const audiofile = new File([audioBlob], "audiofile_"+timeInMs+".wav", { type: "audio/wav" });
        return audiofile;
    }

    const checkAttachingFile = (e) => {
        let file = e.target.files[0];
        if(file.size > 6291456){
            setTimeout(() => setChatWarnings('Выберите файл менее 6Мб'), 0);
            setTimeout(() => setChatWarnings(null), 6000);
            return false;
        }
        let ext = null, 
        parts = file.name.split('.'),
        extable = ["jpg","png","gif","txt","doc","docx","odf","xml","xmlx","rtf","log","webp",
            "mp4","avi","mp3","wav","ogg","zip","tgz","pdf"];
        if (parts.length > 1) ext = parts.pop().toLowerCase();
        if (extable.indexOf(ext) < 0){
            setTimeout(() => setChatWarnings('Не допустимый тип файла'), 0);
            setTimeout(() => setChatWarnings(null), 6000);
            return false;
        }
        return true
    }

    return (
        loading ? 
        <LoadingScreen /> :
        <Page 
            id="chet_page_wrapper" 
            className={s.page} 
            onLoad={(e)=>{
                let letPage = document.getElementById("chet_page_wrapper");
                checkVisibleHeight(letPage);
            }}
            >
            {/* HEADER */}
            <div className="d-flex align-items-center">
                <BackButton onClick={handleBackBtn} />
                <div className="flex-grow-1 d-flex gap-2 align-items-center">
                    <div className={s.faceWrap}>
                        <img src={face} alt="face" />
                    </div>
                    <div className="d-flex flex-column">
                        <small className="fw-bold">Бот системы</small>
                        <small className={`${s.onlineText}`}><div />Онлайн</small>
                    </div>
                </div>
                {/*<div role="button" className="p-2">
                    <I.CallIcon />
                </div>
                <div role="button" className="p-2">
                    <I.VideoIcon />
                </div>*/}
            </div>

            {/* MESSAGES */}
            <MessageList messages={messages} roomTitle={roomTitle}  />

            {/* CONTROLS */}
            <div className="d-flex align-items-center gap-1 flex-wrap">
                {
                    !mediaAttached? <></> :
                    <MediaPreview 
                        setMediaAttached={setMediaAttached} 
                        data={mediaAttached} />
                }
<div className="left-button-panel">
    {AudioMessageAvailable && <>
        <ReactMediaRecorder
            audio
            render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                
                if(status==="stopped"){   
                    let WavFile = BlobUrltoWav(mediaBlobUrl);
                    //console.log(WavFile);
                    WavFile.then((res)=>{clearBlobUrl();setMediaAttached(res);});
                }

                return(
                <>
                    {status!=="recording" ? <>
                        <span onClick={()=>{startRecording();setChatWarnings("Запись началась...");setTimeout(()=>setChatWarnings(null), 6000);}} id="start" className="p-2" title="Начать запись аудиосообщения">
                            <I.MicIcon alt="Начать запись аудиосообщения" />
                        </span></>
                    :
                     <><span onClick={() => {stopRecording(); setChatWarnings("Запись прикреплена к сообщению");setTimeout(()=>setChatWarnings(null), 6000);}} id="stop" className="p-2" title="Остановить запись аудиосообщения">
                        <I.MicSlashIcon alt="Остановить запись аудиосообщения" />
                    </span> </>}
                    {/*status=="stopped" && <><audio src={mediaBlobUrl} controls style={{
                        height: "30px",
                        width: "50%",
                        marginLeft: "10px",
                    }} /> &nbsp; <span onClick={() => {clearBlobUrl();setChatWarnings("Запись удалена");setTimeout(()=>{setChatWarnings(null);}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" title="Удалить запись" type="button">
                    <i className="fa fa-trash" alt="Удалить запись"></i>
                </span> <span onClick={() => {setMediaAttached(mediaBlobUrl); clearBlobUrl();setChatWarnings("Запись отправленна");setTimeout(()=>{setChatWarnings(null);}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" title="Отправить запись сообщением" type="button">
                        <i className="fa fa-file-audio-o" alt="Отправить запись сообщением"></i>
                    </span></>/**/}
                </>
            );}}
        /><br className="clearfix" />
    </>}
                <label className="p-2">
                    <I.ClipIcon />
                    <input 
                        type="file"
                        id="sdfsdfa" 
                        onChange={e => {if(checkAttachingFile(e))setMediaAttached(e.target.files[0]);}} 
                        className="d-none" />
                </label>
                </div>
                <textarea
                    onChange={(e) => setNewMessage(e.target.value)}
                    className={s.messageInput}
                    id="messageInput"
                    name="messageInput"
                    placeholder="Начните вводить"
                    rows="3"
                    maxLength="600"
                    value={newMessage}
                    disabled={isSending}>{newMessage}</textarea>
                <button
                onClick={handleSend} 
                className={s.sendBtn} 
                disabled={(!newMessage.trim().length && !mediaAttached) || isSending}>
                    {
                        isSending ?
                        <FemidaLoader smol /> :
                        <I.SendIcon />
                    }
                </button>
            </div>
            <AnimatePresence>
                {socketStatus && <Notification>{socketStatus}</Notification>}
                {chatWarnings && <Notification>{chatWarnings}</Notification>}
            </AnimatePresence>
        </Page>
        
    );
}

export default RoomPage;