import React, { useEffect, useState, useContext } from "react";
import { fadeAnimation } from "../../../../utils/animationSetting";
import Page from "../../../Page";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../PageHeader";
import { UserContext } from "../../../../context/UserContext";
import LoadingScreen from "../../../LoadingScreen";


let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + token);

const YooPayResultPage = (props) => {
	
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (!user) {navigate('/login', {replace: true});return;}
        setLoading(false);
    }, []);

    return (
        loading ?
        <LoadingScreen />
        :
        <Page {...fadeAnimation}>

		    <PageHeader>Оплата</PageHeader>

            <div className="d-flex justify-content-between align-items-center mb-4">
			
                <div className="container page">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h3>Спасибо, за оплату.</h3> 
                            <div className="card">
                                <div className="card-body text-center">
                                    <a href="/lk/" className="btn btn-info btn-small mb-3 text-white">Личный кабинет</a>
                                    <br />
                                    <a href="/" className="btn btn-primary btn-small">Главная</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
            </div>
            
        </Page>
    );
}

export default YooPayResultPage;