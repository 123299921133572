import { ReactComponent as MapIcon } from './map.svg';
import { ReactComponent as MarkSmallIcon } from './mark-smol.svg';
import { ReactComponent as MarkIcon } from './mark.svg';
import { ReactComponent as StarIcon } from './star.svg';
import { ReactComponent as CheckmarkIcon } from './checkmark.svg';

const icons = {
    MapIcon,
    MarkSmallIcon,
    MarkIcon,
    StarIcon,   
    CheckmarkIcon,
}

export default icons;
