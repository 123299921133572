import React, { useState, useEffect, useContext } from 'react';
//import { UserContext } from "../../context/UserContext";


const PremiumAccess = (props) => {
    //const {user} = useContext(UserContext);
	const [isPremium, setIsPremium] = useState(false);
	const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        try {
				let profileData = localStorage.getItem('profile');
				if(profileData){
					profileData = JSON.parse(profileData);
					let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
					setIsPremium(LSisPremium);/**/
				}
				
				let LSisAuth = Boolean((localStorage.getItem('tokenAPI'))?true:false);
				setIsAuth(LSisAuth);
        } catch (e) {
            console.log(e);
        }
    }, []);

    if(props.CheckForAuth === true){
		if(isAuth === false){
			return (<>
				<div className="text-center pb-4 pt-2">
                	<b className="d-block mb-2">Доступно только авторизованным пользователям.</b>
                    <a href="/login"><button className="femida-btn">Авторизироваться</button></a>
                </div> 
			</>);
		}else{
			return (<></>);
		}
	}else if(props.CheckForPremium === true){
		if(isPremium === false){
			return (<>
				<div className="text-center pb-4 pt-2">
                	<b className="d-block mb-2">Доступно только пользователям с Премиум аккаунтом.</b>
                    <a href="/intarifs"><button className="femida-btn">Выбрать тариф</button></a>
                </div> 
			</>);
		}else{
			return (<></>);
		}
	}
	return (<></>);
}

export default PremiumAccess;