import React, { startTransition, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import pageData from '../CategoryPage/data';
import PageHeader from "../../PageHeader";
import SearchBar from "../../SearchBar";
import CategoryItem from "../../CategoryItem";
import Page from "../../Page";
import { femida } from "../../../api/app";
import LoadingScreen from "../../LoadingScreen";
import FemidaCollapse from "../../FemidaCollapse";
import { UserContext } from "../../../context/UserContext";
import PageSearchBar from "../../PageSearchBar";
import * as utils from "../../../api/utils";
import { CallCard2 } from "../../CallCard";

const SingleCategoryPage = (props) => {
const usePParams =useParams();
		console.log(usePParams);

    const location = useLocation();
    const navigate = useNavigate();
    const { catid } = useParams();
    const path = location.pathname.split('/')[1];
    const {user} = useContext(UserContext);

    const [items, setItems] = useState(null);

    const [header, setHeader] = useState("");

    const cards = pageData[path]?.cards;
    
    //auth
    const [isPremium, setIsPremium] = useState(false);
	
	
    useEffect(() => {
			

        /*if (!user) {
            navigate('/login', { replace: true });
            return;
        } */

        let profileData = localStorage.getItem('profile');
        if(profileData){
          profileData = JSON.parse(profileData);
          let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
          setIsPremium(LSisPremium);
        }

        let letcatid = catid;
        if(path === "docs")letcatid = usePParams.id;
        let letheader = letcatid && cards?.length ? cards.filter(i => i.url === letcatid)[0].name : '';
        
        setHeader(letheader);

        try {
            startTransition(async () => {
                const data = await femida.getCategoryContent({
                    query: location.pathname,
                    token: user?.token
                });
                setItems(data);
                //console.log(cards);
                //console.log(catid);
                //console.log(header);
                //console.log(path);
            });
        } catch (e) {
            console.log(e);
        }        
    }, []);


    return (
        <Page>
            {
                path === 'qa' ?
                <PageHeader>Вопрос-ответ</PageHeader> :
                <PageHeader>{header}</PageHeader>
            }		
            {user ? <PageSearchBar sTarget={path} /> : <></>}
			{ /*<SearchBar />*/ } {
                !items ?
                <LoadingScreen /> :
                <>
                <div className="my-4 d-flex flex-column gap-2">
                {
                    items && items?.length ?
                    items.map((item, i) => {
                        item.parent_url = catid;
                        if (path === 'qa') {
                            return (
                                <>
                                {isPremium ? 
                                    <>
                                    <CategoryItem 
                            key={i}
                            description={item.content}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.title} />
                                    {/*}<FemidaCollapse key={i} name={item.title}>
                                        {item.content}
                                    </FemidaCollapse>{/**/}
                                    </> 
                                :
                                    <>
                                    <CategoryItem 
                            key={i}
							id={item.id}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.title} />
                                    </>    
                                }
                                
                                </>
                            );
                        } else if (path === 'doc') {
                            return <CategoryItem 
                            key={i}
                            description={item.description}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.name} />							
                        } else if (path === 'docs') {
                            return <CategoryItem 
                            key={i}
                            description={item.description}
                            codex_id={item.id} 
                            parent_url={catid} 
                            title={item.name} />
                        } else if (path === 'situation') {
							if (item.codex_id) {
								item.title = utils.replaceByTpl(item.title, 'Глава', 'Рекомендации по Главе');
								item.title = utils.replaceByTpl(item.title, 'Статья', 'Рекомендации по Статье');
                            return <CategoryItem 
                            key={i}
                            description={item.name}
							id={item.id}
                            codex_id={btoa(item.codex_id)} 
                            parent_url={catid} 
                            title={item.title} />;
							} else {
								return <></>
							}
                        } else if (path === 'codex') {
							//alert(item.base64_uid);
                            return <CategoryItem 
                            key={i}
							description=""
							id={item.id}
                            codex_id={item.base64_uid} 
                            parent_url={catid} 
                            title={item.title} />;						
                        } else {
                            return <CategoryItem key={i} {...item} />
                        }
                    })
                    : items?.length === 0 ?
                    <div className="mt-5 text-center">Пусто</div>
                    : <LoadingScreen />
                }
            </div>
            
            <CallCard2 />
                </>
            }
            
        </Page>
    );
}

export default SingleCategoryPage;