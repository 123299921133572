import React from "react";
import './styles.scss';
import CallSvg from './assets/Call.svg';
import { useNavigate } from "react-router-dom";

const CallCard = (props) => {
    const navigate = useNavigate();
    return (
        <div role="button" className="p-3 call-card" style={{background: "url('/static/media/img.svg') no-repeat rgba(4, 182, 245, 0.08)", border: "2px solid #04b6f529"}}>
            <div className="d-flex gap-2 call-card__block">
                <div>
                    <img alt="" src="https://api.my-advo.cat/uploads/bg_btn_male.png" width="100px" />
                </div>			
                <div>
                    <h5 className="call-card__title">Нужен срочный совет юриста?</h5>
                    <p className="text-secondary call-card__description">Вам помогут в круглосуточной службе поддержки.</p>
                </div>
                <div  className="d-none">
                    <h5 className="call-card__title">Нужна срочная помощь?</h5>
                    <p className="text-secondary call-card__description">Получите консультацию от ведущих адвокатов и юристов России.</p>
                </div>				
            </div>
            <button onClick={() => navigate('/call', {replace: true})} className="d-flex gap-2 w-100 justify-content-center femida-btn">
                <img src={CallSvg} alt="позвонить" />			
                <span>Позвоните нам</span>
            </button>
        </div>
    );
}

export default CallCard;