import React, { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const SpaceToBottom = (props) => {
    return (
        <>
            <div className="d-block mt-5 mb-5" style={{height: "20px"}}></div>
        </>
    );
}

export default SpaceToBottom;