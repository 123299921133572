import React from "react"
import './styles.scss';
import BackButton from "../BackButton";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const MapScreen = (props) => {

    const {
        close, 
        children, 
        subjects,
        onPlacemarkClick,
        activePlace
    } = props;

    return (
        <div className="map-screen">
            <div className="map-wrap">
            {
                subjects &&
                <YMaps query={{apikey:'df639532-a255-4e82-b3e0-06ad7483393e',load:'package.full'}}>
                    <Map
                    height="100vh"
                    width="100vw"
                    modules={["geolocation", "geocode", 'geoObject.addon.balloon', 'geoObject.addon.hint']} 
                    defaultState={{ 
                        center: [
                            activePlace ? parseFloat(activePlace.coordx) - 0.001 : subjects[0].coordx, 
                            activePlace ? activePlace.coordy : subjects[0].coordy,
                        ], 
                        zoom: activePlace ? 17 : 12,
                        controls: ['zoomControl'] 
                    }}>
                        {
                            subjects && subjects.map((s, i) => {
                                return (
                                    <Placemark
                                    key={i}
                                    onClick={onPlacemarkClick} 
                                    type='Point'
                                    class={`placemark`} 
                                    geometry={{
                                        type: 'Point',
                                        coordinates: [s.coordx, s.coordy]
                                    }}
                                    properties={{
                                        balloonContentBody:  `<h6>${s.name}</h6>${s.str_address}`,
                                        balloonContent: s.str_address,
                                        clusterCaption: s.name,
                                        hintContent: s.name,
                                        id: s.id
                                    }}></Placemark>
                                );
                            })
                        }
                    </Map>
                </YMaps>
            }
            </div>
            <div className="map-controls">
                <BackButton solid="true" onClick={close} />
                <input
                placeholder="Найти" 
                className="search-input flex-grow-1" 
                type="text" />
            </div>
            { children && children }
        </div>
    );
}

export default MapScreen;