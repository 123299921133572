import React, { useEffect } from "react"

const RootDivScrollTop = (props) => {

    useEffect(() => {
        
        let root_div = document.querySelector("#root>div");
        root_div.scrollTo(0,0);
        window.scrollTo(0, 0);
    });

    return (
        <>
        </>
    );
}

export default RootDivScrollTop;