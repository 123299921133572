import React from "react"
import './styles.scss';
//import { formatDate, getImageUrl } from "./utils";

const NewsItem = (props) => {
    const { 
        title, 
        anonce,
        image,
        url,
		pubdate
    } = props;

    //const imgUrl = getImageUrl(file);
    //const date = formatDate(created_at);
	
    const imgUrl = image;
    const date = pubdate;
    return (
        <div className="news-item">
            {
                !imgUrl ? <></> :
                <div
                style={{backgroundImage: `url(${imgUrl})`}} 
                className="news-item__img-c">
                    {/* <img src={''} alt={title} className="news-item__img" /> */}
                </div>
            }
            <div className="d-flex flex-column">
                <span className="news-item__title">{title}</span>
                <span className="text-secondary news-item__description">{anonce}</span>
                <small className="text-secondary news-item__date mt-1">{date}</small>
            </div>
        </div>
    );
}

export default NewsItem;