import React, { startTransition, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Button";
import Page from "../../../Page";
import PageHeader from "../../../PageHeader";
// import avatar from '../assets/avatar.png';
import './styles.scss';
import { ReactComponent as EditIcon } from './edit.svg';
import { UserContext } from "../../../../context/UserContext";
import { femida } from "../../../../api/app";
import LoadingScreen from '../../../LoadingScreen';
// import { useNavigate } from "react-router-dom";
import { fetchProfileData } from "../../../../utils/user";
import {FormErrors} from "./FormErrors";
import {FormMsg} from "./FormMsg";


let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

const EditProfilePage = (props) => {

    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
      //  phones: [{}],
    });
    const [imgPreview, setImgPreview] = useState(null);
	
    const [errors, setErrors] = useState({
        name: '',
        phones: ''
    });
    const [formResponceErrors, setFormResponceErrors] = useState({error: ''});	
	const [formResponceMsg, setFormResponceMsg] = useState({msg: ''});
	
	
	
	
    useEffect(() => {
        if (!localStorage.getItem('tokenAPI')) {
			localStorage.clear();
            navigate('/login');
            return;
        }
        try {
            setLoading(true);
          //  startTransition(async () => {
				
        let url = 'https://api.my-advo.cat/api/user/get-profile';
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then((response) => {
				console.log("=======================");
                console.log(response);
				console.log("=======================");
                if(response.status !== 200){
					localStorage.clear();
                    window.location.href = '/login';
					return false;
                }
                /*let phones = [{id: 0, data: ''}];
                if(response.payload.contacts){
                    if(response.payload.contacts.length>0){
                        phones = response.payload.contacts;
                    }
                }*/
				/*
                let socialLinks = [{id:0,link:'',social_id:0}];
                if(response.payload.socialLinks){
                    if(response.payload.socialLinks.length>0){
                        socialLinks = response.payload.socialLinks;
                    }
                }
				*/
				
                    console.log(response.payload);
					
		
					//updateField("name", response.payload.name);
					
					setUserInfo(response.payload);
                    setLoading(false);					
					
					/*
                    setUserInfo({
                        //...userInfo,
                       
					});*/

                let gender = 'не указан';
                if (response.payload.gender){
                    if (response.payload.gender === "F") {
                        gender = 'женский';
                    } else if (response.payload.gender === "M") {
						gender = 'мужской';
					}
                }
/*
                    setUserInfo({
                        //...userInfo,
                        gender: gender,
                    });*/
/*alert(userInfo.login);
console.log(userInfo);					
                    setUserInfo({
                        ...userInfo,
                        email: response.payload.email
                    });	
					console.log(response.payload.email);
alert(userInfo.avatar_image);
console.log(userInfo);					
                    setUserInfo({
                        ...userInfo,
                        description: response.payload.description
                    });	
*/					
					
                   /* setUserInfo({
                        ...userInfo,
                        birthday: response.payload.birthday
                    });						
                    setUserInfo({
                        ...userInfo,
                        address: response.payload.address
                    });						
                    setUserInfo({
                        ...userInfo,
                        phones: response.payload.phones
                    });	*/					
					/*
                    setUserInfo({
                        ...userInfo,
                        socialList: response.payload.socialList,				
                    }); */
                   /* setUserInfo({
                       // ...userInfo,
                        isPremiumUser: response.payload.premium,				
                    });*/
					
					let accountTypes = {
						1: "Администратор",
2: "Клиент",
4: "Клиент без оплаты",
7: "Премиум Клиент",
8: "Адвокат - Чат",
9: "Оператор звонков"
};
                let accountType = 'Обычный';
                if (response.payload.type_acc){
					

for (const [key, value] of Object.entries(accountTypes)) {
  if (key === response.payload.type_acc) {
    accountType = value;
  }
}					
				}	
				let lastActive = null;
                if (response.payload.last_active !== null){
					lastActive = response.payload.last_active;
                }	
					
					//accountType = Object.fromEntries(Object.entries(accountTypes).filter(([key]) => key.includes(response.payload.type_acc))).;
                
                    setUserInfo({
                        ...response.payload,
						lastActive: lastActive,
                        accountType: accountType,
                        genderAlias: gender,
                        isPremiumUser: response.payload.premium,
						phones: response.payload.phones,
						avatar_image: response.payload.avatar_image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88eN7PQAJPwNoN++SxwAAAABJRU5ErkJggg=='
                    });

					

                //}

            })
			.catch(error => {
                    console.log(error);
					alert('something went wrong');
                });
				
               // await fetchProfileData(user, setUser);
                setLoading(false);
          //  });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }, []);
    /*useEffect(() => {
        try {
            setLoading(true);
            startTransition(async () => {
                await fetchProfileData(user, setUser);
                setLoading(false);
                setUserData(user?.data);
            });
        } catch(e) {
            console.log(e);
            setLoading(false);
        }
    }, []);*/

    const handleChange = (e) => {
        if (e.target.name === 'avatar_image') {
            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                startTransition(() => {
                    setUserInfo({
                        ...userInfo,
                        [e.target.name]: file
                    });
                    setImgPreview(reader.result);
                })
            };
            reader.readAsDataURL(file);
            return;
        }
	
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });

    }

    /*const handlePhoneChange = (e) => {
        const index = parseInt(e.target.name.split('-')[1]);
        let newPhones = [...userData.phones]
        newPhones[index] = {
            ...newPhones[index],
            data: e.target.value
        };
        setUserData({
            ...userData,
            phones: newPhones
        });
    }*/

    const saveChanges = async (e) => {
		
		setFormResponceErrors({
            error: ''
        });
		setFormResponceMsg({
            msg: ''
        });
		
		
		errors.phones = '';
        if (userInfo.phones !== undefined && (userInfo.phones).length) {
            // eslint-disable-next-line
			const regExpr = /[^\d\+]/g;
            // eslint-disable-next-line
			const phones = (userInfo.phones).replace(regExpr, '');
			// eslint-disable-next-line
            var regPhoneExpr = /^\+7[0-9]{10}$/;
            // eslint-disable-next-line
            if (!phones.match(regPhoneExpr)) {
				errors.phones = "Указан некорректный номер телефона.";
            } else {
				userInfo.phones = phones;
            }				
        }
		setErrors({
            ...errors,
            phones: errors.phones || ''
        });
		
		errors.name = '';
        if (userInfo.name !== undefined && (userInfo.name).length) {
			let name = (userInfo.name).replace(/(<([^>]+)>)/gi, '');
			name = name.replace(/\s{2,}/gi, ' ');

            if (!name.length) {
                errors.name = 'Указано недопустимое ФИО.';
            } else {
				userInfo.name = name;
            }				
        } else {
            errors.name = 'Поле ФИО обязательно для заполнения.';		
        }			
        setErrors({
            ...errors,
            name: errors.name || ''
        });	

		if (Object.values(errors).join('').length === 0) {
        try {
            setLoading(true);
            await femida.editProfileData({
                token: localStorage.getItem('tokenAPI'),
                data: userInfo
            });
            setLoading(false);
			
            let successStr = "Профиль успешно обновлен.";						
			setFormResponceMsg({
                msg: successStr
            });
        } catch(e) {
            console.log(e);
            setLoading(false);
			
			let errorStr = "Внимание! При обновлении данных произошла ошибка."
			+ "\n" + e;						

			setFormResponceErrors({
                error: errorStr
            });			
        }
		}
		return false;
    }
    console.log(userInfo);
    return (
        loading ?
        <LoadingScreen />
        :
        <Page>
            <PageHeader>Редактировать профиль</PageHeader>
			<div className="panel panel-default" id="form_result">
                <FormErrors formErrors={formResponceErrors} />
				<FormMsg formMsg={formResponceMsg} />
            </div>			
            <div className="my-2 d-flex flex-column align-items-center gap-2">
                <label className="profile-pic-w">
                    <img src={imgPreview || (userInfo?.avatar_image ? userInfo?.avatar_image : '/logo192.png')} alt="" />
                    <EditIcon className="profile-edit-icon" />
                    <input
                    name="avatar_image"
					accept="image/*"
                    type="file"
                    onChange={handleChange}
                    className="profile-pic-input" />
                </label>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Почта <span className="form-required">*</span></small>
                <input 
                onChange={handleChange}
                name="email" 
				disabled="true"
                value={userInfo?.email === undefined ? '' : userInfo.email} 
                type="email"
				style={{opacity: ".5"}}
                className="femida-input-control" />
            </div>			
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">ФИО <span className="form-required">*</span></small>
                <input 
                onChange={handleChange}
				value={userInfo?.name || ''}
                name="name"
                className={`femida-input-control ${errors['name'] && 'is-danger'}`}
				required="required"
                type="text" />
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Номер телефона</small>
                <input 
                onChange={handleChange}
				value={userInfo?.phones || ''}
                name="phones"
                className={`femida-input-control ${errors['phones'] && 'is-danger'}`}
				placeholder="+79999999999"
                type="tel" />
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Пол</small>
                <select
                onChange={handleChange} 
                name="gender" 
                value={userInfo?.gender || 'none'} 
                className="femida-input select">
                    <option value="none">Не выбран</option>
                    <option value="M">Мужской</option>
                    <option value="F">Женский</option>
                </select>
            </div>

            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Дата рождения</small>
                <input 
                onChange={handleChange}
                name="birthday" 
				min="1940-01-01"
				max="2015-01-01"
                value={userInfo?.birthday} 
                className="femida-input-control"
                type="date" />
            </div>

            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Адрес проживания</small>
                <input 
                onChange={handleChange}
                name="address" 
                value={userInfo?.address} 
                type="text"
				placeholder="Населенный пункт или адрес проживания"
                className="femida-input-control" />
            </div>
            <Button onClick={saveChanges} className="w-100 mt-3">Сохранить</Button>
        </Page>
    );
}

export default EditProfilePage;