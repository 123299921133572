import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {PayMethods} from "../PayMethods";

export class PayInfoAccordion extends React.Component {

    render(){
		let LinkColor = {color:"#04B6F5"};
        return (<>
			<Accordion defaultActiveKey="0">
				<Accordion.Item eventKey="0">
					<Accordion.Header>Условия оплаты</Accordion.Header>
					<Accordion.Body>
					Для получения Премиум аккаунта необходимо авторизоваться, перейти на страницу оплаты, выбрать тариф, указать адрес электронной почты и номер телефона, принять условия <a href="/privpol/" style={LinkColor} className="link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" title="Политика конфиденциальности">политики конфиденциальности</a> (поставить галочку) и нажать кнопку "Оплатить".<br />
					Оплачивая премиум аккаунт вы принимаете <a href="/assets/files/license.docx" style={LinkColor} className="link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" target="_blank" title="Лицензионное соглашение">лицензионное соглашение</a>.<br />
					Далее произойдёт переадресация на страницу оплаты сервиса <noindex>Yookassa.ru</noindex>. Там выберите и используйте подходящий способ оплаты.<br />
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header>Способы оплаты</Accordion.Header>
					<Accordion.Body>
					Премиум аккаунт на любом тарифе можно оплачивать различными способами.<br /><b>Выбирайте наиболее удобный</b>:
					<PayMethods />                 
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="2">
					<Accordion.Header>Чем отличаются тарифы?</Accordion.Header>
					<Accordion.Body>
					В зависимости от стоимости тарифа начисляется указанное в нём количество времени, которое будет действовать Премиум аккаун. 
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>);

    }

}

export default PayInfoAccordion;