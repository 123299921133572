import { createContext, useState } from "react";

const UserContext = createContext();

const UserContextProvider = ({children}) => {

    let userState = localStorage.getItem('user_state');
    if (userState) {
        userState = JSON.parse(userState);
    }
    const [user, setUser] = useState(userState || null);

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
}

export {
    UserContextProvider,
    UserContext
};