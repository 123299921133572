export const getNodeTree = (arr) => {
    const tree = [];

    // find first parent id
    let firstParentIds = [];
    const ids = arr.map(e => e.id);
    arr.forEach(e => {
        if (!ids.includes(e.parent_id)) {
            firstParentIds.push(e.id);
        }
    });
console.log(firstParentIds);
    firstParentIds.forEach(id => {
        const item = arr.filter(e => e.id === id)[0]; 
        tree.push({
            ...item,
            children: getNestedChildren(arr, id)
        });
    });

    return tree;
}

const getNestedChildren = (models, parentId) => {
    const nestedTreeStructure = [];

    for (let i = 0; i < models.length; i++) {
        const model = models[i];

        if (model.parent_id === parentId) {
            const children = getNestedChildren(models, model.id);

            if (children.length > 0) {
                model.children = children;
            }

            nestedTreeStructure.push(model);
        }
    }

    return nestedTreeStructure;
}