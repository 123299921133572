 import React, { useContext, useState } from "react"
import { Link, useNavigate, Navigate } from "react-router-dom";
import Button from "../../Button";
import EyeSvg from '../../../assets/eye-svg.svg';
import VkSvg from '../../../assets/vk.svg';
import GoogleSvg from '../../../assets/google.svg';
import BackButton from "../../BackButton";
import { motion } from "framer-motion";
import { fadeAnimation } from '../../../utils/animationSetting';
import { femida } from "../../../api/app";
import { UserContext } from "../../../context/UserContext";
import { useEffect } from "react";
import useForm from "./useForm";
import validate from './LoginFormValidationRules';
import {FormErrors} from "./FormErrors";

const LogInPage = (props) => {

    useEffect(() => {
        //window.location.replace('https://my-advo.cat/login/');
        // window.location.replace('http://localhost:3001/login/');
    }, []);

    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    //const [error, setError] = useState(false);	
    const [credentials, setCredentials] = useState({
        login: '',
        password: ''
    });
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
		setErrors
    } = useForm(login, validate);
    const [formResponceErrors, setFormResponceErrors] = useState({error: ''});


    async function fetchGetResponse() {
        const formData = new FormData();
		formData.append('login', values.email);
		formData.append('password', values.password);
		
        const url = "https://api.my-advo.cat/api/token";
					
        const response = await fetch(
		    url, 
			{
               method: 'POST',
               body: formData
            }
		);
		
	    if (response.status !== 200) {
		    console.log('error='+response);
		    throw new Error(`HTTP error! status: ${response.status}`);
	    }				
        const result = await response.json();
	    return result;
    }		

    function login() {
        console.log('No errors, submit callback called!');
	    console.log(values);
		
		setLoading(true);
		
    fetchGetResponse()
        .then(result => {
            setLoading(false);
			
			if (result.status === 200) {
				const user = result.payload;
                setUser(user);
                localStorage.setItem('user_state', JSON.stringify(user));
				
                localStorage.setItem('tokenAPI',result.payload.token);
                localStorage.setItem('id_user',result.payload.id_user);
                localStorage.setItem('public_key',result.payload.public_key.trim());				
                navigate('/profile');
                return false;				
			} else {
				errors.common = "Ошибка авторизации";
				//setErrors(errors);
			let errorStr = "Внимание! Авторизация не пройдена. "
			+ "Проверьте корректность введенных данных и повторите попытку.";						

			setFormResponceErrors({
                error: errorStr
            });					
			}
        })
        .catch(error => {
			setLoading(false);
			errors.common = "Ошибка авторизации";
            console.log(error);
			let errorStr = "Внимание! Авторизация не пройдена. "
			+ "Проверьте корректность введенных данных и повторите попытку.";						

			setFormResponceErrors({
                error: errorStr
            });			
			//setErrors(errors);
        });		
    }		
	
	
    const handleUserInputBK = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            console.log('name=' +name);
            console.log('value=' +value);
			
			
        setCredentials({
            ...credentials,
            [name]: value
        });			
    }
		
		
    const handleChangeBk = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitBk = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const user = await femida.authWithCredentials(credentials);
            setLoading(false);
            setUser(user);
            localStorage.setItem('user_state', JSON.stringify(user));

            navigate('/profile');
        } catch(e) {
            console.log(e);
            alert('Ошибка авторизации');
			//setError(true);
            setLoading(false);
        }
    }

    const oauth2 = async (type) => {
        try {
            setLoading(true);
            let user = null;
            switch(type) {
                case 'google':
                    user = await femida.authWithGoogle();
                    break;
                case 'vk':
                    user = await femida.authWithVk();
                    break;
                default:
                    break;
            }
            
            if (!user) throw new Error();
            
            console.log(user);

            setLoading(false);
            setUser(user);
            localStorage.setItem('user_state', JSON.stringify(user));
        } catch(e) {
            setLoading(false); 
            console.log(e);
            alert('Ошибка авторизации')
        }
    }

    return (
        <motion.div {...fadeAnimation}
        className="d-flex flex-column align-items-center justify-content-center py-5 gap-4">
		{ /*<div className="align-self-stretch">
                <BackButton onClick={() => navigate('/')} />
            </div>*/ }
            <div className="d-flex flex-column align-items-center gap-3">
                <h2 className="mb-0">Вход</h2>
                <span className="font-weight-light text-secondary text-center">Для входа используйте адрес электронной почты и пароль.</span>
                <small>Нет аккаунта? <Link className="femida-link" to="/signup">Регистрация</Link></small>
            </div>
			<div className="panel panel-default" id="form_result">
                <FormErrors formErrors={formResponceErrors} />
            </div>
            <form
            onSubmit={handleSubmit} noValidate
            className="d-flex flex-column gap-3 w-100 align-items-stretch">
                <label>
                    <small className="d-block mb-2">E-mail <span class="form-required">*</span></small>
				
                <div className="control">
                  <input autoComplete="off" className={`femida-input-control ${errors.email && 'is-danger'}`} type="email" name="email" onChange={handleChange} value={values.email || ''} required />
                  {errors.email && (
				    <> 
                        <p className="help is-danger">{errors.email}</p>
					</>
                  )}
                </div>
                </label>				
                <label>
                    <small className="d-block mb-2">Пароль <span class="form-required">*</span></small>
					
                <div className="control position-relative">
                        <img 
                        src={EyeSvg} 
                        onClick={() => setPasswordShown(!passwordShown)}
                        className="femida-input__eye" 
                        alt="показать/скрыть" />				
                  <input className={`femida-input-control ${errors.password && 'is-danger'}`} 
				  type={passwordShown ? 'text' : 'password'}
				  name="password" autoComplete="current-password" onChange={handleChange} value={values.password || ''} required />
                {errors.password && (
				  <>
                  <p className="help is-danger">{errors.password}</p>
				  </>
                )}	
                </div>
                </label>
                { /*<Link style={{marginTop: "0", textAlign: "right"}} className="femida-link" to="#">Забыли пароль?</Link>*/ }
                <Button 
                className="mt-2 is-block"
                loading={loading}
                disabled={loading} 
                type="submit">Войти</Button>
            </form>
			<div className="flex-column align-items-stretch gap-4 text-center">
                <p className="small text-secondary">Нажимая «Войти», Вы принимаете <a href="/privpol/" target="_blank" title="Политика в отношении обработки персональных данных">пользовательское соглашение и политику конфиденциальности</a>.</p>			
			</div>
			
			<div className="flex-column align-items-stretch gap-3">
			
                       <small className="text-secondary">
                            При входе Вам будут доступны:
                            <ul>
                                <li>разделы консультаций в чате и по видеосвязи,</li>
                                <li>полные версии статей судебной практики,</li>
                                <li>полная база юристов, адвокатов и нотариусов в любом регионе России</li>
                            </ul> и многие другие сервисы нашего юридического портала.
                        </small>			
			</div>			
            <div className="d-none flex-column w-100 align-items-stretch gap-4">
                <span className="text-center">Или войти через:</span>
                <div className="d-flex gap-3 justify-content-center">
                    <div 
                    onClick={() => oauth2('vk')}
                    role="button"><img className="oauth-img" src={VkSvg} alt="vk"/></div>
                    <div  style={{display: "none"}}
                    role="button"
                    onClick={() => oauth2('google')}><img className="oauth-img" src={GoogleSvg} alt="google"/></div>
                </div>
            </div>
        </motion.div>
    );
}

export default LogInPage;