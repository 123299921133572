import React, { useState } from "react"
import { startTransition } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { femida } from "../../../api/app";
import CategoryItem from "../../CategoryItem";
import Page from "../../Page";
import PageHeader from "../../PageHeader";
import RadioSelect from "../../RadioSelect";
import SearchBar from "../../SearchBar";
import PageSearchBar from "../../PageSearchBar";
import { ReactComponent as NotFoundIcon } from '../../SearchBar/not-found.svg';
import s from './styles.module.scss';
import { CallCard2 } from "../../CallCard";

const CodexPage = (props) => {

    const [searchResult, setSearchResult] = useState('');
    const [codexes, setCodexes] = useState(null);
    const [constitution, setConstitution] = useState(null);
    //const [codexes, setCodexes] = useState(null);
    const location = useLocation();
    const path = location.pathname.split('/')[1];
    const pageTitle = path === 'plenum' ? 'Судебная практика' : 'Кодексы, законы, судебная практика';

    useEffect(() => {
        try {
            startTransition(async () => {
                const data = await femida.getCodexes(location.pathname);
                setCodexes(data);
                //console.log(data.at(44202));
                setConstitution(data.at(44202));
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    return (
        <Page>
            <PageHeader>{pageTitle}</PageHeader>
			<PageSearchBar sTarget={path} />
			
			{ /*<div className={`mb-4 ${s.codexSearch}`}>
                <SearchBar
                value={searchResult}
                onChange={(e) => setSearchResult(e.target.value)} />
            </div>
            {
                false &&
                <div className="search-result">
                    <RadioSelect wrap fields={['Все','Кодекс','Вопрос-ответ','Формы документов','Практические рекомендации']} />
                    <div className="d-flex flex-column align-items-center py-5">
                        <NotFoundIcon />
                        <b>Ничего не найдено</b>
                    </div>
                    <div className="search-result-category">
                        <small>Кодекс</small>
                    </div>
                    <CategoryItem
                    name="Ситуации: Глава 14 КоАП РФ"
                    description="предпринимательской деятельности, если такие действия причинили крупный ущерб. Примером может являться фиктив..."
                    url="1" />
                    <CategoryItem
                    name="Ситуации: Глава 14 КоАП РФ"
                    url="1" />
                </div>
            }
			*/ }
            <div className="d-flex flex-column gap-2">
                {
                    codexes?.length &&
                    codexes
                    .filter(c => c.title?.toLowerCase().trim()
                        .includes(searchResult.toLowerCase().trim()))
                    .map((codex, i) => 
                        <CategoryItem 
                            key={i}
							id={codex.id}
                            codex_id={codex.base64_uid} 
                            title={codex.title} />						
						)
                }
            </div>
            <div className="mt-5 contentpage-wrapper">			
                <p>В данном разделе реализована возможность просмотра информации из кодексов, а также сопутствующих разделов (<a href="/situation/">ситуации</a>, <a href="/qa/">вопрос-ответ</a>). Когда пользователь заходит в данную вкладку, то он видит быстрый старт: 9 популярных кодексов. Внизу есть кнопка «раскрыть», которая показывает все кодексы. Приложение выводит на экран главы, части, разделы, подразделы, параграфы и статьи кодекса, справочную информацию, содержащую данные документа (действующий или утратил силу, дата вступления в силу, последняя редакция и т.д.), а также аннотацию, которая позволит Вам ознакомиться с кратким содержанием документа.</p>
                <p>При нажатии на статью происходит переход на просмотр ее содержания. Помимо этого, выводится информация о самой статье, рассматриваются ситуации, регулируемые этой статьей или попадающие под ее действие, а так же вопросы-ответы, касающиеся разрешения этих ситуаций.</p>
            </div> 
			
            <CallCard2 />
        </Page>
    );
}

export default CodexPage;