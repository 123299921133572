import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
//import { fadeAnimation, slideUpAnimation } from "../../utils/animationSetting";
//import './styles.scss';

        let GooglePlayAppLink = "market://details?id=cat.my_advo.m";
        let AppleAppStoreLink = "https://itunes.apple.com/it/app/id6470447828";

const AppPopupWindow = (props) => {

    const [AppPopupWindow, setAppPopupWindow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
                
        let UnixTimeStamp = Math.round(Date.now() / 1000);
        let tmpAppPopupWindow = localStorage.getItem('AppPopupWindowTimer');
        let tryToGoToApplication = localStorage.getItem('tryToGoToApplication');

        if(tryToGoToApplication){
            console.log("tryToGoToApplication");
            localStorage.removeItem('tryToGoToApplication');
            //window.location.href = GooglePlayAppLink;
            let GPAL_A = document.createElement("a");
            GPAL_A.href=GooglePlayAppLink;
            GPAL_A.click();
            console.log("tryToGoToApplication");
            //navigate(GooglePlayAppLink);
        }

        if(
            tmpAppPopupWindow === null || // первый запуск
            tmpAppPopupWindow < (UnixTimeStamp - 86400) // прошли сутки
        ){
            localStorage.setItem('AppPopupWindowShowTrigger', 1);
            //setTimestampHideAppWindow();
        }


        setTimeout(()=> {
            let isMobileApplication = localStorage.getItem('isMobileApplication');
            if(!isMobileApplication){
                let AppPopupWindowShowTrigger = localStorage.getItem('AppPopupWindowShowTrigger');
                if(AppPopupWindowShowTrigger){
                    setAppPopupWindow(true);
                    localStorage.removeItem('AppPopupWindowShowTrigger');
                }
            }
        },2000);
    });


    const goToApplication = () => {
        localStorage.removeItem('AppPopupWindowTimer');
        localStorage.setItem('AppPopupWindowShowTrigger', 1);
        localStorage.setItem('tryToGoToApplication', 1);
        setAppPopupWindow(false);
        //window.location.href = "https://my-advo.cat"
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let GPAL_A = document.createElement("a");
        if(isSafari === true) GPAL_A.href = AppleAppStoreLink;
        else GPAL_A.href="https://my-advo.cat";
        console.log("goToApplication");
        GPAL_A.click();
    }

    /*const setTimestampHideAppWindow = () => {
        let UnixTimeStamp = Math.round(Date.now() / 1000);
        localStorage.setItem('AppPopupWindowTimer', UnixTimeStamp);
    }/**/

    const starIcon = ()=>{ return(<img 
        src="/assets/images/mobile-game-golden-star-clipart-design-illustration-free-png.png" 
        style={{
            width:"10px",
            float: "left",
            marginRight: "5px"
        }} 
        alt="Оценка 5" />);}

    return (
        <>
            {AppPopupWindow ? <>
                <div className="top-app-confirm" style={{
                    position:"relative",
                    marginBottom: "10px",
                    borderBottom: "solid 1px #ccc",
                    paddingBottom: "10px",
                    background: "#fff",
                    zIndex: 9,
                    paddingTop: "10px"
                }}>
                    <div onClick={()=>{goToApplication()}} style={{position:"relative",textAlign: "left"}}>
                        <img 
                            src="/assets/images/myac_icon.png" 
                            style={{
                                width:"20%",
                                maxWidth:"50px",
                                float: "left"
                            }} 
                            alt="Открыть в приложении" />
                        <div style={{
                            fontSize: "16px",
                            position: "relative",
                            display: "block",
                            margin: "3px 0 0 5px",
                            float: "left",
                            width: "60%"}}
                        >
                            <div className="">Открыть в приложении</div>
                                <div className="">
                                    {starIcon()}
                                    {starIcon()}
                                    {starIcon()}
                                    {starIcon()}
                                    {starIcon()}
                                </div>
                            </div>
                            <div style={{
                                    fontSize: "14px",
                                    position: "relative",
                                    display: "block",
                                    margin: "0",
                                    float: "left",
                                    width: "22%",
                                    fontWeight: "600",
                                    padding: "11px 7px",
                                    textAlign: "center",
                                    height: "49px",
                                    lineHeight: "27px"
                                }}
                                className="femida-btn"
                            >Открыть</div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="hr"></div>
            </>:<>
            
            </>}
        </>
    );
}

export default AppPopupWindow;