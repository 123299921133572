import crypto from '../crypto-browserify';
import { Buffer } from 'buffer';

const arrayBufferToBase64 = (arrayBuffer) => {
    let byteArray = new Uint8Array(arrayBuffer);
    let byteString = '';
    for (let i = 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCharCode(byteArray[i]);
    }
    const b64 = window.btoa(byteString);
    return b64;
}

const addNewLines = (str) => {
    let finalString = '';
    while(str.length > 0) {
        finalString += str.substring(0, 64) + '\n';
        str = str.substring(64);
    }
    return finalString;
}

const toPem = (Key, type = "private") => {
    const b64 = addNewLines( arrayBufferToBase64(Key));
    let pem = '';
    if(type === "private")
        pem = "-----BEGIN PRIVATE KEY-----\n" + b64 + "-----END PRIVATE KEY-----";
    if(type === "public")
        pem = "-----BEGIN PUBLIC KEY-----\n" + b64 + "-----END PUBLIC KEY-----";
    return pem;
}

export const genKeyPars = async (modulusLength = 2048, hash = 'SHA-256') => {

    const keyPair = await window.crypto.subtle.generateKey({
        name: "RSA-OAEP",
        modulusLength, // can be 1024, 2048 or 4096
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: {name: hash} // or SHA-512
    }, true, ["encrypt", "decrypt"]);

    const exportedPrivateKey = await window.crypto.subtle
        .exportKey("pkcs8", keyPair.privateKey);
    const pvs_private_Key = toPem(exportedPrivateKey);

    const exportedPublicKey = await window.crypto.subtle
        .exportKey("spki", keyPair.publicKey);
    const pvs_public_key = toPem(exportedPublicKey, "public");

    return {
        pvs_private_Key,
        pvs_public_key
    };
}

export const formDataFromObject = (data) => {
    let formData = new FormData();
    Object.keys(data).forEach(key => {
        if (key === 'avatar_image' && typeof data[key] !== 'string') {
            formData.append(key, data[key], 'avatar.jpg');
        } else {
            formData.append(key, data[key]);
        }
    });
    return formData;
}

export const filterObject = (obj, allowedFields, mapFields = []) => {
    let newObj = {};

    Object.keys(obj).forEach((key) => {
        if (allowedFields.includes(key)) {
            newObj[key] = obj[key];
        }
        if (mapFields.includes(key)) {
            obj[key].forEach((value, i) => {
                Object.keys(value).forEach((valueKey) => {
                    newObj[`${key}[${i}][${valueKey}]`] = value[valueKey];
                });
            })
        }
    });
    return newObj;
}

export const asyncCatcher = (fn) => {
    return new Promise(async (resolve, reject) => {
        fn(resolve, reject).catch(e => reject([null, e]));
    });
} 

export const apiUrlFromQuery = (query) => {
    const queryArray = query.slice(1, query.length).split('/');
	//console.log(queryArray);
    let url = '';
    const page = queryArray[0];
    const category = queryArray[1];
    const codexId = queryArray[2];
    let codex_id = '';
    switch(page) {
        case 'situation':
            codex_id = queryArray[2];
            url += '/api/situation/situation/list';

            if (codex_id) {
				codex_id = window.atob(codex_id);
				//console.log('codexId=' + codex_id);
                url += `?codex_id=${codex_id}&getcontent=1`;
            } else {
                switch(category) {
                    case 'partuk':
                        url += '?part=1';
                        break;
                    case 'partkoap':
                        url += '?part=2';
                        break;
                    case 'partnalog':
                        url += '?part=3';
                        break;
                    default:
                        break;
                }
            }
            return url;		
        case 'cases':
            url += '/api/situation/situation/list';
            if (codexId) {
                url += `?codex_id=${codexId}&getcontent=1`;
            } else {
                switch(category) {
                    case 'ugolov':
                        url += '?part=1';
                        break;
                    case 'administ':
                        url += '?part=2';
                        break;
                    case 'nalog':
                        url += '?part=3';
                        break;
                    default:
                        break;
                }
            }
            return url;
        case 'qa':
            url += '/api/qa/qa/list'; //?gn=ap&getcontent=1
            switch(category) {
                case 'ugolov':
                    url += '?gn=up&getcontent=1';
                    break;
                case 'administ':
                    url += '?gn=ap&getcontent=1';
                    break;
                case 'nalog':
                    url += '?gn=np&getcontent=1';
                    break;
                default:
				url += '?getcontent=1';
                    break;
            }
            return url;
        case 'docs':
            url += '/doc/document/list';
            switch(category) {
                case 'part13':
                    url += '?parent_id=13';
                    break;
                case 'part11':
                    url += '?parent_id=11';
                    break;
                case 'part12':
                    url += '?parent_id=12';
                    break;
                case 'part10':
                    url += '?parent_id=10';
                    break;
                case 'part52':
                    url += '?parent_id=52';
                    break;
                default:
                    break;
            }
            return url;			
        case 'doc':
            url += '/doc/document/list';
            switch(category) {
                case 'ugolov':
                    url += '?parent_id=13';
                    break;
                case 'administ':
                    url += '?parent_id=11';
                    break;
                case 'nalog':
                    url += '?parent_id=12';
                    break;
                case 'gover':
                    url += '?parent_id=10';
                    break;
                case 'document':
                    url += '?parent_id=52';
                    break;
                default:
                    break;
            }
            return url;
        case 'codex':
            url += '/api/codex/codex/list?recursive=1&pid=0&exclude=s';
			codex_id = queryArray[1];
            if (codex_id) {
				codex_id = window.atob(codex_id);
                url = `/api/codex/codex/list?exclude=s&pid=${codex_id}`;
            }
            return url;
        case 'plenum':
            url = '/api/plenum/plenum/list?recursive=1&pid=0&lvl=1';
            if (category) {
                const id = Buffer.from(category, 'utf-8').toString('base64');
                url = `/api/plenum/plenum/get?alias=${id}`;
            }
            return url;
        default:
            return url;
    }
}

export const fetchData = async (args) => { // always resolves
    return new Promise(async (resolve, reject) => {
        const { 
            url, 
            token, 
            payload, // payload type
            pop // 'true' to remove last element of payload arr 
        } = args;
        try {
            let res = null;
            if (token) {
                const headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);
                res = await fetch(url, { headers });
            } else {
                res = await fetch(url);
            }
            const data = await res.json();
            if (data.status === 200) {
                let list = null;
                //console.log("data");
                //console.log(data);
                switch(payload) {
                    case 'array':
                        list = Object.keys(data.payload.array).map(key =>
                            data.payload.array[key]);
                            break;
                    case 'list':
                        list = Object.keys(data.payload.list).map(key => 
                            data.payload.list[key]);
                            break;
                    default:
                        list = data.payload;
                        break;
                }
                if (pop) list.pop(); // to remove 'nodes' element
                resolve([list, null]);
            } else {
                resolve([null, data]);
            }
        } catch(err) {
            resolve([null, err]);
        }
    }).catch(e => console.log(e));
} 

export const isBase64 = (str) => {
    if (!str?.trim()) return false;
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export const PDec = (key, message) => {
    return message.toString('utf8');
    /*let decrypted = '';
    try {
        decrypted = crypto.privateDecrypt({
            key,
            padding: crypto.constants.RSA_PKCS1_PADDING,
            passphrase: '',
            }, Buffer.from(message, 'base64'));
    } catch (e) {
        console.log(e);
        decrypted = '[Ошибка дешифровки]';
    }
    return decrypted.toString('utf8');/**/
}

export const getMonthString = (month) => {
    let string = '';
    switch(month) {
        case 0:
            string = 'января';
            break;
        case 1:
            string = 'февраля';
            break;
        case 2:
            string = 'марта';
            break;
        case 3:
            string = 'апреля';
            break;
        case 4:
            string = 'мая';
            break;
        case 5:
            string = 'июня';
            break;
        case 6:
            string = 'июля';
            break;
        case 7:
            string = 'августа';
            break;
        case 8:
            string = 'сентября';
            break;
        case 9:
            string = 'октября';
            break;
        case 10:
            string = 'ноября';
            break;
        case 11:
            string = 'декабря';
            break;
        default:
            break;
    }
    return string;
}

export const getDateString = (timestamp) => {
    const date = new Date(Number(timestamp) * 1000);
    
    const day = date.getDate();
    const month = getMonthString(date.getMonth());
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
}

const SeparateArray = (array, bufferSize) => {
    let subarray = [];
    for (let i = 0, mcalb = Math.ceil(array.length/bufferSize); i < mcalb; i++){
        subarray[i] = array.slice((i*bufferSize), (i*bufferSize) + bufferSize);
    }
    return subarray;
}

const RsaEncryptHash = (encryptBuffer, public_key) => {
    var encrypted = crypto.publicEncrypt( {
           "key" : public_key, 
           padding : crypto.constants.RSA_PKCS1_PADDING
       }, encryptBuffer
   );
   return encrypted;
}

const MargeArray = (ArrayInner1, ArrayInner2) => {
    //let ai1Count = ArrayInner1.length;
    let ai2Count = ArrayInner2.length;
    for( let i = 0; i < ai2Count; i++){
        ArrayInner1.push(ArrayInner2[i]);
    }
    return ArrayInner1
}

const b64EncodeUnicode = (str) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}

const RsaEncrypt = ({inputBytes, PublicRsa}) => {
    if (!inputBytes) return false;
    //if (PublicRsa == null) console.log("public key can not be null");
    var bufferSize = (2048 / 8) - 11;
    var separatedArray = SeparateArray(inputBytes, bufferSize);
    let EncriptedArray = [];
    for(let i = 0; i < separatedArray.length; i++){
        let EncriptedTMP = RsaEncryptHash(separatedArray[i], PublicRsa);
        let EncriptedConcat = MargeArray(EncriptedArray, EncriptedTMP);
        EncriptedArray = EncriptedConcat;
    }
    return b64EncodeUnicode(EncriptedArray);
}

export const encryptMessage = ({message, key}) => {
    let encrypted = '';
    const encryptBuffer = Buffer.from(message);
    if(encryptBuffer.length <= 245){
        encrypted = crypto.publicEncrypt(
            {key, padding: crypto.constants.RSA_PKCS1_PADDING}, 
            encryptBuffer)
            .toString("base64");
    }else{
        encrypted = RsaEncrypt({
            inputBytes: encryptBuffer, 
            PublicRsa: key
        });
    }
    return encrypted;
}

export const encryptText = ({key, text}) => {
    try {
        const encryptBuffer = Buffer.from(text);
        const encrypted = crypto.publicEncrypt({
                key, 
                padding : crypto.constants.RSA_PKCS1_PADDING
            }, encryptBuffer).toString("base64");
        return encrypted;
    } catch(e) {
        console.log(e);
        return text;
    }
}

export function elementInArray(el, arElements) {

    // https://tc39.github.io/ecma262/#sec-array.prototype.includes
    if (!arElements.includes) {
        Object.defineProperty(arElements, "includes", {
            value: function(searchElement, fromIndex) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If len is 0, return false.
                if (len === 0) {
                    return false;
                }

                // 4. Let n be ? ToInteger(fromIndex).
                //    (If fromIndex is undefined, this step produces the value 0.)
                var n = fromIndex | 0;

                // 5. If n ≥ 0, then
                //  a. Let k be n.
                // 6. Else n < 0,
                //  a. Let k be len + n.
                //  b. If k < 0, let k be 0.
                var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

                // 7. Repeat, while k < len
                while (k < len) {
                    // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                    // b. If SameValueZero(searchElement, elementK) is true, return true.
                    // c. Increase k by 1.
                    // NOTE: === provides the correct "SameValueZero" comparison needed here.
                    if (o[k] === searchElement) {
                        return true;
                    }
                    k++;
                }

                // 8. Return false
                return false;
            },
        });
    }

    return arElements.includes(el);
}

export function escapeAndTrim(str) {
	return str
	        .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/[<>&'"/]/g, '')
            .replace(/\s{2,}/g, ' ')
            .trim();
}

export function simpleClean(str) {
    if (str.length < 1) return '';
    // eslint-disable-next-line
	let t0 = /<!--(.*?)\/\/-->/gmi;
    // eslint-disable-next-line
	let t1 = /\&copy\;|\&reg\;|\&sect\;|\&para\;|\&euro\;|\&pound\;|\&plusmn\;|\&frac14\;|\&frac12\;|\&frac34\;|\&times\;|\&divide\;|\&fnof\;|\&larr\;|\&uarr\;/gmi;
    // eslint-disable-next-line
	let t2 = /\&rarr\;|\&darr\;|\&harr\;|\&quot\;|\&amp\;|\&lt\;|\&gt\;|\&laquo\;|\&raquo\;|\&deg\;|\&gt\;|\&lt\;/gmi;
    // eslint-disable-next-line
	let t3 = /\&hellip\;|\&prime\;|\&nbsp\;|\&ndash\;|\&mdash\;|\&lsquo\;|\&rsquo\;|\&sbquo\;|\&ldquo\;|\&ordm\;|\&Oslash\;/gmi;
    // eslint-disable-next-line
	let t4 = /\&sup3\;|\&trade\;|\&asymp\;|\&bull\;|\&rdquo\;|\&bdquo\;/gmi;
    // eslint-disable-next-line
	let t5 = /\t|\n|\r|\0|\x0B|\xA0/gmi;

	return str
	        .replace(t0, " ")
			.replace(t1, " ")
            .replace(t2, " ")
			.replace(t3, " ")
			.replace(t4, " ")
			.replace(t5, " ")
            .replace(/\s{2,}/g, ' ')
            .trim();
}

export function replaceByTpl(str, regex, subst) {
   // const regex = /Глава/gm;

    // Alternative syntax using RegExp constructor
    const regExpr = new RegExp(regex, 'gm')

    //const str = `Глава 10 УК`;
    //const subst = `Рекомендации по главе`;

    // The substituted value will be contained in the result variable
    const result = str.replace(regExpr, subst);

    //console.log('Substitution result: ', result);
	return result;
}	

export function  normalizeCountForm(number, words_arr) {
    number = Math.abs(number);
    if (Number.isInteger(number)) {
        let options = [2, 0, 1, 1, 1, 2];
        return words_arr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
    }
    return words_arr[1];
}