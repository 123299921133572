import React, { startTransition, useEffect, useState } from "react"
import PageHeader from "../../PageHeader";
import SearchItem from "../../SearchItem";
import Page from "../../Page";
//import FormSearchBar from "../../FormSearchBar";
//import PageSearchBar from "../../PageSearchBar";
import { Link } from "react-router-dom";
import { femida } from "../../../api/app";
import FemidaLoader from "../../FemidaLoader";
import LoadingScreen from "../../LoadingScreen";
import NotFound from "../../NotFound";
import { useRef } from "react";
import Button from "../../Button";
import {FormErrors} from "./FormErrors";



import * as utils from '../../../api/utils.js';
import SearchSvg from './search.svg';
import './styles.css';

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

const SearchPage = (props) => {

    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [error, setError] = useState(false);
	
	const [searchText, setSearchText] = useState('');
    const [searchTarget, setSearchTarget] = useState('all');
    const [searchResultList, setSearchResultList] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pagesTotal, setPagesTotal] = useState(null);
	const [count, setCount] = useState(0);
	//const [count, setCount] = useState(0);
	const [viewHints, setViewHints] = useState(0);
    const [errors, setErrors] = useState({
        q: ''
    });	
    const [formResponceErrors, setFormResponceErrors] = useState({error: ''});
    const [isSubmitting, setIsSubmitting] = useState(false);	

	const [isPremium, setIsPremium] = useState(false);
	const [isAuth, setIsAuth] = useState(false);

	
    useEffect(() => {
        try {
            startTransition(async () => {
                setLoading(true);
				
                let params = new URLSearchParams(document.location.search);
				
				let query = params.get("q") || '';
                if (query != null) {
        	        query = utils.escapeAndTrim(query);
                } else {
					query = '';
				}
                console.log('query=' + query);
                if (query.length < 3) {
                    setLoading(false);
                    setError(true);
        	        return false;
                }
                
                let profileData = localStorage.getItem('profile');
				if(profileData){
					profileData = JSON.parse(profileData);
                    let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
                    setIsPremium(LSisPremium);
                }
				let LSisAuth = Boolean((localStorage.getItem('tokenAPI'))?true:false);
				setIsAuth(LSisAuth);
				
				let target = params.get("target") || '';
				if (target != null) {
        	        target = utils.escapeAndTrim(target);
                } else {
					target = '';
				}
                if (!target || !utils.elementInArray(target, ['qa', 'doc', 'situation', 'codex'])) {
        	        target = "all";
                }
                const data = await femida.getSearchItems({limit: 10, page: Number(params.get("page") || 1), q: query /*params.get("q") */ , target: target /*params.get("target")*/});
		
				setSearchResultList(data.searchResultList);
				
	            setSearchText(params.get("q"));
                setSearchTarget(params.get("target"));
				setCount(Number(data.itemsTotal || 0));
				setPagesTotal(data.pagesTotal);
				setCurrentPage(Number(params.get("page") || 1));
                setLoading(false);
            });
        } catch(e) {
            startTransition(() => {
                setLoading(false);
                setError(true);
            });
        }
    }, []);

    const handleLoadMore = () => {
        try {
            startTransition(async () => {
                setBtnLoading(true);
                const data = await femida.getSearchItems({
                    page: currentPage + 1,
                    limit: 10,
                    q: searchText, 
					target: searchTarget					
                });

				console.log('data =2= ');				

                console.log(searchResultList);				
                console.log('=2= data');				
				console.log('data =1= ');				

                console.log(data.searchResultList);				
                console.log('=1= data');				
                setSearchResultList(
				    [...searchResultList, ...data.searchResultList]
				);	

				console.log('data =3= ');				

                console.log(searchResultList);				
                console.log('=3= data');					
                setCurrentPage(currentPage + 1);
                setBtnLoading(false);				
            });
        } catch (e) {
            setBtnLoading(false);
        }
    }



    const searchTextChange = (e) => {
	    setSearchText(e.target.value);
		console.log('e=' + e.target.value);
		console.log('SearchText=' + searchText);
    }
	
    const escapeAndTrim = (str) => {
        str
            .replace(/[<>&'"/]/g, '')
            .replace(/\s{2,}/g, ' ')
            .trim();	
	}
	
    const getComplete = async (e) => {
	    setSearchText(e.target.value);
		console.log('e=' + e.target.value);
		console.log('SearchText=' + searchText);		
		
        let query = e.target.value; //document.querySelector("input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;

        if (query != null) {
        	query = utils.escapeAndTrim(query);
        } else {
			query = '';
		}
        console.log('query=' + query);
        if (query.length < 3) {
        	document.querySelector("#search-bar-header-page").innerHTML = '';
			setViewHints(0);
        	return false;
        }

        if (target != null) {
        	target = utils.escapeAndTrim(target);
        } else {
			target = '';
		}
        if (!target || !utils.elementInArray(target, ['qa', 'doc', 'situation', 'codex', 'all'])) {
        	target = "all";
        }
		
        console.log('target=' + target);
       	//target = '&target=' + target;

        /*
        if (query != null) {
           // query = escapeAndTrim(query);
        }
        console.log('query='+query);
		
        if (!query.length) {
            return false;
        }
        if (target != null) {
            //target = escapeAndTrim(target);
        }
        console.log('target='+target);
        if (target.length) {
            target = '&target=' + target;
        } else {
            target = '';
        }*/

        let url = 'https://api.my-advo.cat/api/search/complete?q=' + query + '&target=' + target;

        const api_ResourceLL = await fetch(url, {headers: headers});
        var output = await api_ResourceLL.json();

        if (output.status === 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for (let i = 0; i < list.length; i++) {
                let linkTitleTmp = list[i].tags;
                let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
                resultComplete += "<div onclick='window.location.href=\""+linkUrlTmp+"\";'>"+linkTitleTmp+"</div>";
            }
            //document.querySelector("#search-bar-header-page").innerHTML = resultComplete;
			
			
        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery !== null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	} else {
				curQuery = '';
			}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector("#search-bar-header-page").innerHTML = resultComplete;
				if (resultComplete.length > 5) {
				    setViewHints(1);
				} else {
					setViewHints(0);
				}				
        	} else {
        		document.querySelector("#search-bar-header-page").innerHTML = '';
				setViewHints(0);
        	}			
        } else {
            return false;
        }
    }	
	
	
	
	
    const searchFormSubmit = (e) => {
		let el = document.querySelector('input.header-search-input');
	    setSearchText(e.target.value);
		console.log('e=' + e.target.value);
		console.log('SearchText=' + searchText);
    }



    const handleFormSubmit = async (e) => {
		
			errors.q = 
			setFormResponceErrors({
                error: ''
            });	
			
			
            let el = document.querySelector('input[name=q]');
	        if (!el.value) {
                errors.q = 'Не указан поисковый запрос.';
            } else if (el.value.length < 3) {
                 errors.q = 'Минимальная длина поискового запроса - 3 символа.';
            } 
	        
            setErrors({
                ...errors,
                q: errors.q || ''
            });


			

      if (Object.values(errors).join('').length === 0) {
		setIsSubmitting(true);
    } else {
		e.preventDefault();
			let errorStr = "Внимание! " +errors.q;						

			setFormResponceErrors({
                error: errorStr
            });			
		  setIsSubmitting(false);
	}
	
		    if  (Object.values(errors).join('').length === 0 /*&& isSubmitting*/) {
				return true;
			}  else {
                return false;				
			}		
    }	

//<FormSearchBar addClassName={`${errors.q && 'is-danger'}`} sText={searchText} sTarget={searchTarget} onChange={getComplete} onSubmit={handleFormSubmit} />
    return (
        <Page>
            <PageHeader>Результаты поиска</PageHeader>
		<div className="nav-search-contact-div mb-3" style={{display: "flex",alignItems: "center"}}>
			<form className="uk-search header-search-bar" style={{width: "100%"}} method="GET" onSubmit={handleFormSubmit} action="/search/" noValidate>
				<input type="hidden" name="target" value={['all', 'codex', 'qa', 'situation', 'doc'].includes(searchTarget) ? searchTarget : "all"} />
				<div className="search-div header-search-click">
					<input type="image" id="edit-submit" alt="" name="submit" src={SearchSvg} className="header-search-click" />
					<input
						value={searchText}
						onChange={getComplete} 
						type="text"
						name="q"
						id="header-search"
						placeholder="что делать, если..."
						autocomplete="off"
						required="required"
						className={`search-input header-search-click ${errors.q && 'is-danger'}`}
						minLength={3}
						maxLength={255}
						data-append-on="search-bar-header-page"
					/>
					
				</div>
				<div id="search-bar-header-page" className={`search-bar-home-page header-search-click ${viewHints && 'ds-flex'}`}></div>
			</form>
		</div>				
			
            
			<a href={"/search/?target=all&q=" + searchText}><button className={`femida-search-btn ${searchTarget && searchTarget==='all' && 'active'}`}>Все</button></a>
			<a href={"/search/?target=codex&q=" + searchText}><button className={`femida-search-btn ${searchTarget && searchTarget==='codex' && 'active'}`}>Кодексы, законы, судебная практика</button></a>
			{isAuth ? <a href={"/search/?target=situation&q=" + searchText}><button className={`femida-search-btn ${searchTarget && searchTarget==='situation' && 'active'}`}>Практические рекомендации</button></a>:<></>}
			<a href={"/search/?target=qa&q=" + searchText}><button className={`femida-search-btn ${searchTarget && searchTarget==='qa' && 'active'}`}>Вопрос-ответ</button></a>
			<a href={"/search/?target=doc&q=" + searchText}><button className={`femida-search-btn ${searchTarget && searchTarget==='doc' && 'active'}`}>Правовые документы</button></a>
			
			<div className="panel panel-default" id="form_result">
                <FormErrors formErrors={formResponceErrors} />
            </div>
            {
            error ?
            <span>Не удалось загрузить 😢</span> :
            loading ?
            <LoadingScreen /> :
            !searchResultList ? <><NotFound /></> :
            <div className="d-flex flex-column gap-3">

                {searchResultList && searchResultList.map((element) => {
                        return (
						<>
					        <SearchItem {...element} />
                        </>
						 );
                    }
                )}

                {
                    !(currentPage < pagesTotal) ? <></> :
                    <Button
                    loading={btnLoading} 
                    onClick={handleLoadMore}>Показать еще</Button>
                }

            </div>
        }
        </Page>
    );
}

export default SearchPage;