import React from "react"
import s from './styles.module.scss'; 

const Card = (props) => {

    const {
        image,
        name,
        date,
        text
    } = props;

    return (
        <div className={s.card}>
            <div className="d-flex gap-2 align-items-center mb-1">
                <div className={`round-wrap ${s.imgWrap}`}>
                    <img src={image} alt="avatar" />
                </div>
                <div className="d-flex flex-column gap-1">
                    <b>{name}</b>
                    <span className="text-secondary">{date}</span>
                </div>
            </div>
            <span>{text}</span>
        </div>
    );
}

export default Card;