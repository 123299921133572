import React from "react"
import BackButton from '../BackButton';
import './styles.scss';

const PageHeader = (props) => {

    const { children } = props;

    let pluginClass = "";
    if(typeof children === "string"){
        if(children?.trim().length > 60) pluginClass = "category-title-too-mach ";
    }
    return (
        <div className="page-header mb-2">
            <div className="justify-self-start">
                <BackButton />
            </div>
            { 
                children && 
                <h5 className={`category-title ${pluginClass}position-absolute w-100 text-center pe-none p-5`} dangerouslySetInnerHTML={{__html:children}}></h5>
            }
        </div>
    );
}


export const PageHeader2 = (props) => {

    const { children } = props;

    let pluginClass = "";
    if(typeof children === "string"){
        if(children?.trim().length > 60) pluginClass = "category-title-too-mach ";
    }
    return (
        <div className="page-header page-header-transparent mb-2" style={{background: "#293a4b", margin:"0"}}>
            <div className="justify-self-start">
                <BackButton />
            </div>
            { 
                children ?
                <h5 className={`category-title ${pluginClass}position-absolute w-100 text-center pe-none p-5`} dangerouslySetInnerHTML={{__html:children}}></h5>
                : <></>
            }
        </div>
    );
}

export default PageHeader;