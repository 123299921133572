import React, { startTransition, useContext, useEffect, useState } from "react";
import { fadeAnimation } from "../../../utils/animationSetting";
import Page from "../../Page";
import PageHeader from "../../PageHeader";
import { ReactComponent as QuestionIcon } from './assets/question.svg';
import { ReactComponent as ExitIcon } from './assets/exit.svg';

import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import LoadingScreen from "../../LoadingScreen";
import { fetchProfileData } from "../../../utils/user";

const ProfilePage = (props) => {

    const navigate = useNavigate();
    const {user, setUser} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
	
    useEffect(() => {
        if (!localStorage.getItem('tokenAPI')) {
            navigate('/login');
            return;
        }
        try {
            setLoading(true);
				
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));
        let url = 'https://api.my-advo.cat/api/user/get-profile';
        fetch(url, {
            method: 'GET',
            headers: headers
        })
            .then(response => response.json())
            .then((response) => {
                if(response.status !== 200){
                    localStorage.removeItem('tokenAPI');
                    localStorage.removeItem('id_user');
                    localStorage.removeItem('public_key');
                    localStorage.removeItem('user');
                    window.location.href = '/login';	
                }
                setUserInfo(response.payload);
                setLoading(false);			

                let gender = 'не указан';
                if (response.payload.gender){
                    if (response.payload.gender === "F") {
                        gender = 'женский';
                    } else if (response.payload.gender === "M") {
						gender = 'мужской';
					}
                }
					
					let accountTypes = {
						1: "Администратор",
                        2: "Клиент",
                        4: "Клиент без оплаты",
                        7: "Премиум Клиент",
                        8: "Адвокат - Чат",
                        9: "Оператор звонков"
                    };
                    let accountType = 'Обычный';
                    if (response.payload.type_acc){
					

                    for (const [key, value] of Object.entries(accountTypes)) {
                    if (key === response.payload.type_acc) {
                        accountType = value;
                    }
                    }					
				}	

				let lastActive = null;
                if (response.payload.last_active !== null){
					lastActive = response.payload.last_active;
                }	
                    setUserInfo({
                        ...response.payload,
						lastActive: lastActive,
                        accountType: accountType,
                        genderAlias: gender,
                        isPremiumUser: response.payload.premium,
						phones: response.payload.phones,
						avatar_image: response.payload.avatar_image || '/logo192.png'//'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP88eN7PQAJPwNoN++SxwAAAABJRU5ErkJggg=='
                    });
            })
			.catch(error => {
                    console.log(error);
					alert('something went wrong');
                });
                setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('tokenAPI');
        localStorage.removeItem('id_user');
        localStorage.removeItem('public_key');
        localStorage.removeItem('user');		
        localStorage.clear();
        setUser(null);
        navigate('/login', {replace: true})
    }

    let stylePremiumShild = {
        backgroundColor: "#04B6F5",
        fontSize: "16px",
        borderRadius: "16px",
        padding: "10px 13px",
        fontWeight: "400"
    };

    return (
        loading ?
        <LoadingScreen />
        :
        <Page {...fadeAnimation}>
		    <PageHeader>Мой кабинет</PageHeader>
            <div className="my-3 d-flex flex-column align-items-center gap-2">
                <div className="profile-pic-w">
                    <img src={userInfo?.avatar_image ? userInfo?.avatar_image : '/logo192.png'} alt="" />
                </div>
                <span className="category-title text-center">{userInfo?.name || ''}</span>
                {(userInfo && (userInfo.sand_time > 0))?
                    <span className="badge text-white" style={stylePremiumShild}>Триал {Math.floor(userInfo.sand_time / (60 * 60 * 24))} дней</span>
                :
                    <></>
                }
                
                {/*<small>Был{userInfo?.gender && userInfo?.gender=="F" ? <>a</> : <></>} в сети: {userInfo?.lastActive !== null ? <>{userInfo?.lastActive}</> : <>недавно</>}</small>{/**/}
            </div>
            <div className="d-flex gap-3 mt-4 mb-4">
                <Link to="edit" className="flex-grow-1">
                    <button className="femida-btn w-100 default slim">Изменить данные</button>
                </Link>
                <Link to="change-password" className="flex-grow-1">
                    <button className="femida-btn w-100 default slim">Сменить пароль</button>
                </Link>
                {/*<button className="femida-btn default slim flex-grow-1 btn-exit" onClick={handleLogout}><ExitIcon /></button>{/**/}
			</div>
            {/*<div className="d-flex flex-column gap-1 mt-4 mb-3">
                <small className="text-secondary">О себе</small>
                <span>{userInfo?.description || ''}</span>
				</div>*/}
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Номер телефона</small>
                <span>{userInfo?.phones || ''}</span>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Почта</small>
                <span>{userInfo?.login || ''}</span>
            </div>
            <div className="d-flex justify-content-between pe-5">
                <div className="d-flex flex-column gap-1 mb-3">
                    <small className="text-secondary">Пол</small>
                    <span>{userInfo?.genderAlias || 'Не указан'}</span>
                </div>
                <div className="d-flex flex-column gap-1 mb-3">
                    <small className="text-secondary">Дата рождения</small>
                    <span>{userInfo?.birthday === "0000-00-00" ? 'Не указана' : userInfo?.birthday}</span>
                </div>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
                <small className="text-secondary">Адрес</small>
                <span>{userInfo?.address || 'Не указан'}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
			

                <div className="d-flex flex-column gap-1">
                    <small className="text-secondary">Аккаунт</small>
                    {userInfo && userInfo.isPremiumUser 
                    ?		<b>Триал</b>
                        :			
                            <b>Обычный <QuestionIcon /></b>
                    }
                </div>
				
				{/*userInfo && !userInfo.isPremiumUser 
				?
				<>
                <div>
                    <Link to="/tarifs">
                        <button className="femida-btn slim">Купить премиум</button>
                    </Link>
                </div>
				</>
				: <div>
                <Link to="/tarifs">
                    <button className="femida-btn slim">Придлить премиум</button>
                </Link>
            </div>
				/**/}
            </div>
            <div className="mb-4" role="button" onClick={handleLogout}>
                <span className="femida-link">Выход</span>
            </div>
        </Page>
    );
}

export default ProfilePage;