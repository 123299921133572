import React from "react";
import s from './styles.module.scss';

const RadioSelect = (props) => {

    const {
        fields, 
        wrap,
        value,
        onChange
    } = props;

    return (
        <fieldset className={`${s.optionsWrap} ${wrap ? s.wrap : ''}`}>
            {
                fields.map((field, i) => 
                <label 
                key={i}
                role="button" 
                className={s.label}>
                    <span 
                    className={`${s.sortTypeText} ${value === field.value+''?s.active:''}`}>{field.name}</span>
                    <input 
                    type="radio" 
                    value={field.value}
                    onChange={onChange}
                    checked={value === field.value}
                    className="d-none" />
                </label>)
            }
        </fieldset>
    );
}

export default RadioSelect;