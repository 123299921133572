import React from "react";
import './styles.css';

const LoadingScreen = (props) => {
    return (
        <div className="loading-screen">
            <div className="spinner-border" role="status">
            </div>
        </div>
    );
}

export default LoadingScreen;