export default function validate(values) {
  let errors = {};
  if (!values.email) {
    errors.email = 'Поле обязательно для заполнения.';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Укажите корректный электронный адрес.';
  }
  if (!values.password) {
    errors.password = 'Поле обязательно для заполнения.';
  } else if (values.password.length < 2) {
    errors.password = 'Минимальная длина пароля - 6 символов.';
  }
  return errors;
};
