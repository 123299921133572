import React, { startTransition, useEffect, useState, useContext } from "react";
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import Article from "../../Article";
import Breadcrumbs from "../../Article/Breadcrumbs";
import Header from "../../Article/Header";
import Paragraph from "../../Article/Paragraph";
import { femida } from "../../../api/app";
import { Link, useLocation } from "react-router-dom";
import LoadingScreen from "../../LoadingScreen";
import { UserContext } from "../../../context/UserContext";
import parse from 'html-react-parser';
import FemidaCollapse from "../../FemidaCollapse";
import PageSearchBar from "../../PageSearchBar";

const AboutPage = (props) => {

    return (
	<>
        <Page>
			<PageHeader>О проекте «Мой адвокат»</PageHeader> 
            <Article>
				<PageSearchBar />
				<div className="col-12 mt-3 mb-3 about_wrapper section-padding overflow-hidden">
				<div className="row g-0">
					<div className="col-12 mt-1">
						<div className="about_content about_content_2">
						{/*<div className="d-flex justify-content-between align-items-center mb-3">
								<h2 className="category-title">О нас</h2>
								<a className="femida-link" href="/about/" title="Подробнее о нас">Подробнее</a>
                        </div>*/}
							{/*<h3>Квалифицированная, оперативная, конфиденциальная юридическая помощь людям, попавшим в трудную жизненную ситуацию.</h3>*/}
							<p>«Мой адвокат» — это правовая система, где каждый найдет ответ по любому юридическому вопросу от опытных адвокатов и юристов по всей РФ.</p>
							<div className="row mt-4 g-0">
								<div className="col-3 author_img"><img style={{width: "100%"}} src="https://my-advo.cat/assets/images/panel/chulkov_vv.jpg" alt="Подпись Вячеслава Чулкова" /></div>
								<div className="col-9" style={{paddingLeft: "15px"}}>
									<p className="author_desc">Получите консультацию в кратчайшие сроки в нашей круглосуточной службе поддержки.</p>
									<div className="row g-0">
										<div className="col-5 g-0">
											<img style={{maxHeight: "39px"}} src="https://my-advo.cat/assets/images/panel/signature.svg" alt="Подпись Вячеслава Чулкова" />
										</div>						
										<div className="col-7">
											<div className="author_name"><h5>Вячеслав Чулков</h5><small>(адвокат)</small></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>				
				<p>Наша правовая система призвана помочь людям, попавшим в трудную жизненную ситуацию. Каждый зарегистрированный пользователь сможет выбрать адвоката или юриста из каталога для консультации и решения проблем с законом. Проблемы могут быть любого характера:</p>
				<ul>
					<li>Уголовное преследование (задержания, побои, аресты, обыски, вызовы и др.);</li>
					<li>Административные нарушения (штрафы за нарушение тишины, ПДД и др.);</li>
					<li>Бытовые споры (затопление соседей, порча чужого имущества и др.);</li>
				</ul>
				<p>Круглосуточная работа наших специалистов позволяет гражданам обращаться в любое удобное время. Служба поддержки ответит на любой вопрос в течение 5 минут. Экстренные ситуации требуют незамедлительного решения, с чем Вам поможет наша правовая система. Сделать правильный выбор поможет рейтинг и отзывы от клиентов.</p>
				<p>Общение со специалистами возможно онлайн, с помощью звонка и видеосвязи. Это позволяет не посещать офис для получения помощи и консультаций.</p>
				<p>Правовая система «Мой адвокат» разработана для всех и каждого! Стать полноценным пользователем может любой желающий - бизнесмен или простой гражданин. Оформление подписки дает пользователям полноценную поддержку от правовой системы по любым вопросам.</p>
				<p>Наши адвокаты и юристы готовы экстренно выехать для оказания квалифицированной помощи в экстренных случаях, даже в тех ситуациях, когда Вы не сможете выйти на связь. Специалисты с Вами свяжутся или отследят ваше местоположение, чтобы прибыть вовремя.</p>
				<p>Таким образом пользователи системы получают квалифицированную помощь, а юристы и адвокаты &mdash; опыт, развитие в рабочей сфере, возможность проявить свои способности в юриспруденции и поучаствовать в новом перспективном проекте!</p>
				{/*}
					<h5 className="mt-5 mb-3">Карточка предприятия</h5>

					<p><b>Полное наименование:</b> Общество с ограниченной ответственностью «МОЙ АДВО.КАТ»</p>
					<p><b>Сокращенное наименование:</b> ООО "МОЙ АДВО"</p>
					<p><b>Юридический адрес:</b> 454080, Челябинская область, город Челябинск, улица Труда, дом 156 помещение 23, офис 1</p>
					<p><b>Почтовый адрес:</b> 454080, Челябинская область, город Челябинск, улица Труда, дом 156 помещение 23, офис 1</p>
					<p><b>Телефон/факс:</b> <a href="tel:+73517779499">+7(351)777-94-99</a>, <a href="tel:+79222343222">8(922)234-32-22</a></p>
					<p><b>ИНН/КПП:</b> 7453339370/ 745301001</p>
					<p><b>ОГРН:</b> 1217400004733</p>
					<p><b>Классификаторы в статистическом регистре:</b> ОКВЭД 63.11 </p>
					<p><b>Директор:</b> Криушичев Александр Георгиевич, Действует на основании Устава</p>
					<p><b>E-mail:</b> <a href="mailto:m-advokat@inbox.ru">m-advokat@inbox.ru</a></p>		
					<p><b>Лицензионное соглашение:</b> <a href="/assets/files/license.docx" >Скачать</a></p>		
					{/**/}
			</Article>
        </Page>
		</>
    );
}

export default AboutPage;