import React, {useState } from "react"
import SearchSvg from './search.svg';
import './styles.css';

import * as utils from '../../api/utils.js';


let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

const PageSearchBar = (props) => {
	
    const {
        sTarget
    } = props;	
    

    const [error, setError] = useState(false);
	const [viewHints, setViewHints] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);	
	const [searchText, setSearchText] = useState('');
    const [searchTarget, setSearchTarget] = useState('all');
    const [errors, setErrors] = useState({
        q: ''
    });	

    const getComplete = async (e) => {
	    setSearchText(e.target.value);
		console.log('e=' + e.target.value);
		console.log('SearchText=' + searchText);		
		
        let query = e.target.value; //document.querySelector("input[name=q]").value;
        let target = document.querySelector("input[name=target]").value;
		
		
        if (query != null) {
        	query = utils.escapeAndTrim(query);
        }
        console.log('query=' + query);
        if (query.length < 3) {
        	document.querySelector("#search-bar-header-page").innerHTML = '';
			setViewHints(0);
        	return false;
        }


		if (target != null) {
        	target = utils.escapeAndTrim(target);
        }
        if (!target || !utils.elementInArray(target, ['qa', 'doc', 'situation', 'codex'])) {
        	target = "all";
        }
        console.log('target=' + target);
      // 	target = '&target=' + target;
		

        let url = 'https://api.my-advo.cat/api/search/complete?q=' + query + '&target=' + target;

        const api_ResourceLL = await fetch(url, {headers: headers});
        var output = await api_ResourceLL.json();

        if (output.status === 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for (let i = 0; i < list.length; i++) {
                let linkTitleTmp = list[i].tags;
                let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
                resultComplete += "<div onclick='window.location.href=\""+linkUrlTmp+"\";'>"+linkTitleTmp+"</div>";
            }
            //document.querySelector(".search_autocomplete").innerHTML = resultComplete;
			
			
        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery != null) {
        		curQuery = utils.escapeAndTrim(curQuery);
        	}
        	console.log('curQuery=' + curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector("#search-bar-header-page").innerHTML = resultComplete;
				if (resultComplete.length > 5) {
				    setViewHints(1);
				} else {
					setViewHints(0);
				}
        	} else {
        		document.querySelector("#search-bar-header-page").innerHTML = '';
				setViewHints(0);
        	}			
        } else {
            return false;
        }
    }	


	
	

    const handleFormSubmit = async (e) => {

    	errors.q = '';

    	let query = document.querySelector('input[name=q]').value;
		
        if (query != null) {
        	query = utils.escapeAndTrim(query);
        }
        console.log('query=' + query);
		
    	if (!query) {
    		errors.q = 'Не указан поисковый запрос.';
    	} else if (query.length < 3) {
    		errors.q = 'Минимальная длина поискового запроса - 3 символа.';
    	}
	
    	setErrors({
    		...errors,
    		q: errors.q || ''
    	});

    	if (Object.values(errors).join('').length === 0) {
    		return true;
    	} else {
    		e.preventDefault();
    		return false;
    	}
    }
//<img className="header-search-click" alt="Найти" src={SearchSvg} role="button" type="submit" />

    return (
	<>
		<div className="nav-search-contact-div mb-2 mt-3" style={{display: "flex",alignItems: "center"}}>
			<form className="uk-search header-search-bar" style={{width: "100%"}} method="GET" onSubmit={handleFormSubmit} action="/search/" noValidate>
				<input type="hidden" name="target" value={['all', 'codex', 'qa', 'situation', 'doc'].includes(sTarget) ? sTarget : "all"} />
				{/*<div className="search-div header-search-click">/**/}
				<div className="flex-grow-1 d-flex gap-2 align-items-center bg-white rounded-pill px-3 py-2">
					<input type="image" alt="" id="edit-submit" name="submit" src={SearchSvg} className="header-search-click" />
					<input
						value={searchText}
						onChange={getComplete} 
						type="text"
						name="q"
						id="header-search"
						placeholder="что делать, если..."
						autoComplete="off"
						required="required"
						className={`header-search-input ${errors.q && 'is-danger'}`}
						//className={`search-input header-search-click ${errors.q && 'is-danger'}`}
						minLength={3}
						maxLength={255}
						data-append-on="search-bar-header-page"
					/>
					
				</div>
				<div id="search-bar-header-page" className={`search-bar-home-page header-search-click ${viewHints && 'ds-flex'}`}></div>
			</form>
		</div>
	</>
    );
}


export const PageSearchBar2 = (props) => {
    const { sTarget } = props;

	const [viewHints, setViewHints] = useState(0);
	const [searchText, setSearchText] = useState('');
    const [errors, setErrors] = useState({q: ''});

	let SearchTargets = ['qa', 'doc', 'situation', 'codex'];

    const getComplete = async (e) => {
	    setSearchText(e.target.value);	
		
        let query = e.target.value;
        let target = document.querySelector("input[name=target]").value;
        if (query !== null) query = utils.escapeAndTrim(query);
        if (query.length < 3) {
        	document.querySelector("#search-bar-header-page").innerHTML = '';
			setViewHints(0);
        	return false;
        }
		if (target !== null) target = utils.escapeAndTrim(target);
        if (!target || !utils.elementInArray(target, SearchTargets)) target = "all";
		

        let url = 'https://api.my-advo.cat/api/search/complete?q=' + query + '&target=' + target;

        const api_ResourceLL = await fetch(url, {headers: headers});
        var output = await api_ResourceLL.json();

        if (output.status === 200) {
            let list = output.payload.list;
            let resultComplete = "";
            for (let i = 0; i < list.length; i++) {
                let linkTitleTmp = list[i].tags;
                let linkUrlTmp = '/search/?target=' + target + '&q=' + linkTitleTmp.split(" ").join("+");
                resultComplete += "<div onclick='window.location.href=\""+linkUrlTmp+"\";'>"+linkTitleTmp+"</div>";
            }
        	let curQuery = document.querySelector("form input[name=q]").value;
        	if (curQuery !== null) curQuery = utils.escapeAndTrim(curQuery);
        	if (curQuery.length > 2) {
        		document.querySelector("#search-bar-header-page").innerHTML = resultComplete;
				if (resultComplete.length > 5) {
				    setViewHints(1);
				} else {
					setViewHints(0);
				}
        	} else {
        		document.querySelector("#search-bar-header-page").innerHTML = '';
				setViewHints(0);
        	}			
        } else {
            return false;
        }
    }

    const handleFormSubmit = async (e) => {
    	errors.q = '';
    	let query = document.querySelector('input[name=q]').value;
        if (query !== null) query = utils.escapeAndTrim(query);
    	if (!query) {
    		errors.q = 'Не указан поисковый запрос.';
    	} else if (query.length < 3) {
    		errors.q = 'Минимальная длина поискового запроса - 3 символа.';
    	}
	
    	setErrors({
    		...errors,
    		q: errors.q || ''
    	});

    	if (Object.values(errors).join('').length === 0) {
    		return true;
    	} else {
    		e.preventDefault();
    		return false;
    	}
    }

    return (
		<>
			<div className="nav-search-contact-div mb-2 mt-2" style={{display: "flex",alignItems: "center"}}>
				<form className="uk-search header-search-bar" style={{width: "100%"}} method="GET" onSubmit={handleFormSubmit} action="/search/" noValidate>
					<input type="hidden" name="target" value={['all', 'codex', 'qa', 'situation', 'doc'].includes(sTarget) ? sTarget : "all"} />
					<div className="flex-grow-1 d-flex gap-2 align-items-center bg-white rounded-pill px-3 py-2">
						<input
							value={searchText}
							onChange={getComplete} 
							type="text"
							name="q"
							id="header-search"
							placeholder="Что делать, если?"
							autoComplete="off"
							required="required"
							className={`header-search-input ${errors.q && 'is-danger'}`}
							minLength={3}
							maxLength={255}
							data-append-on="search-bar-header-page"
							style={{minWidth:"90%"}}
						/>
						<input type="image" id="edit-submit" alt="" name="submit" src={SearchSvg} className="header-search-click" />
					</div>
					<div id="search-bar-header-page" className={`search-bar-home-page header-search-click ${viewHints && 'ds-flex'}`}></div>
				</form>
			</div>
		</>
    );
}

export default PageSearchBar;