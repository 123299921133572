import React from "react"
import SearchSvg from './search.svg';
import './styles.css';

const SearchBar = (props) => {

    const {
		target,
        value,
        onChange
    } = props;

    return (
        <div className="flex-grow-1 d-flex gap-2 align-items-center bg-white rounded-pill px-3 py-2">
		    <input type="hidden" name="target" value={target} />
            <img src={SearchSvg} alt="Найти" role="button" type="submit" />
            <input
            value={value}
            onChange={onChange} 
            type="text"
            name="q" 			
            placeholder="Поиск по названию..."
            className="header-search-input" />
        </div>
    );
}

export default SearchBar;