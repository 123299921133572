import React, { startTransition, useContext, useEffect, useState } from "react"
import Button from "../../Button";
import Page from "../../Page";
import PageHeader from "../../PageHeader";
import { AnimatePresence } from "framer-motion";
import Notification from "../../Notification";
import SlideUp from "../../SlideUp";
import { femida } from "../../../api/app";
import { useParams, useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { Document, Page as PdfPage } from 'react-pdf';
import LoadingScreen from "../../LoadingScreen";
import './styles.scss';
import { ReactComponent as ArrowPrevIcon } from './arrow-prev.svg';
import { ReactComponent as ArrowNextIcon } from './arrow-next.svg';

import { pdfjs } from 'react-pdf';
import FemidaLoader from "../../FemidaLoader";
import TextInputGroup from "../../TextInputGroup";

import { saveAs } from 'file-saver';

// react-pdf fix
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `https://m.my-advo.cat/assets/js/pdf.worker.min.js`;

let APIendpoint = "https://api.my-advo.cat";

let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));

const TemplatePage = (props) => {

    const {id} = useParams();
    //const {user} = useContext(UserContext);
	const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();

    //auth
    const [isPremium, setIsPremium] = useState(false);

    // get Data
    const [variables, setVariables] = useState([]);
    const [docFiles, setDocFiles] = useState(null);
    const [devServerUrl, setDevServerUrl] = useState("");

    
    // pdf state
    const [doc, setDocData] = useState(null);
    const [numPages, setNumPages] = useState(1);
    const [activePage, setActivePage] = useState(1);

    // UI
    const [templateForm, showTemplateForm] = useState(false);
    const [notification, showNotification] = useState(false);
    const [templateFilled, setTemplateFilled] = useState(false);

    // form 
    const [formData, setFormData] = useState(null);
    const [formState, setFormState] = useState({});

    useEffect(() => {
		
        /*if (!localStorage.getItem('tokenAPI')) {
            navigate('/login');
            return;
        }		/**/
		
        //if (!user) navigate('/login');
        try {
            startTransition(async () => {
				
/*let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + localStorage.getItem('tokenAPI'));	*/			
                const data = await femida.getDocData({
                    id
					/*,token: user?.token*/
                });
                /*if(data.filepdfs.path != undefined){
                  data.FilePDFLink = "https://api.my-advo.cat/uploads/" + 
                      data.filepdfs.path +
                      data.filepdfs.name;
                }
                if(data.filedocxs.path != undefined){
                  data.FileDOCXLink = "https://api.my-advo.cat/uploads/" + 
                    data.filedocxs.path +
                    data.filedocxs.name;
                }/**/
                let profileData = localStorage.getItem('profile');
                if(profileData){
                  profileData = JSON.parse(profileData);
                  let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
                  setIsPremium(LSisPremium);
                }
                if(data.filedocxs.path !== undefined)
                data.FileDOCXLink = "https://api.my-advo.cat/uploads/" + 
                  data.filedocxs.path +
                  data.filedocxs.name;


                console.log(data);
                getFormVariables(id, data);
                getDocFiles(id, data);
            });
        } catch(e) {
            console.log(e);
        }


        localStorage.removeItem('isMobileApplication');
        document.addEventListener("deviceready", ()=>{
            //setPlatformDevice(window.cordova.platformId);
            console.log("Cordova Loaded");
            console.log(window.cordova.platformId);
            localStorage.setItem('isMobileApplication', window.cordova.platformId);
        }, false);/**/


        let isMobileApplication = localStorage.getItem('isMobileApplication');
        console.log("isMobileApplication");
        console.log(isMobileApplication);
        if(isMobileApplication !== null){
            console.log("isMobileApplication");
            console.log(isMobileApplication);
            document.addEventListener("click", function (e) {

                var elem = e.target.closest('a[download]');
                
                if(elem === null)return;

                if (elem.tagName === "A" && elem.hasAttribute("download")) {
                    e.preventDefault();
        
                    if (elem.getAttribute("href").slice(0, 5) === "data:") {
                      var blob = dataURItoBlob(elem.getAttribute("href"));
                      PdfDownload(elem.getAttribute("download"), blob, blob.type);
                    } else {
                      fetch(elem.getAttribute("href"))
                      .then(function (response) {
                        return response.blob();
                      })
                      .then(function (blob) {
                        return PdfDownload(elem.getAttribute("download"), blob, blob.type);
                      });
                    }
        
                    return;
                }
            });
        }
    }, []);

    

    const getFormVariables = (id) => {
      let self = this;
      let url = APIendpoint+'/doc/document/get-form-variables?id='+id;
      fetch(url)
          .then(res => res.json())
          .then((response)=>{
              if(response.payload){
                setVariables(response.payload.variables);
              }
          });
  }

  const getDocFiles = (id, data) => {
    let self = this;
    let url = APIendpoint+'/doc/document/get-doc-files?id='+id;
    fetch(url)
        .then(res => res.json())
        .then((response)=>{
            if(response.payload){
              let devServerUrl = APIendpoint+'/doc/document/convert?file-id='+response.payload.files.id+'#toolbar=0&navpanes=0';
              if(response.payload.filepdfs)devServerUrl = APIendpoint+"uploads/"+response.payload.filepdfs.path+response.payload.filepdfs.name+"#toolbar=0&navpanes=0";
              
              data.file = response.payload.files;
              data.filedocx = response.payload.filedocxs;
              data.filepdf = response.payload.filepdfs;

              data.FilePDFLink = "https://api.my-advo.cat/uploads/" + 
                response.payload.filepdfs.path +
                response.payload.filepdfs.name;

              console.log(data);
              setDocData(data);
              setDevServerUrl(devServerUrl);
            }
        });
}

    const handleSubmitTemplate = async () => {

      const url = APIendpoint+'/doc/document/generate';
      let isMobileApplication = localStorage.getItem('isMobileApplication');
      try {
        if(window.cordova !== undefined){
            //const headers = new Headers();
            //headers.append('Authorization', 'Bearer ' + user?.token);
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', 'Bearer ' + user?.token);
            xhr.onload = function(e) {
              if (this.status === 200) {
                // myBlob is now the blob that the object URL pointed to.
                var myBlob = this.response;
                PdfDownload(doc.files.name, myBlob, myBlob.type);
              }
              console.log(this.status);
            };
            xhr.onerror = function(e) {
              console.log(e);
            }
            xhr.send(formData);
            /*await fetch(url, {
                method: 'POST',
                body: formData,
                headers
            }).then(r => r.blob())
            .then((blob)=>{
              PdfDownload(doc.files.name, blob, blob.type);
            });/**/
      } else {
        /*await fetch(url).then(r=>r).then(r => r.blob())
        .then(function (blob) {
          return PdfDownload(doc.files.name, blob, blob.type);
        });/**/
        const blob = await femida.generateDoc({
          token: user?.token,
          data: formData
        });
        PdfDownload(doc.files.name, blob, blob.type);
        /*var csvURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', doc.files.name);
        tempLink.click();/**/
      }






/*
            const blob = await femida.generateDoc({
                token: user?.token,
                data: formData
            });
            var csvURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', doc.files.name);
            tempLink.click();/**/
    
            notify();
        } catch(e) {
            console.log(e);
        }
    }

    const PdfDownload = (filename, data, mimeType) => {
        console.log("data");
        console.log(data);
        var blob = new Blob([data], {
            type: mimeType
          });
          //let isMobileApplication = localStorage.getItem('isMobileApplication');
          //if (isMobileApplication !== null) {
        console.log("window.cordova");
        console.log(window.cordova);
          if (window.cordova && window.cordova.platformId !== "browser") {
            document.addEventListener("deviceready", function() {
              var storageLocation = "";
              //switch (isMobileApplication) {
              switch (window.cordova.platformId) {
                case "ios":
                  storageLocation = window.cordova.file.documentsDirectory;
                  break;

                case "android":
                default:
                  storageLocation = window.cordova.file.externalDataDirectory;
                  break;
              }
              var folderPath = storageLocation;
              window.resolveLocalFileSystemURL(
                folderPath,
                function(dir) {
              console.log("dir");
              console.log(dir);
                  dir.getFile(
                    filename,
                    {
                      create: true
                    },
                    function(file) {
                        console.log("file");
                        console.log(file);
                      file.createWriter(
                        function(fileWriter) {
                          fileWriter.write(blob);
        
                          fileWriter.onwriteend = function() {
                            var url = file.toURL();
                            console.log("url");
                            console.log(url);
                            let pathToFile = file.nativeURL;
                            console.log("pathToFile");
                            console.log(pathToFile);
                            window.cordova.plugins.fileOpener2.open(pathToFile, mimeType, {
                              error: function error(err) {
                                console.error(err);
                                alert("Unable to download");
                              },
                              success: function success() {
                                console.log("success with opening the file");
                              }
                            });
                          };
        
                          fileWriter.onerror = function(err) {
                            alert("Unable to download");
                            console.error(err);
                          };
                        },
                        function(err) {
                          // failed
                          alert("Unable to download");
                          console.error(err);
                        }
                      );
                    },
                    function(err) {
                      alert("Unable to download");
                      console.error(err);
                    }
                  );
                },
                function(err) {
                  alert("Unable to download");
                  console.error(err);
                }
              );
            });
          } else {
            saveAs(blob, filename);
          }

    }
    
    const dataURItoBlob = (dataURI) => {
        var isBase64 = dataURI.split(",")[0].split(";")[1] === "base64";
        var byteString;
      
        if (isBase64) {
          // convert base64 to raw binary data held in a string
          // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
          byteString = atob(dataURI.split(",")[1]);
        } else {
          byteString = dataURI.split(",")[1];
        } // separate out the mime component
      
        var mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0]; // write the bytes of the string to an ArrayBuffer
      
        var ab = new ArrayBuffer(byteString.length); // create a view into the buffer
      
        var ia = new Uint8Array(ab); // set the bytes of the buffer to the correct values
      
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        } // write the ArrayBuffer to a blob, and you're done
      
        var blob = new Blob([ab], {
          type: mimeString
        });
        return blob;
      };


    const handleDocLoad = ({ numPages }) => {
        setNumPages(numPages);
    }

    const notify = () => {
        showNotification(true);
        setTimeout(() => showNotification(false), 2000);
    }

    const handleArrowClick = (direction) => {
        if (direction === 'prev') {
            if (activePage === 1) {
                setActivePage(numPages);
            } else {
                setActivePage(activePage - 1);
            }
        }
        if (direction === 'next') {
            if (activePage === numPages) {
                setActivePage(1);
            } else {
                setActivePage(activePage + 1);
            }
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(!isPremium)return false
        setTemplateFilled(true);
        showTemplateForm(false);
        const formData = new FormData(e.target);
        setFormData(formData);
    }

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    }

    return (
        !doc ?
        <LoadingScreen /> :
        <Page>
            <PageHeader>{doc.name}</PageHeader>
            {/*<h5 className="category-title mb-2"></h5>*/}
            <p className="text-secondary mb-4">{doc.description}</p>
            {(doc.id_file !== null) &&
                <>{
                    templateFilled ?
                    <div className="d-flex gap-2">
                        <Button
                        className="flex-grow-1" 
                        onClick={() => showTemplateForm(true)}>Изменить данные</Button>
                        <Button 
                        className="flex-grow-1"
                        onClick={handleSubmitTemplate}>Сгенерировать</Button>
                    </div>
                    :
                    <Button 
                    onClick={() => showTemplateForm(true)}
                    className="w-100">Заполнить шаблон</Button>
                }</>
            }
            <div className="d-flex justify-content-between my-4">
                <a 
                  download={isPremium ? doc.filepdfs.name : false} 
                  target="_blank" 
                  rel="noreferrer" 
                  href={isPremium ? doc.FilePDFLink : "#"} 
                  className={"femida-link "+(isPremium ? "" : "")}
                  style={{ pointerEvents: (isPremium ? "all" : "none") }}
                  disabled={!isPremium} 
                >Скачать шаблон .pdf</a>
                <a 
                  download={isPremium ? doc.filedocxs.name : false}
                  target="_blank"
                  rel="noreferrer"
                  href={isPremium ? doc.FileDOCXLink : "#"}
                  className={"femida-link "+(isPremium ? "" : "")}
                  style={{ pointerEvents: (isPremium ? "all" : "none") }}
                  disabled={!isPremium} 
                >Скачать шаблон .docx</a>
            </div>
            <div className="d-flex justify-content-between align-items-stretch mb-4">
                {(numPages > 1) ? <div
                role="button" 
                onClick={() => handleArrowClick('prev')}
                className="d-flex align-items-center flex-grow-1">
                    <ArrowPrevIcon />
                </div> : <>
                    <div role="button" className="d-flex align-items-center flex-grow-1"></div>
                </>}
                <Document 
                    file={doc.FilePDFLink}
                    className="pdf-wrap"
                    loading={<div className="loading-screen"><FemidaLoader /></div>}
                    error={<div className="d-flex align-items-center justify-content-center">Ошибка загрузки pdf</div>}
                    onLoadSuccess={handleDocLoad} 
                    onLoadError={(e) => console.log(e)}>
                    <PdfPage pageNumber={activePage} />
                    { (numPages > 1) && <div className="pdf-page-view">
                        <div className="pdf-page-view__dots-list">
                            { Array(numPages).fill(0).map((q, i) =>
                                <div
                                key={i} 
                                className={`pdf-page-view__dot ${(i + 1) === activePage}`}></div>) }
                        </div>
                    </div> }
                </Document>
                {(numPages > 1) ? <div
                    role="button"
                    onClick={() => handleArrowClick('next')} 
                    className="d-flex align-items-center flex-grow-1 justify-content-end">
                        <ArrowNextIcon />
                </div> : <><div role="button"
                className="d-flex align-items-center flex-grow-1 justify-content-end">
                </div></>}
            </div>
            
            <AnimatePresence>
            {
                templateForm &&
                <SlideUp
                title="Генерация шаблона" 
                description= {(isPremium ? "" : <><div>Только для Премиум пользователей.</div> <a href="/tarifs/" style={{
                  display: "block",
                  maxWidth: "max-content"}}
                  className="femida-btn text-white mt-2">Оплатить</a></>)}
                close={() => showTemplateForm(false)}>
                    <form onSubmit={handleFormSubmit}>
                        <input 
                        type="hidden" 
                        name="file-id" 
                        value={doc.files.id}
                        disabled={!isPremium} />
                        {
                            doc.variables.map((v, i) => 
                            <fieldset key={i}
                            disabled={!isPremium} >
                                <input 
                                  className={(isPremium ? "" : "disabled")}
                                  name={`varval[${i}][name]`}
                                  value={v.name_variable}
                                  disabled={!isPremium} 
                                  type="hidden" />
                                <TextInputGroup
                                  value={formState[`varval[${i}][value]`] || ''}
                                  onChange={handleChange}
                                  name={`varval[${i}][value]`}
                                  placeholder={v.title}
                                  disabled={!isPremium} 
                                  label={v.title} />
                            </fieldset>)
                        }
                        <Button
                          type="submit"
                          // onClick={() => submit(formState)} 
                          disabled={isPremium?false:"disabled"}
                          className={"w-100 "+(isPremium?"":"inactive")}
                        >Сохранить</Button>
                    </form>
                </SlideUp>
            }
            {
                notification &&
                <Notification 
                onClick={() => showNotification(false)}>Документ успешно скачан</Notification>
            }
            </AnimatePresence>
        </Page>
    );
}

export default TemplatePage;