import { getMonthString } from "../../api/utils";

export const getImageUrl = (file) => {
    let out = null;
    if (file) {
        // eslint-disable-next-line
        out = process.env.REACT_APP_IMG_SERVER + `${file?.path}` + `/${file?.name}`;
    }
    return out;
}

export const formatDate = (created_at) => {
    const date = new Date(created_at);
    const day = date.getDate();
    const month = getMonthString(date.getMonth());
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}