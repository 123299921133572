import React from "react";
import { Spinner } from "react-bootstrap";
import { motion } from 'framer-motion';

const Button = (props) => {
    const { children, loading, className, disabled } = props;
    let btnLoading = false;
    if (loading !== undefined) btnLoading = loading;
    if (disabled !== undefined) btnLoading = disabled;
    
    return (
        <motion.button
            {...props}
            loading={btnLoading.toString()}
            disabled={btnLoading}
            className={`femida-btn ${className}`}>
            {
                loading ?
                <Spinner animation="border" size="sm" />
                : children
            }
        </motion.button>
    );
}

export default Button;