import React from "react"
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";
import * as utils from "../../api/utils";

const CategoryItem = (props) => {

    const { id, title, description, codex_id, parent_url } = props;
    let url_tmp = codex_id+"";
	let fDescription = description && utils.simpleClean(description);
	let fTitle = utils.simpleClean(title);
    return (
        <Link to={ ("./"+url_tmp)+"/" || "/" } data-id={id} data-codex_id={codex_id} data-parent_url={parent_url}>
            <motion.div {...fadeAnimation}
            className="d-flex bg-white rounded-3 py-3 px-3"> 
                <div className="flex-grow-1 d-flex flex-column gap-1">
                    <b dangerouslySetInnerHTML={{__html: fTitle}}></b>
                    { 
                        fDescription && 
                        <small className="text-secondary" dangerouslySetInnerHTML={{__html: fDescription}}></small> 
                    }
                </div>
                <div>
                    <ArrowIcon />
                </div>
            </motion.div>
        </Link>
    );
}

const propsAreEqual = (prev, next) => {
    return prev.title === next.title;
}

export default React.memo(CategoryItem );




export const CategoryItem2 = (props) => {

    const { id, title, description, codex_id, parent_url, node } = props;
    let url_tmp = codex_id;//new String(codex_id);
	let fDescription = description && utils.simpleClean(description);
	let fTitle = utils.simpleClean(title);
    console.log(node);
    if(node.type_node === "section")return;
    return (
        <Link to={ url_tmp || "/" } className="d-block mb-2" data-id={id} data-codex_id={codex_id} data-parent_url={parent_url}>
            <motion.div {...fadeAnimation}
            className="d-flex bg-white rounded-3 py-3 px-3"> 
                <div className="flex-grow-1 d-flex flex-column gap-1">
                    <b dangerouslySetInnerHTML={{__html: fTitle}}></b>
                    { 
                        fDescription && 
                        <small className="text-secondary" dangerouslySetInnerHTML={{__html: fDescription}}></small> 
                    }
                </div>
                <div>
                    <ArrowIcon />
                </div>
            </motion.div>
        </Link>
    );
}