
const formatMonth = (month) => {
    switch (month) {
        case 0:
            return 'янв';
        case 1:
            return 'фев';
        case 2:
            return 'мар';
        case 3:
            return 'апр';
        case 4:
            return 'мая';
        case 5:
            return 'июн';
        case 6:
            return 'июл';
        case 7:
            return 'авг';
        case 8:
            return 'сен';
        case 9:
            return 'окт';
        case 10:
            return 'ноя';
        case 11:
            return 'дек';
        default:
            return 'янв';
    }
}

export const formatDate = (timestamp) => {
    const date = new Date(timestamp  * 1000);
    const today = new Date(Date.now());

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    const day = date.getDate();
    const month = formatMonth(date.getMonth());

    if (today.getDate() === day &&
        today.getMonth() === month &&
        today.getFullYear() === date.getFullYear())
    {
        return `${hours}:${minutes}`;
    }
    else if (today.getFullYear() === date.getFullYear())
    {
        return `${day} ${month}`;
    } else {
        return `${day} ${month}, ${date.getFullYear()}`;
    }
}

export const formatTime = (timestamp) => {
    const date = new Date(Number(timestamp)  * 1000);
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    return `${hours}:${minutes}`;
}

// •
export const formatMessagePreview = (args) => {
    let type = args.type;
    let content = args.content;
    if (!content) content = args;

    let out = '';
    switch(type) {
        case 'text':
            if (content?.length > 85) {
                out = content.slice(0, 82).trim() + '...';
                return out;
            } else {
                return content;
            }
        case 'image':
            return '[Изображение]';
        case 'video':
            return '[Видео]';
        case 'audio':
            return '[Аудио]';
        case 'call':
            return '[Звонок]';
        case 'file':
            return '[Документ]';
        default:
            if (content?.length > 85) {
                out = content.slice(0, 82).trim() + '...';
                return out;
            } else {
                return content;
            }
    }
}