import React from "react"
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import {CategoryItem2} from "../../../../CategoryItem";
import FemidaLoader from "../../../../FemidaLoader";
import FemidaCollapse from "../../../../FemidaCollapse";

const RecursiveCodexCard = (props) => {

    const {
        loading,
        node,
        firstChild
    } = props;

    const hasChildren = node?.children && node.children.length > 0 ? true : false;

    return (
        loading ?
            <div className="py-4 text-center">
                {/*}<FemidaLoader />{/**/}&nbsp;
            </div>
        : hasChildren || (firstChild && hasChildren) ?
                <>
                    {
                        !hasChildren ? 
                            <></> 
                        :<>
                            {node.children?.map((child, i) => 
                                <RecursiveCodexCard key={i} node={child} />)}
                        </> 
                    }
                </>
            :
                <>
                    {/*}<Link to={'/codex/'+node.base64_uid || '/codex/'} className="codex-article-link d-block">
                        {parse(node.title)}
                    </Link>{/**/}
                    <CategoryItem2 
                        key={node.base64_uid}
						id={node.base64_uid}
                        node={node} 
                        codex_id={'/codex/'+node.base64_uid} 
                        title={(node.title)} />	
                </>
    );


    /*
    
    return (
        loading ?
            <FemidaCollapse name={parse(node.title)}>
                <div className="py-4 text-center">
                    <FemidaLoader />
                </div>
            </FemidaCollapse>
        : hasChildren || (firstChild && hasChildren) ?
                <>
                    <li>
                        <span>{parse(node.title)}</span>
                        
                            {
                                !hasChildren ? 
                                    <></> 
                                :<ul>
                                    {node.children?.map((child, i) => <RecursiveCodexCard key={i} node={child} />)}
                                </ul>
                                    
                            }
                    </li>
                </>
            :
                <>
                    <Link to={'/codex/'+node.base64_uid || '/codex/'} className="codex-article-link d-block">
                        {parse(node.title)}
                    </Link>
                </>
    );

    /**/


   /* return (
        loading ?
            <FemidaCollapse name={parse(node.title)}>
                <div className="py-4 text-center">
                    <FemidaLoader />
                </div>
            </FemidaCollapse>
        : hasChildren || (firstChild && hasChildren) ?
                <>
                    <FemidaCollapse name={parse(node.title)}>
                        {
                            !hasChildren ? 
                                <></> 
                            :
                                node.children?.map((child, i) => <RecursiveCodexCard key={i} node={child} />)
                        }
                    </FemidaCollapse>
                </>
            :
                <>
                    <Link to={'/codex/'+node.base64_uid || '/codex/'} className="codex-article-link d-block">
                        {parse(node.title)}
                    </Link>
                </>
    );/*/
}

export default RecursiveCodexCard;