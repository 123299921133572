import React from "react";
import { Link } from "react-router-dom";
//import CallCard from "./CallCard";
import { CallCard2 } from "../../CallCard";
import { Header2 } from "./Header";
//import Cases from "./Cases";
//import Services from "./Services";
//import ServicesItems from "./ServicesItems";
//import News from "./News";
import { motion } from 'framer-motion';
import { fadeAnimation } from "../../../utils/animationSetting";
import Offer from "./Offer";
//import Navigation from "../../Navigation";
import iconCodex from './Services/icons/codex.svg';
//import iconQuestion from './Services/icons/question.svg';
import iconCase from './Services/icons/case.svg';
import iconDocs from './Services/icons/docs.svg';
import iconLawyer from './Services/icons/lawyer.svg';
import iconPerson from './Services/icons/person.svg';
import iconPromoLogo from './Offer/promo-logo.svg';

//import icons from './Services/icons/icons';
import './ServicesItems/styles.css';

const MainPage2 = () => {
	let style={
		arrowHover: {
			//border: "2px solid #04b6f529",
			borderRadius: "1rem",
			padding: "12px 15px",
			background: "rgba(4, 182, 245, 0.16)"
		},
		serviceImageWrapper: {
			alignSelf: "center",
			//border: "solid 1px #eee",
			//padding: "7px",
			//borderRadius: "50%",
			//overflow: "hidden",
		},
		serviceImage: {
			height: "100px",
			minWidth: "100px"
			//border: "solid 1px #eee",
			//padding: "7px",
			//borderRadius: "50%",
			//overflow: "hidden",
		},

	};
	let services = [
		{
			title:"Позвонить адвокату!",
			description:"Круглосуточная помощь от опытных специалистов",
			link:"/call/",
			icon: iconPerson
		},
		{
			title:"Нужна консультация?",
			description:"Напишите нам, и мы ответим на Ваши вопросы",
			link:"/chat/",
			icon: iconCodex
		},
		{
			title:"Не знаете, как поступить?",
			description:"Мы собрали для вас рекомендации на все случаи жизни",
			link:"/situation/",
			icon: iconCase
		},
		{
			title:"Где взять документ?",
			description:"Десятки шаблонов правовых документов с возможностью самостоятельного заполнения",
			link:"/doc/",
			icon: iconDocs
		}
	];
    return (
        <motion.div {...fadeAnimation} className="flex-column gap-2 mainpage-wrapper">
            
            <Header2 />

			<h2 className="category-title mb-1">Как решить юридическую проблему?</h2>
				
			<div className="row">
				{services.map((item, i)=>
						<>
								<div key={i} className="col-12 my-2">
									<Link to={item.link}>
										<div className="media arrow-hover" style={style.arrowHover}>
											<div className="media-body align-self-center">
												<h6 
													className="mb-1"
													style={{
														fontSize:"1.1rem"
													}}
													>{item.title}</h6>
												<span>{item.description}</span>
											</div>
											<div 
												className="only-icon only-icon-lg text-primary" 
												style={style.serviceImageWrapper}>
												<img src={item.icon} alt=""  
													style={style.serviceImage}/>
											</div>
										</div>
									</Link>
								</div>
						</>
					)
				}

				<div className="col-12 text-center mt-3 mb-3">
					<Link to="/services" className="femida-btn mt-1 mb-1 text-white">
						Еще больше в наших сервисах
					</Link>
				</div>
				<div className="col-12 text-center mt-3 mb-3">
					<Link to="/about" className="femida-btn mt-1 mb-1 text-white">
						О компании
					</Link>
				</div>
				<div className="col-12 text-center mt-3 mb-3">
					<Link to="/requisites" rel="nofollow, noindex" className="femida-btn mt-1 mb-1 text-white">
						Реквизиты
					</Link>
				</div>
				<div className="col-12 text-center mt-3 mb-3">
					<a href="/intarifs" className="femida-btn mt-1 mb-1 text-white">
						Тарифы
					</a>
				</div>
				<div className="col-12 text-center mt-3 mb-3">
					<Link to="/feedback" className="femida-btn mt-1 mb-1 text-white">
						Обратная связь
					</Link>
				</div>

			</div>
{/*
			<CallCard2 />
			
            	<div className="promo-content">
                <a href="/call/" className="promo-trigger">
                    <div className="promo-trigger-img" aria-haspopup="true"><img src={iconPromoLogo} alt="" /></div>
                    <div className="promo-trigger-text" style={{width: "0"}}>Проблемы?<br/>Звоним юристу</div>
				</a>
			</div>	{/**/}		
        </motion.div>
    );
}

export default MainPage2;