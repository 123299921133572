import React from "react";
import './styles.css';
import NotFoundSvg from './not-found.svg'; 

const NotFound = (props) => {
    return (
        <div className="notfound-wrapper">
            <div className="notfound-content" role="status">
			    <img src={NotFoundSvg} alt="Ничего не найдено" />
				<p>Ничего не найдено.</p>
            </div>
        </div>
    );
}

export default NotFound;