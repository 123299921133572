import React from "react";
import './styles.scss';
import CallSvg from './assets/Call.svg';
import MessageSvg from '../../assets/message.svg';
//import LawyerBgSvg from './assets/lawyer-bg.svg';
//import LawyerSvg from './assets/lawyer.svg';
//import LawyerBg2Svg from './assets/img.svg';
import { useNavigate } from "react-router-dom";

const CallCard = (props) => {
    const navigate = useNavigate();
    return (
        <div role="button" className="p-3 call-card" style={{background: "url('/static/media/img.svg') no-repeat rgba(4, 182, 245, 0.08)", border: "2px solid #04b6f529"}}>
            <div className="d-flex gap-2 call-card__block">
                <div>
                    <img src="https://api.my-advo.cat/uploads/bg_btn_male.png" alt="" width="100px" />
                </div>			
                <div>
                    <h5 className="call-card__title">Нужен срочный совет юриста?</h5>
                    <p className="text-secondary call-card__description">Вам помогут в круглосуточной службе поддержки.</p>
                </div>
                <div  className="d-none">
                    <h5 className="call-card__title">Нужна срочная помощь?</h5>
                    <p className="text-secondary call-card__description">Получите консультацию от ведущих адвокатов и юристов России.</p>
                </div>				
            </div>
            <button onClick={() => navigate('/call', {replace: true})} className="d-flex gap-2 w-100 justify-content-center femida-btn">
                <img src={CallSvg} alt="позвонить" />			
                <span>Позвоните нам</span>
            </button>
        </div>
    );
}

export const CallCard2 = (props) => {
    const navigate = useNavigate();
    return (
        <div role="button" className="p-3 call-card mt-3" style={{background: "url('/static/media/img.svg') no-repeat rgba(4, 182, 245, 0.08)"}}>
            <div className="d-flex gap-2 call-card__block">
                <div>
                    <img src="https://api.my-advo.cat/uploads/bg_btn_male.png" alt="" width="80px" />
                </div>			
                <div>
                    <h5 className="call-card__title">Остались вопросы?</h5>
                    <p 
                        className="call-card__description"
                        style={{color:"#000"}}>Получите консультацию прямо сейчас!</p>
                </div>			
            </div>
            <button onClick={() => navigate('/call', {replace: true})} className="d-flex gap-2 w-100 justify-content-center femida-btn">
                <img 
                    src={MessageSvg}
                    alt="Получить консультацию"
                    style={{ 
                        transform: "scaleX(-1)",
                        width:"24px",
                        height:"24px"
                    }}
                />
                <span style={{fontSize: "1.1rem"}}>Получить консультацию</span>
            </button>
        </div>
    );
}


export default CallCard;