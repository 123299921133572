import { AnimatePresence } from "framer-motion";
import React, { useContext, startTransition } from "react"
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { femida } from "../../../../api/app";
import { UserContext } from "../../../../context/UserContext";
import Button from "../../../Button";
import Notification from "../../../Notification";
import Page from "../../../Page";
import PageHeader from "../../../PageHeader";

const CreateRoomPage = (props) => {

    const [room, setRoom] = useState(null);
    const {user} = useContext(UserContext);
    const [socketStatus, setSocketStatus] = useState(null);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        startTransition(async () => {
            const room = femida.chat().room({
                user,
                onClose: () => {
                    setTimeout(() => setSocketStatus('ws: connection closed'), 0);
                    setTimeout(() => {setSocketStatus(null); startTransition();}, 6000);
                },
                onError: () => {
                    setTimeout(() => setSocketStatus('ws: connection error'), 0);
                    setTimeout(() => setSocketStatus(null), 6000);
                },
                onMessage: (e) => {
                    console.log(e);
                    if (e.action === 'refreshRoomList') {
                        setLoading(false);
                        navigate("/chat", {
                            state: { roomCreated: 'dam,n' }
                        });
                    }
                }
            });
            setRoom(room);
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!room) return;

        let TicketTitle = e.target[0].value;
        let TicketMessage = e.target[1].value;

        if(!TicketTitle?.trim().length || !TicketMessage?.trim().length){
            setTimeout(() => setSocketStatus('Укажите тему обращения и Ваш вопрос...'), 0);
            setTimeout(() => setSocketStatus(null), 6000);
            return false;
        }

        try {
            setLoading(true);
            room.createRoom({
                title: TicketTitle,
                message: TicketMessage
            });
        } catch(e) {
            setLoading(false);
            console.log(e);
        }
    }

    return (
        <Page>
            <PageHeader>Новое обращение</PageHeader>
            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <span className="d-block mb-1">Тема обращения</span>
                    <input
                    placeholder="Тема вашего обращения" 
                    type="text"
                    name="title" 
                    className="femida-input" />
                </div>
                <div className="mb-2">
                    <span className="d-block mb-1">Ваш вопрос</span>
                    <textarea
                    name="message"
                    placeholder="Введите вопрос вашего обращения максимально развернуто" 
                    className="femida-input textarea" 
                    rows="5" />
                </div>
                <div>
                    <Button
                    loading={loading}
                    className="w-100">Добавить</Button>
                </div>
            </form>
            <AnimatePresence>
            {
                socketStatus &&
                <Notification>{socketStatus}</Notification>
            }
            </AnimatePresence>
        </Page>
    );
}

export default CreateRoomPage;