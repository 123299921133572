import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as ReadIcon } from './read.svg';
import { ReactComponent as SentIcon } from './sent.svg';
import { ReactComponent as CallIcon } from './call.svg';
import { ReactComponent as VideoIcon } from './video.svg';
import { ReactComponent as FileIcon } from './file.svg';
import { ReactComponent as SendIcon } from './send.svg';
import { ReactComponent as ClipIcon } from './clip.svg';
import { ReactComponent as MicIcon } from './mic.svg';
import { ReactComponent as MicSlashIcon } from './mic-slash.svg';

const icons = {
    AddIcon,
    ReadIcon,
    SentIcon,
    CallIcon,
    VideoIcon,
    FileIcon,
    SendIcon,
    ClipIcon,
    MicIcon,
    MicSlashIcon,
}

export default icons;