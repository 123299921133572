import React, { startTransition, useEffect, useState } from "react"
import BackButton from "../../BackButton";
import MapScreen from "../../MapScreen";
import Page from "../../Page";
import { Collapse } from "react-bootstrap";
import SlideUp from "../../SlideUp";
import I from '../LawyerPage/assets/icons';
import s from './styles.module.scss';
import { AnimatePresence } from "framer-motion";
import { femida } from "../../../api/app";
import LoadingScreen from "../../LoadingScreen";
import FemidaCollapse from "../../FemidaCollapse";
import LawyerCollapse from "../../LawyerCollapse";
import { motion } from "framer-motion";
import { itemAnimation } from "../../../utils/animationSetting";
import { ReactComponent as ClearIcon } from './close.svg';
import { ReactComponent as AwayIcon } from './away.svg';
import { ReactComponent as ArrowIcon } from "../../CategoryItem/arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RadioSelect from "../../RadioSelect";
import PremiumAccess from "../../PremiumAccess";

const InfoPage = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const page = location.pathname.split('/')[1];
    const sortFields = [
        {name: 'Юристы', value: "jurist"}, 
        {name: 'Адвокаты', value: "lawyer"}, 
        {name: 'Нотариусы', value: "notary"}
    ];

    const [activeRegion, setActiveRegion] = useState(null);
    const [regions, setRegions] = useState(null);
    const [subjects, setSubjects] = useState(null);
    
    // UI
    const [selectingRegion, setSelectingRegion] = useState(false);
    const [mapActive, setMapActive] = useState(false);
    const [placeInfo, setPlaceInfo] = useState(null);
    
    const [lawyerType, setLawyerType] = useState('jurist');
    const [regionSearch, setRegionSearch] = useState(''); 
    const [subjectSearch, setSubjectSearch] = useState(''); 

	const [isPremium, setIsPremium] = useState(false);
	const [isAuth, setIsAuth] = useState(false);
	//const [open, setOpen] = useState(false);

    useEffect(() => {
        startTransition(async () => {
            try {
                let profileData = localStorage.getItem('profile');
                if(profileData){
                    profileData = JSON.parse(profileData);
                    let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
                    setIsPremium(LSisPremium);
                }
				
				let LSisAuth = Boolean((localStorage.getItem('tokenAPI'))?true:false);
				setIsAuth(LSisAuth);

                let data = null;
                if (page === 'lawyer') {
                    data = await femida.getLawyerRegions();
                } else {
                    data = await femida.getRegions();
                }
                setRegions(data);
                setActiveRegion(data[0]);

                let subjectsData = null;
                if (page === 'lawyer') {
                    subjectsData = await femida.getLawyersByState({
                        id: data[0].id,
                        type: lawyerType
                    });
                } else {
                    subjectsData = await femida.getRegionSubjects(data[0].id);
                }
                setSubjects(subjectsData);
            }  catch(e) {
                console.log(e);
            }
        });
    }, []);

    const handleSelectRegion = (i) => {
        startTransition(async () => {
            const region = regions.filter(r => r.id === i)[0];
            setActiveRegion(region);
            setSelectingRegion(false);
            
            let subjectsData = null;
            if (page === 'lawyer') {
                subjectsData = await femida.getLawyersByState({
                    id: i, 
                    type: lawyerType
                });
            } else {
                subjectsData = await femida.getRegionSubjects(i);
            }
            setSubjects(subjectsData);
        });
    }

    const handlePlaceClick = (item) => {
        if (page === 'lawyer') {
            navigate(item.juristuser_id);
        } else {
            setMapActive(true);
            startTransition(() => {
                setPlaceInfo(item);
            });
        }
    }

    const handlePlacemarkClick = (e) => {
        const subjectId = e.originalEvent.target.properties._data.id;
        if (subjectId && subjects?.length) {
            const data = subjects.filter(s => s.id === subjectId)[0];
            setPlaceInfo(data);
        }
    }

    const handleLawyerTypeChange = (e) => {
        startTransition(async () => {
            setLawyerType(e.target.value);
            try {
                const data = await femida.getLawyersByState({
                    id: activeRegion.id, 
                    type: e.target.value
                });
                setSubjects(data);
            } catch(e) {
                console.log(e);
            }
        });
    }

    return (
        !regions ?
        <LoadingScreen /> :
        <Page>
	<div className="lawyerspage-wrapper">
            <div className={s.stickyTop}>

                <div className="d-flex align-items-center gap-2 mt-1 mb-3">
                    <BackButton />
                    
                <div
                onClick={() => setSelectingRegion(true)}
                className="d-flex gap-2 align-items-center" 
                role="button">
                    <I.MarkIcon />
                    <span className="category-title fs-6">
                        {activeRegion?.name || activeRegion?.value || ''}
                    </span>
                </div>

                    {page !== 'lawyer' && <I.MapIcon onClick={() => setMapActive(true)} />}
                </div>

                {isPremium ? 
                    <div className={`flex-grow-1 mb-3 ${s.subjectSearch}`}>
                        <input
                        value={subjectSearch}
                        onChange={e => setSubjectSearch(e.target.value)}
                        placeholder="Поиск" 
                        type="text" 
                        className="search-input w-100" />
                        { 
                            subjectSearch?.length ? 
                            <ClearIcon role="button" onClick={() => setSubjectSearch('')} /> 
                            : <></> 
                        }
                    </div>:
                <></>}
                { 
                    page === 'lawyer' && 
                    <RadioSelect
                    value={lawyerType}
                    onChange={handleLawyerTypeChange} 
                    fields={sortFields} /> 
                }
            </div>

            {/* ----SUBJECTS---- */}
            <AnimatePresence>
                {
                    (isAuth) ? (subjects?.length && 
                    subjects
                    .filter(s => {
                        if (page === 'lawyer') {
                            return (s.value.toLowerCase().includes(subjectSearch.toLowerCase()) ||
                            s.name.toLowerCase().includes(subjectSearch.toLowerCase()));
                        } else {
                            return (s.name.toLowerCase().includes(subjectSearch.toLowerCase()) ||
                            s.str_address.toLowerCase().includes(subjectSearch.toLowerCase()));
                        }
                    })
                    .map((item, i) => {
                        return (
						<div key={i}>
						<LawyerCollapse item={item} />
							{ /*
                            <motion.div 
                            {...itemAnimation(i)}
                            key={i}
                            className="femida-collapse d-flex gap-2 py-3 border-bottom">
					
								<div role="button" className="femida-collapse__control" onClick={() => setOpen(!open)}>
									<div className="femida-collapse__name">
										<p>{item.name}</p>
										<div className="d-block mt-1">
											<I.MarkSmallIcon className="me-1" />
											<small>{item.str_address || item.value}</small>
										</div>					
									</div>

									<ArrowIcon className={`femida-collapse__arrow ${open}`} />

								</div>
								<Collapse in={open}>
									<div className="mt-2">
									{item.reg_num &&
									<>
												<small className="text-secondary d-block mt-3 mb-1">Адвокат рег. номер:</small>
												<span>{item.reg_num}</span>
												</>
									}
									{item.addresses &&
									<>
								<small className="text-secondary d-block mt-3 mb-1">Место работы</small>
								{item.addresses.map( (el, ii) => {
											return(
											<>
								<span key={`a${ii}`}>{el.state && el.state}{el.street && ", " + el.street}{el.house && ", " + el.house}{el.office && ", " + el.office}</span>
								</>
								)
								}
								)}
								</>
									}

								{item.phones && 
								<>
								<div className="femida-collapse__phones">
								<small className="text-secondary d-block mt-3 mb-1">Телефоны</small>
								{item.phones.map( (el, ii) => {
											return(
											<>
												<a key={`t${ii}`} href={`tel:${el.number}`}>
													{el.number}
												</a>
												</>
											);
										})}
										</div>
										</>
								}										
								{item.services && 
								<>
								<div className="femida-collapse__services">
								<small className="text-secondary d-block mt-3 mb-1">Специализация</small>
									<div className="mt-0 mb-3">
									
										{item.services && item.services.map( (el, ii) => {
											return(
											<>
												<span key={el.id} dangerouslySetInnerHTML={{__html: el.title}}></span>
											</>
											);
										})}

									</div>
									</div>
									</>
								}
									</div>
								</Collapse>
                            </motion.div>
							*/ }
							{ /*							
                            <motion.div 
                            {...itemAnimation(i)}
                            key={i}
                            role="button"
                            className="d-flex align-items-center gap-2 py-3 border-bottom">
                                {
                                    false && // image
                                    <div className={s.imgWrap}>
                                        <img src="" alt="lawyer" />
                                    </div>
                                }
                                <div onClick={() => handlePlaceClick(item)}>
                                    <b>{item.name}</b>
                                    <div className="d-flex align-items-center mt-1">
                                        <I.MarkSmallIcon className="me-1" />
                                        <small>{item.str_address || item.value}</small>
                                    </div>
                                </div>
                            </motion.div> /**/ }
							</div>
                        );
                    })):
                    <><PremiumAccess CheckForAuth={true}/></>
                }
            </AnimatePresence>

            {/* ----REGION SELECT---- */}
            <AnimatePresence>
            {
                selectingRegion &&
                <SlideUp
                halfScreen darken 
                title="Выбрать область" 
                close={() => setSelectingRegion(false)}>
                    <div className={s.searchSticky}>
                        <input
                        value={regionSearch}
                        onChange={e => setRegionSearch(e.target.value)}
                        placeholder="Поиск по регионам" 
                        type="text" 
                        className={`w-100 ${s.regionSearch}`} />
                        {
                            regionSearch?.length ?
                            <ClearIcon role="button" onClick={() => setRegionSearch('')} />
                            : <></>
                        }
                    </div>
                    <fieldset className="d-flex flex-column">
                        {
                            regions
                            .filter(region => {
                                if (page === 'lawyer') {
                                    return region.value.toLowerCase().includes(regionSearch.toLowerCase());
                                } else {
                                    return region.name.toLowerCase().includes(regionSearch.toLowerCase());
                                }
                            })
                            .map((region, i) => 
                            <label
                            role="button"
                            key={i}
                            onClick={() => handleSelectRegion(region.id)} 
                            className="d-flex align-items-center py-3 justify-content-between border-bottom">
                                <span>{region.name || region.value || ''}</span>
                                <div className={s.radioInputWrap}>
                                    <input
                                    name="region"
                                    type="radio" />
                                    {false && <I.CheckmarkIcon key={i} />}
                                </div>
                            </label>)
                        }
                    </fieldset>
                </SlideUp>
            }

            {/* ----MAP---- */}
            </AnimatePresence>
            { 
                ( /*page !== 'lawyer' &&*/ mapActive) && 
                <MapScreen
                activePlace={placeInfo}
                onPlacemarkClick={handlePlacemarkClick} 
                close={() => setMapActive(false)}
                subjects={subjects}>
                    <AnimatePresence>
                        {
                            placeInfo &&
                            <SlideUp
                            close={() => setPlaceInfo(null)} 
                            title="Организация">
                                <small className="text-secondary d-block mt-3 mb-1">Наименование</small>
                                <span>{placeInfo.name || ''}</span>
                                <small className="text-secondary d-block mt-3 mb-1">Адрес</small>
                                <span>{placeInfo.str_address || ''}</span>
                                <small className="text-secondary d-block mt-3 mb-1">Номер телефона</small>
                                <span>
                                    {
                                        placeInfo.contacts?.map((c, i) =>
                                            <span key={i} className="d-block">{c.data}</span>)
                                    }
                                </span>
                                <small className="text-secondary d-block mt-3 mb-1">Сайт</small>
                                <a 
                                className="femida-link d-flex align-items-center gap-1"
                                target="_blank"
                                rel="noreferrer" 
                                href={placeInfo.site || '/'}>Перейти <AwayIcon /></a>
                                <small className="text-secondary d-block mt-3 mb-1">Время работы</small>
                                <ul>
                                    <li>Пн - 8:00 - 18:00</li>
                                    <li>Вт - 8:00 - 18:00</li>
                                    <li>Ср - 8:00 - 18:00</li>
                                    <li>Чт - 8:00 - 18:00</li>
                                    <li>Пт - 8:00 - 18:00</li>
                                </ul>
                            </SlideUp>
                        }
                    </AnimatePresence>
                </MapScreen> 
            }
			</div>
        </Page>
    );
}

export default InfoPage;