import React from "react"
import './styles.css';

const CategoryList = (props) => {
    const {children} = props;
    return (
        <div className="category-list">
            {children}
        </div>
    );
}

export default CategoryList;