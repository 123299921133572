import React, { startTransition, useEffect, useState, useContext } from "react";
import { fadeAnimation } from "../../../utils/animationSetting";
import Page from '../../Page';
import { PageHeader2 } from '../../PageHeader';
import { PayInfoAccordion } from '../../InfoAccordion';
import LoadingScreen from "../../LoadingScreen";
import { UserContext } from "../../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { normalizeCountForm } from "../../../api/utils";

let token = localStorage.getItem('tokenAPI');
let headers = new Headers();
headers.append('Authorization', 'Bearer ' + token);
let apiLink = "https://api.my-advo.cat";

const RequisitesPage = (props) => {
    let self = this;

    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [TarifList, setTarifList] = useState(null);
    const [LoggedIn, setLoggedIn] = useState(false);
    const [IdLastPaydTariff, setIdLastPaydTariff] = useState(null);
    const [IsPremium, setIsPremium] = useState(false);



    useEffect(() => {
        //if (!user) {tarifs_action();}
        try {
            startTransition(() => {
                setLoading(true);
                let url = apiLink+"/api/prices/list";
                fetch(url, { 
                    method:'GET',
                    headers:headers,
                })
                .then(response=>response.json())
                .then((json)=>{
                    if(json.payload){		
                        setTimeout(() => {
                            setTarifList(json.payload.list); 
                            tarifs_action();
                            setLoading(false);
                        }, 300);	
                    }else{
                        console.warn(json);
                        setLoading(false);
                    }
                });
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }, [user]);

    

    const tarifs_action = () => {
        let url  = apiLink + '/api/token/check-token';
        fetch(url, { headers: headers })
            .then( response =>response.json() )
            .then((response)=>{
                let status = parseInt(response.status);
                switch(status){
                    case 200 : {
                        setLoggedIn(true);
                        url  = apiLink + '/api/pay/yookassa-pay/get-id-last-payd-tariff';
                        fetch( url, { method:'GET', headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status === 200)
                                if(response.payload !== null)
                                    if(response.payload.price_id !== undefined)
                                        setIdLastPaydTariff(response.payload.price_id);
                            } );
                        url  = apiLink + '/api/user/get-profile';
                        fetch( url, { method:'GET', headers: headers } )
                            .then( response =>response.json() )
                            .then( (response)=>{
                                if(response.status === 200)
                                    if(response.payload.premium !== undefined)
                                        setIsPremium(Boolean(response.payload.premium));
                            } );
                        break;
                    }
                    case 403 : {
                        setLoggedIn(false);
                        break;
                    }
                    default : {
                        setLoggedIn(false);
                        break;
                    }
                }
            })
    }/**/


    let style = {
        label: {
            position: "relative",
            display: "block",
            background: "#2B3A4C",
            //boxShadow: "rgba(0, 0, 0, 0.27) 0px 0px 11px 0px",
            marginBottom: "15px",
            height: "100%",
            borderRadius: "5px"
        },
        situationTitle: {
            color: "rgb(255, 255, 255)",
            fontSize: "18px",
            lineHeight: "21px",
            textAlign: "right",
            padding: "8px 5px",
            background: "rgb(0, 186, 255)",
            position: "absolute",
            bottom: "50px",
            right: "0px"
        },
        card: {height: "100%"}
    };
    let Style_wrapper = {
        minHeight: "100vh",
        backgroundColor: "#2B3A4C",
        margin:"0 -1rem"
    };

    return (
        <div style={Style_wrapper}>
            {loading ?
                <LoadingScreen />
                :
                <Page {...fadeAnimation}>
                    <PageHeader2>Тарифы</PageHeader2>

                        <div className="row">
                            <div className="col-12">
                                <div className={"d-flex justify-content-between align-items-center mb-4 mt-4 "}>
                                    {TarifList ? 
                                        <div key={"containerTarifList"} className="container">
                                            <div className="row">
                                                {TarifList.map((props, i) => {
                                                let repeatPay = (IdLastPaydTariff === props.id && IsPremium === true);
                                                let CountOfDay = Math.ceil(props.sand_time / (60*60*24));
                                                let CountOfDayTitle = normalizeCountForm(CountOfDay, ["день","дня","дней"]);
                                                return(
                                                    <div 
                                                        key={props.id} 
                                                        className={"col-12 col-sm-12 mb-2"+(repeatPay===true?" price_sale_repeatpay":"")}
                                                        style={{display:(props.active == 2?"none":"")}}
                                                    >
                                                        <label htmlFor={"price_sale_" + props.id} className="situation_money grow" style={style.label}>
                                                            <input 
                                                                id={"price_sale_" + props.id}
                                                                className="form-check-input d-none mt-0"
                                                                type="radio"
                                                                onChange={()=>{ console.log("asd"); }}
                                                                name="price_sale[]"
                                                                value={props.id}
                                                                aria-label={props.name} 
                                                                />
                                                            <div className="situation_title d-none" style={style.situationTitle}>{props.name}</div>
                                                            <div className="card" style={{
                                                                height: "100%",
                                                                background: (repeatPay===true ? "rgba(255,255,255,.2)" : "rgba(255,255,255,.1)"),
                                                                color: (repeatPay===true ? "#fff" : "#fff"),
                                                            }}>
                                                                <div className="card-body pb-0 text-center">
                                                                        <div className="price_sale_icon"></div>

                                                                        <div className="price_sale_name" style={{
                                                                            color: (repeatPay===true ? "#fff" : "#fff"),
                                                                        }}>
                                                                            {props.name} ({CountOfDay+" "+CountOfDayTitle})
                                                                        </div>

                                                                        <div className="price_sale_price" style={{
                                                                            color: (repeatPay===true ? "#fff" : "#fff"),
                                                                            fontSize:"1.3rem"
                                                                        }}>
                                                                            <strong>{props.price}&nbsp;руб</strong>
                                                                        </div>
                                                                        <div className="col-12" dangerouslySetInnerHTML={{__html: props.description}} />
                                                                </div>
                                                                <div className="card-footer" style={{borderTop:0,background:"transparent"}}>

                                                                    {LoggedIn === false ? 
                                                                        <div className="price_sale_btn_wrapper price_sale_registration pb-2 text-center">
                                                                            <a 
                                                                                href="/registration/"
                                                                                className="btn femida-btn text-white"
                                                                            >
                                                                                <div className="text-center">Зарегистрироваться</div>
                                                                            </a>
                                                                        </div> : <></>}
                                                                        <div className="price_sale_btn_wrapper price_sale_pay pb-2 text-center">
                                                                        {props.active == 2 ? <> <a 
                                                                                href={LoggedIn === false ? "/login/":("/tarifs/?price_id="+props.id)}
                                                                                className="btn femida-btn text-white"
                                                                            >
                                                                                
                                                                                    <div className="text-center">
                                                                                        {repeatPay?"Продлить":"Подписаться"}
                                                                                    </div>
                                                                                
                                                                            </a>
                                                                                </> : <></>}
                                                                        </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>)})}
                                            </div>
                                        </div>
                                        : <>
                                        <h3>Нет доступных тарифов</h3>
                                    </>}
                                </div>
                                <div className="col-md-12 pb-3 tarif_page_accordion">
                                    <PayInfoAccordion />
                                </div>
                            </div>
                        </div>
                </Page>
            }
        </div>
    );
}

export default RequisitesPage;