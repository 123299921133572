import React, { startTransition, useState } from "react"
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { femida } from "../../../../api/app";
import LoadingScreen from "../../../LoadingScreen";
import Page from "../../../Page";
import PageHeader from "../../../PageHeader";
import './styles.css';
import { getNodeTree } from "./utils";
import RecursiveCodexCard from "./RecursiveCodexCard";
import CategoryContent from "../../../CategoryContent";
import * as utils from "../../../../api/utils";
import { CallCard2 } from "../../../CallCard";
import PremiumAccess from "../../../PremiumAccess";

const SingleCodexPage = (props) => {

    const location = useLocation();
    const query = location.pathname;
    const codexId = query.slice(1, query.length).split('/')[1];
    const codexIdStr = codexId?(Buffer(codexId, 'base64').toString("ascii")):null;

    const [codex, setCodex] = useState(null);
    const [nodes, setNodes] = useState(null);
	const [branches, setBranches] = useState(null);
	const [crumbs, setCrumbs] = useState(null);
    const [loading, setLoading] = useState(false);
	const [isPremium, setIsPremium] = useState(false);

    const fetchCodex = async () => {
        try {
			let codex_id = codexIdStr;
            const data = await femida.getSingleCodex(codex_id);
            setCodex(data);
            console.log(codex_id);
            if(codex_id !== 44704){
                const dataItems = await femida.getSingleCodexContent(codex_id);
                setBranches(dataItems);
                const dataCrumbs = await femida.getBreadcrumbs(codex_id);
                setCrumbs(dataCrumbs);
            }


        } catch(e) {
            console.log(e);
        }
    }

    const fetchStartingNodes = async () => {
        try {
			let codex_id = codexIdStr;
            console.log("fetchStartingNodes");
            console.log(codex_id);
            const sectionsData = await femida.getCodexNodeNoContent(codex_id);
            startTransition(() => {
                setNodes(sectionsData);
                setLoading(true);
            });
            
            const allNodes = [...sectionsData];
            await fetchNodes(allNodes, sectionsData);
            const tree = getNodeTree(allNodes);
            startTransition(() => {
                setNodes(tree);
                setLoading(false);
            });
        } catch(e) {
            setLoading(false);
        }
    }

    const fetchNodes = async (allNodes, arr) => {
        console.log("fetchNodes");
        if (arr[0]?.type_node === 'article') return allNodes;
        const data = await Promise.all(arr.map(e => 
            femida.getCodexNodeContent(e.id)));
        await Promise.all(data.map(e => {
            allNodes.push(...e);
            return fetchNodes(allNodes, e);
        }));
    }
    
    useEffect(() => {
        try {
            let profileData = localStorage.getItem('profile');
            if(profileData){
                profileData = JSON.parse(profileData);
                let LSisPremium = Boolean(profileData.premium?(JSON.parse(profileData.premium)):false);
                setIsPremium(LSisPremium);
            }
            fetchCodex();
            fetchStartingNodes();
        } catch(e) {
            console.log(e);
        }
    }, []);

    return (
        <Page>
            <PageHeader>Кодексы, законы, судебная практика</PageHeader>
            {
                !codex ?
                <LoadingScreen /> :
                <>
                    {
                        crumbs?.length > 1 &&
                        <>	
                            <div className="codex-crumbs mb-3">
                                <small>
                                    <a href="/codex/" title="">Кодексы</a>
                                </small>
                                {
                                    crumbs
                                    .map((crumb, i) => 
                                        <>
                                        <small key={i}>
                                            <a href={'/codex/'+ crumb.base64_uid} dangerouslySetInnerHTML={{__html: utils.simpleClean(crumb.title)}}></a>
                                        </small>
                                        </>							
                                    )
                                }			
                            </div>
                        </>
                    }
                    {crumbs?.length > 2 && codex?.title && codex?.title.length <3 ?
                        <>
                            <h1 className="category-title mb-3" dangerouslySetInnerHTML={{__html: utils.simpleClean(crumbs.at(crumbs?.length-2).title+' п.'+crumbs.at(crumbs?.length-1).title)}}></h1>
                        </>
                    :
                        <>
                            <h1 className="category-title mb-3" dangerouslySetInnerHTML={{__html: utils.simpleClean(codex?.title || '')}}></h1>
                        </>
                    }
                
                    {codex?.content && 
                        <>
                            <div 
                                className={"d-flex flex-column gap-2 "+((isPremium) ? "" : ((codex.type_node == "plenum" && codex.parent_id !== 0) ? 'text-restricted':""))} 
                                dangerouslySetInnerHTML={{__html: utils.simpleClean(codex.content)}}
                            ></div>
                        </>
                    }
                    <div className="d-flex flex-column gap-2">
                    {
                            !nodes?.length ? <></> :
                            <ul className="RecursiveCodexCard">
                                {nodes.map((node, i) => 
                                <RecursiveCodexCard
                                firstChild 
                                key={i} 
                                loading={loading} 
                                node={node} />)}
                            </ul>
                        }
                    </div>


                    <div className="branches_list_wrapper">{
                        ((codexIdStr && codexIdStr !== "44704"))
                        ?
                        ((branches?.length) ?
                            branches
                            .map((branch, i) => 
                                <CategoryContent 
                                    key={i}
                                    num={i}
                                    title={utils.simpleClean(branch.content)}
                                    type_node={branch.type_node}
                                    />						
                                )
                        :
                            <></>
                        ):<></>
                    }</div>



                    
                    {(codex.type_node == "plenum" && codex.parent_id !== 0) ?
                    <><PremiumAccess CheckForPremium={true}/></>
                    :
                    <></>}
                    

                    <CallCard2 />
                </>
            }

        </Page>
    );
}

export default SingleCodexPage;