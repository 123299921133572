import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import Button from "../../../Button";
import Page from "../../../Page";
import { femida } from "../../../../api/app";
import LoadingScreen from '../../../LoadingScreen';
import PageHeader from "../../../PageHeader";
import {FormErrors} from "../EditProfilePage/FormErrors";
import {FormMsg} from "../EditProfilePage/FormMsg";
import EyeSvg from '../../../../assets/eye-svg.svg';

const ChangePassPage = (props) => {

    const navigate = useNavigate();
    const [state, setState] = useState({});
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formResponceErrors, setFormResponceErrors] = useState({error: ''});	
	const [formResponceMsg, setFormResponceMsg] = useState({msg: ''});
    
    const [passwordShown, setPasswordShown] = useState(false);
    const [newpasswordShown, setNewPasswordShown] = useState(false);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setLoading(true);
        if (!localStorage.getItem('tokenAPI')) {
			localStorage.clear();
            navigate('/login');
            return;
        }

            try {


                setLoading(false);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
    }, []);
    
    const saveChanges = async () => {
        
		setFormResponceErrors({
            error: ''
        });
		setFormResponceMsg({
            msg: ''
        });


        if (state.passwordOld === undefined || state.passwordOld.length < 6){
            // eslint-disable-next-line
			let errorStr = "Внимание! Ошибка ввода старого пароля."+"\n";
			setFormResponceErrors({error: errorStr});
            setLoading(false);
            return false;
        }
        if (state.passwordNew === undefined || state.passwordNew.length < 6){
            // eslint-disable-next-line
			let errorStr = "Внимание! Ошибка ввода нового пароля."+"\n";
			setFormResponceErrors({error: errorStr});
            setLoading(false);
            return false;
        }
        if (state.passwordConfirm === undefined || state.passwordConfirm.length < 6){
            // eslint-disable-next-line
			let errorStr = "Внимание! Ошибка ввода подтверждения пароля."+"\n";
			setFormResponceErrors({error: errorStr});
            setLoading(false);
            return false;
        }
        if (state.passwordNew !== state.passwordConfirm){
            // eslint-disable-next-line
			let errorStr = "Внимание! Новый пароль не совпадает с подтверждающим паролем."+"\n";
			setFormResponceErrors({error: errorStr});
            setLoading(false);
            return false;
        }
        
		
        try {
            setLoading(true);
            let res = await femida.setProfilePassword({
                token: localStorage.getItem('tokenAPI'),
                data: state
            });
            setLoading(false);
            console.log(res);
            if(res.status !== 200){	
                let errorStr = "Внимание! При обновлении данных произошла ошибка.";
                if(res.payload.errorMessage !== undefined) errorStr = res.payload.errorMessage;
                if(res.payload[0] !== undefined) errorStr = res.payload[0];
                setFormResponceErrors({error: errorStr});
            }else{
                let successStr = "Профиль успешно обновлен.";	
                setFormResponceMsg({msg: successStr});
                setPasswordChanged(true);
                setTimeout(() => {navigate('/profile');}, 2000);
            }
            return false;

        } catch(e) {
            console.log(e);
            setLoading(false);
			
			let errorStr = "Внимание! При обновлении данных произошла ошибка.";

			setFormResponceErrors({
                error: errorStr
            });
        }
		
    }

    return (
        loading ?
        <LoadingScreen />
        :
        <Page>
            <PageHeader>Сменить пароль</PageHeader>
            <form
                className="d-flex flex-column gap-3 w-100 align-items-stretch"
                action="#"
                onSubmit={(e)=>{e.preventDefault();saveChanges();}}>
                <div className="d-flex flex-column gap-1 mb-3"
                    style={{position: "relative"}}>
                    <small className="text-secondary">Введите старый пароль</small>
                    <input 
                        autocomplete="off"
                        onChange={handleChange}
                        name="passwordOld"
                        value={state.passwordOld === undefined ? '' : state.passwordOld}
                        className="femida-input"
                        placeholder="Старый пароль"
                        disabled={passwordChanged}
                        type={passwordShown ? 'text' : 'password'} />
                    <img 
                        src={EyeSvg} 
                        onClick={() => setPasswordShown(!passwordShown)}
                        className="femida-input__eye" 
                        style={{top: "65%"}}
                        alt="показать/скрыть" />				
                </div>
                <div className="d-flex flex-column gap-1 mb-3"
                    style={{position: "relative"}}>
                    <small className="text-secondary">Введите новый пароль</small>
                    <input 
                        autocomplete="off"
                        onChange={handleChange}
                        name="passwordNew"
                        value={state.passwordNew === undefined ? '' : state.passwordNew}
                        className="femida-input"
                        placeholder="Новый пароль"
                        disabled={passwordChanged}
                        type={newpasswordShown ? 'text' : 'password'} />
                    <img 
                        src={EyeSvg} 
                        onClick={() => setNewPasswordShown(!newpasswordShown)}
                        className="femida-input__eye" 
                        style={{top: "65%"}}
                        alt="показать/скрыть" />				
                </div>
                <div className="d-flex flex-column gap-1 mb-3">
                    <small className="text-secondary">Подтвердите новый пароль</small>
                    <input 
                        autocomplete="off"
                        onChange={handleChange}
                        name="passwordConfirm"
                        value={state.passwordConfirm === undefined ? '' : state.passwordConfirm}
                        className="femida-input"
                        placeholder="Подтвердите новый пароль"
                        disabled={passwordChanged}
                        type="password" />
                </div>
                <FormErrors formErrors={formResponceErrors} />
                <FormMsg formMsg={formResponceMsg} />
                {passwordChanged === false ? 
                    <>
                        <Button type="submit" className="w-100">Сохранить</Button>
                        {/*}<Button className="w-100" onClick={()=>{saveChanges();}}>Сохранить</Button>{/**/}
                    </>
                     : 
                    <></>
                }
                
            </form>
        </Page>
    );
}

export default ChangePassPage;