import React, { useEffect, useState } from "react"
import './styles.scss'
import { ReactComponent as BackIcon } from './assets/back.svg';
import { ReactComponent as ClockIcon } from './assets/clock.svg';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../../../utils/animationSetting";
import { Collapse } from "react-bootstrap";

const PremiumPage = (props) => {

    const navigate = useNavigate();
    const [premiumActive, setPremium] = useState(false);

    const [activeFeature, setActiveFeature] = useState('f1');
    const handleFeatureToggle = (feature) => {
        setActiveFeature(activeFeature === feature ? '' : feature);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <motion.div {...fadeAnimation}
        className="premium-page">
            <div role="button" onClick={() => navigate(-1)}>
                <BackIcon />
            </div>
            <h1 className="premium-title mt-4 mb-3">Премиум аккаунт</h1>
            {
                premiumActive ?
                <div>
                    <span className="femida-link d-block mb-2">Подписка активна</span>
                    <small className="premium-text">Следующее списание 23 мая в 19:00</small>
                </div>
                :
                <div className="">
                    <span className="premium-text">Обращайтесь за помощью бесплатно в любое время</span>
                    <button
                    onClick={() => setPremium(true)} 
                    className="femida-btn w-100 mt-3">Купить за 199 ₽</button>
                </div>
            }
            <div className="features-list my-4">
                <div className="feature-card">
                    <ClockIcon />
                    <span className="text-white">Получайте ответ в течении 15-ти минут</span>
                </div>
                <div className="feature-card">
                    <ClockIcon />
                    <span className="text-white">Выбирайте лучших специалистов из топа</span>
                </div>
                <div className="feature-card">
                    <ClockIcon />
                    <span className="text-white">Экономия на обращениях до 50%</span>
                </div>
                <div className="feature-card">
                    <ClockIcon />
                    <span className="text-white">Экономия на звонках до 30%</span>
                </div>
            </div>

            <div
            role="button"
            className={`feature-collapse-title ${activeFeature === 'f1' ? 'active' : ''}`}
            onClick={() => handleFeatureToggle('f1')}
            aria-controls="f1"
            aria-expanded={activeFeature === 'f1'}>
                <span>Что входит в Премиум?</span>
                <ArrowIcon className={`arrow ${activeFeature === 'f1' ? 'active' : ''}`} />
            </div>
            <Collapse in={activeFeature === 'f1'}>
                <div className="feature-collapse" id="f1">
                    {`Возврат товара — это тот случай, когда даже небольшая деталь имеет 
                    значение. В двух словах на вопрос, как вернуть покупку в магазин, 
                    ответить нельзя — сначала нужно уточнить все подробности.`}
                </div>
            </Collapse>

            <div
            role="button"
            className={`feature-collapse-title ${activeFeature === 'f2' ? 'active' : ''}`}
            onClick={() => handleFeatureToggle('f2')}
            aria-controls="f2"
            aria-expanded={activeFeature === 'f2'}>
                <span>Как отменить?</span>
                <ArrowIcon className={`arrow ${activeFeature === 'f2' ? 'active' : ''}`} />
            </div>
            <Collapse in={activeFeature === 'f2'}>
                <div className="feature-collapse" id="f2">
                    {`Возврат товара — это тот случай, когда даже небольшая деталь имеет 
                    значение. В двух словах на вопрос, как вернуть покупку в магазин, 
                    ответить нельзя — сначала нужно уточнить все подробности.`}
                </div>
            </Collapse>

            <div
            role="button"
            className={`feature-collapse-title ${activeFeature === 'f3' ? 'active' : ''}`}
            onClick={() => handleFeatureToggle('f3')}
            aria-controls="f3"
            aria-expanded={activeFeature === 'f3'}>
                <span>Кому нужен Премиум?</span>
                <ArrowIcon className={`arrow ${activeFeature === 'f3' ? 'active' : ''}`} />
            </div>
            <Collapse in={activeFeature === 'f3'}>
                <div className="feature-collapse" id="f3">
                    {`Возврат товара — это тот случай, когда даже небольшая деталь имеет 
                    значение. В двух словах на вопрос, как вернуть покупку в магазин, 
                    ответить нельзя — сначала нужно уточнить все подробности.`}
                </div>
            </Collapse>

            <div
            role="button"
            className={`feature-collapse-title ${activeFeature === 'f4' ? 'active' : ''}`}
            onClick={() => handleFeatureToggle('f4')}
            aria-controls="f4"
            aria-expanded={activeFeature === 'f4'}>
                <span>Насколько это выгодное предложение?</span>
                <ArrowIcon className={`arrow ${activeFeature === 'f4' ? 'active' : ''}`} />
            </div>
            <Collapse in={activeFeature === 'f4'}>
                <div className="feature-collapse" id="f4">
                    {`Возврат товара — это тот случай, когда даже небольшая деталь имеет 
                    значение. В двух словах на вопрос, как вернуть покупку в магазин, 
                    ответить нельзя — сначала нужно уточнить все подробности.`}
                </div>
            </Collapse>

            
        </motion.div>
    );
}

export default PremiumPage;