import * as utils from "./utils";
import { Buffer } from 'buffer';
import { gapi, loadAuth2 } from 'gapi-script';
import { asyncCatcher, fetchData } from './utils';
import Chat from "./chat";
import Room from "./room";

class FemidaApi {
    #API_URL;
    #WS_URL;
    #VK_ID;
    #config;

    constructor (config) {
        this.#config = config;
        const { 
            API_URL,
            WS_URL,
            VK_ID
        } = config;

        this.#API_URL = API_URL;
        this.#WS_URL = WS_URL;
        this.#VK_ID = VK_ID;
    }

    #sendCryptoKeys(token, pem) {
        try {
            const url = this.#WS_URL + '?token=' + token;
            let client = new WebSocket(url);
            client.onerror = () => {
                console.log('WebSocket Connection Error');
                // TODO: log out user
                alert('Ошибка отправки ключа');
            }
            client.onopen = () => {
                console.log('WebSocket connection opened');
                let pvs_public_key_base64 = Buffer.from(pem).toString('base64');
                const data = {
                    token,
                    action: "takePublic",
                    msg: pvs_public_key_base64
                };
                client.send(JSON.stringify(data));
            }
            client.onclose = () => console.log('WebSocket connection closed');
            
            client.onmessage = (e) => { 
                if (typeof e.data === 'string') {
                    let PayLoad = JSON.parse(e.data);
                    if(PayLoad.payload.action === "takePublic"){
                        // navigate to main (?)
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    authWithCredentials(credentials) {
        return asyncCatcher(async (resolve, reject) => {
            //const formData = utils.formDataFromObject(credentials);
			const formData = new FormData();
			formData.append('login', 'kilyazova@yandex.ru');
			formData.append('password', '1234567');
			
			//formData.append('login', credentials.email);
			//formData.append('password', credentials.password);			
            const url = this.#API_URL + "/api/token";
			
			
            fetch(url, {
            method:'POST',
            body:formData
            })
            .then(res => res.json())
            .then((result)=>{
            if (result.status === 200 && result.payload) {
                localStorage.setItem('tokenAPI',result.payload.token);
                localStorage.setItem('id_user',result.payload.id_user);
                localStorage.setItem('public_key',result.payload.public_key.trim());				
				/*
                const keys = utils.genKeyPars();
                const { token } = result.payload;
                this.#sendCryptoKeys(token, keys.pvs_public_key);
                resolve({
                    ...result.payload,
                    ...keys
                });*/
            } else {
                reject(result);
            }					


            })
            .catch(function (error){
                console.log('authWithCredentials: ' + error);
            });			
			
			/*
            const res = await fetch(url, {
                method: 'POST',
                body: formData
            });
            const data = await res.json();
			alert(data);
            if (data.status === 200 && data.payload) {
                const keys = await utils.genKeyPars();
                const { token } = data.payload;
                this.#sendCryptoKeys(token, keys.pvs_public_key);
                resolve({
                    ...data.payload,
                    ...keys
                });
            } else {
                reject(data);
            }*/
        });
    }

    authWithGoogle() {
        return asyncCatcher(async (resolve, reject) => {
            const auth2 = await loadAuth2(gapi, 
                '704349581358-8klcbvslsidqnpt3415qp1a6vokmagc4.apps.googleusercontent.com');
            const googleUser = await auth2.signIn();
            const id_token = googleUser.getAuthResponse().id_token;
            const url  = this.#API_URL+'/api/token/social?token='+id_token+'&type_network=google';
            const data = await (await fetch(url)).json();
            if (data.status === 200 && data.payload) {
                const keys = await utils.genKeyPars();
                const { token } = data.payload;
                this.#sendCryptoKeys(token, keys.pvs_public_key);
                resolve({
                    ...data.payload,
                    ...keys
                });
            } else {
                reject(data);
            }
        });
    }

    authWithVk() {
        return asyncCatcher(async (resolve, reject) => {
            const VK_get_code_url = 'https://oauth.vk.com/authorize?client_id='
            +this.#VK_ID
            +'&display=popup&redirect_uri=https://api.my-advo.cat/api/token/vkauth/&scope=email&response_type=code&v=5.120';
            const VK_Auth_win = window.open(VK_get_code_url, "ModalWindowVkAuth", 'width=600,height=auto');
            VK_Auth_win.addEventListener('unload', () => {
                reject('error');
            });
            window.addEventListener("message", async (event) => {
                if (event.origin !== "https://api.my-advo.cat") {
                    return;
                };
                const user = event.data;
                VK_Auth_win.close();
                const keys = await utils.genKeyPars();
                const { token } = user;
                this.#sendCryptoKeys(token, keys.pvs_public_key);
                resolve({
                    ...user,
                    ...keys
                });
            }, false);
        });
    }

    signUpWithCredentials(credentials) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + "/api/user/register";
            let formData = utils.formDataFromObject(credentials);
            const res = await fetch(url,{
                method:'POST',
                body:formData
            });
            const data = await res.json();
            if (data.status === 200) {
                resolve(data.payload);
            } else {
                reject(data);
            }
        });
    }

    fetchProfileData(token) {
        return asyncCatcher(async (resolve, reject) => {
            let url = this.#API_URL + '/api/user/get-profile';
            const [data, err] = await fetchData({url, token});
            if (err) reject(err);
            else resolve(data);
        });
    }

    editProfileData({token, data}) {
        return asyncCatcher(async (resolve, reject) => {
            const allowedFields = [
                'login',
                'email',
                'name',
                //'surname',
                //'middle_name',
                'gender',
                'birthday',
                //'description',
                'address',
                'avatar_image',
				'phones',
            ];
            const mapFields = [
                //'phones',
                'links',
            ];
            let url = this.#API_URL + '/api/user/edit-profile';
			
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token);
            
			const filteredObj = utils.filterObject(data, allowedFields, mapFields);
            const formData = utils.formDataFromObject(filteredObj);
            const res = await fetch(url,{
                method: 'POST',
                body: formData,
                headers
            });
            const updatedData = await res.json();
            resolve(updatedData);
        });
    }


    setProfilePassword({token, data}) {
        return asyncCatcher(async (resolve, reject) => {
            let url = this.#API_URL + '/api/user/edit-profile';
            const formDataObject = {
                "old-password": data.passwordOld,
                "password": data.passwordNew,
                "repeat-password": data.passwordConfirm,
            };
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token);
            const formData = utils.formDataFromObject(formDataObject);
            const res = await fetch(url,
            {
                method:'POST',
                body:formData,
                headers:headers
            });
            const updatedData = await res.json();
            resolve(updatedData);
        });
    }

    getCategoryContent({query, token}) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + utils.apiUrlFromQuery(query);
            let data = null;
            if (query.includes('docs')) {
				
				let token = localStorage.getItem('tokenAPI');
				//alert(token);
                let headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);
			/*	
const res = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
	  "Authorization": 'Bearer ' + token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({parent_id: '10'}), // body data type must match "Content-Type" header
  });				
		*/		
				
               /* const res = await fetch(url, { 
				method: "POST",
				body: {parent_id: '10'},
				headers 
				});*/
				const res = await fetch(url,{
  method: "GET",
				headers: headers});
                data = await res.json();
                const list = Object.keys(data.payload).map(key => 
                    data.payload[key]);
                    resolve(list);
					
			} else if (query.includes('qa')) {	
                let url = this.#API_URL + `/api/qa/qa/list?codex_id=-1&getcontent=1`;
                const res = await fetch(url);
                data = await res.json();				
	
            } else {
                const res = await fetch(url);
                data = await res.json();
            }
			
			
            if (data?.status === 200) {
                const list = Object.keys(data.payload.list).map(key => 
                    data.payload.list[key]); 
                list.pop(); // to remove 'nodes' element
                resolve(list);
            } else {
                reject(data);
            }
        });
    }

    getArticleContent({query, token}) {
        return asyncCatcher(async (resolve, reject) => {
            let url = this.#API_URL + utils.apiUrlFromQuery(query);
            const page = query.split('/')[1];
			//alert(page);
            if (page === 'codex2') {
                const article = await this.getSingleCodex(query.split('/')[3]);
                const parent = await this.getSingleCodex(query.split('/')[2]);
                if (article.content) {
                    resolve(article);
                } else {
                    const res = await fetch(url);
                    const data = await res.json();
                    if (data.status === 200) {
                        resolve({
                            ...article,
                            content: data.payload.list,
                            pageTitle: parent?.title
                        });
                    } else {
                        reject(data);
                    }
                }
            } else if (page === 'plenum') {
                const res = await fetch(url);
                const data = await res.json();
                if (data.status === 200) {
                    resolve(data.payload);
                } else {
                    reject(data);
                }
			} else if (query.includes('situation')) {	
			//let f = query.split('/')[2];
			//let url = this.#API_URL + `/api/situation/situation/get?id=${f}`;
			
				
				let token = localStorage.getItem('tokenAPI');
                let headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);	
                const res = await fetch(url+'&token='+token,{
                method: "GET",
				headers: headers});
                const data = await res.json();				
                if (data.status === 200) {
                    resolve(data.payload.list['0']);
                } else {
                    reject(data);
                }	
            
            				
			} else if (query.includes('qa')) {	
			let f = query.split('/')[2];
				
            let token = localStorage.getItem('tokenAPI');
            let arg = {};
            if(token !== undefined){
                let headers = new Headers();
                headers.append('Authorization', 'Bearer ' + token);
                arg.headers = headers;
            }

			let url = this.#API_URL + `/api/qa/qa/get?id=${f}`;
                const res = await fetch(url, arg);
                const data = await res.json();				
                if (data.status === 200) {
                    resolve(data.payload);
                } else {
                    reject(data);
                }	
            
            } else {
                if (token) url += `&token=${token}`;
                const res = await fetch(url);
                const data = await res.json();
                if (data.status === 200) {
                    resolve(data.payload.list['0']);
                } else {
                    reject(data);
                }
            }
        });
    }

    getArticleQa(query) {
        return asyncCatcher(async (resolve, reject) => {
            const queryArray = query.slice(1, query.length).split('/');
			let url = this.#API_URL + `/api/qa/qa/list?codex_id=-1&getcontent=1`;
			if (queryArray[2] !== undefined) {
				url = this.#API_URL + `/api/qa/qa/list?codex_id=${queryArray[2]}&getcontent=1`;
			} else if (queryArray[1] !== undefined && parseInt(queryArray[1])) {
				url = this.#API_URL + `/api/qa/qa/list?codex_id=${queryArray[1]}&getcontent=1`;
			}
            let arg = {url, payload: 'list', pop: true};
			let token = localStorage.getItem('tokenAPI');
            if(token !== undefined)arg.token = token;
            const [data, err] = await fetchData(arg);
            if (err) reject(err);
            else {
                if (data?.length) {
                    resolve(data);
                } else {
                    resolve(null);
                }
            };
        });
    }

    getDocData({id /*, token*/}) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + `/doc/document/get?id=${id}`;
			
			let token = localStorage.getItem('tokenAPI');
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token);
            const res = await fetch(url, {
				method: 'GET',
			    headers: headers
			});
			
            const data = await res.json();
            if (data.status === 200) {
                const pdfUrl = this.#API_URL + `/doc/document/convert?file-id=${data.payload.id_file}#toolbar=0&navpanes=0`;
                resolve({
                    ...data.payload,
                    pdfUrl 
                });
            } else {
                reject(data);
            }
        });
    }

    generateDoc({token, data}) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + '/doc/document/generate';
            const headers = new Headers();
            headers.append('Authorization', 'Bearer ' + token);
            const res = await fetch(url, {
                method: 'POST',
                body: data,
                headers
            });
            const blob = await res.blob();
            resolve(blob);
        });
    }

    getRegions() {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + '/regions/region/list';
            const [data, err] = await fetchData({ url, payload: 'list' });
            if (err) reject(err);
            else resolve(data);
        });
    }

    getRegionSubjects(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + `/subjects/subject/list?region_id=${id}`;
            const [data, err] = await fetchData({ url });
            if (err) reject(err);
            else resolve(data);
        });
    }

    getCodexes(query) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + utils.apiUrlFromQuery(query);
            const isPlenum = query.includes('plenum');
            const listName = isPlenum ? 'array' : 'list'; 
            const [data, err] = await fetchData({url, payload: listName, pop: !isPlenum});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getSingleCodex(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + `/api/codex/codex/get?id=${id}`;
			//	https://api.my-advo.cat/api/codex/codex/list?exclude=s&pid=5902
            let arg = { url };
			let token = localStorage.getItem('tokenAPI');
            if(token !== undefined)arg.token = token;

            const [data, err] = await fetchData(arg);
            if (err) reject(err);
            else resolve(data);
        });
    }
	
    getSingleCodexContent(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + 
            `/api/codex/codex/list?exclude=s&pid=${id}`;
            const [data, err] = await fetchData({url, payload: 'list'/*, pop: true*/});
            if (err) reject(err);
            else resolve(data);
        });
    }	

    getCodexNodeContent(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + 
            `/api/codex/codex/list?recursive=1&exclude=p&pid=${id}`;
            const [data, err] = await fetchData({url, payload: 'list', pop: true});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getCodexNodeNoContent(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + 
            `/api/codex/codex/list?recursive=1&out=2&exclude=p&pid=${id}`;
            const [data, err] = await fetchData({url, payload: 'list', pop: true});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getBreadcrumbs(id) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + `/api/codex/codex/breadcrumb-codex?id=${id}&title=1`;
            const [data, err] = await fetchData({ url });
            if (err) reject(err);
            else resolve(data);
        });
    }

    getLawyerRegions() {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + '/api/is/is/pos-states';
            const [data, err] = await fetchData({url, payload: 'array', pop: true});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getLawyersByState({id, type}) {
        return asyncCatcher(async (resolve, reject) => {
            const lawyerType = type || 'jurist';
            const url = this.#API_URL + `/api/is/is/juristlistbystate?id=${id}&type=${lawyerType}`;
            const [data, err] = await fetchData({url, payload: 'array'});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getLawyer({id, token}) {
        return asyncCatcher(async (resolve, reject) => {
            const url = this.#API_URL + `/api/is/is/getjurist?id=${id}`;
            const [data, err] = await fetchData({url, token});
            if (err) reject(err);
            else resolve(data);
        });
    }

    getNews(args) {
        const page = args?.page || 1;
        const limit = args?.limit || 10; 
        
        return asyncCatcher(async (resolve, reject) => {
            let url = this.#API_URL;
            if (typeof(args) === 'string') {
                url += `/news/get?alias=${args}`;
            } else {
                url += `/news/list?page=${page}&limit=${limit}`;
            }
            const [data, err] = await fetchData({ url });
            if (err) reject(err);
            if (data) {
                if (typeof(args) === 'string') {
                    resolve(data);
                } else {
                    resolve({
                        news: data?.news?.length ? data?.news : null,
                        pagesTotal: data?.countPage,
                        newsTotal: data?.count_news
                    });
                }
            }
        });
    }
	
    getSearchItems(args) {
        const page = args.page || 1;
        const limit = args.limit || 10; 
        const target = args.target || "all";
		const q = args.q || "адвокат";
        //let url = global.apiLink + '/search?q=' + q + '&page='+page+'&target='+target;
		
		
		
		
        return asyncCatcher(async (resolve, reject) => {
           // let url = this.#API_URL + '/api/search?q=' + q + '&page='+page+'&target='+target;
			let url = 'https://api.my-advo.cat/api/search?q=' + q + '&page='+page+'&target='+target;
           /* if (typeof(args) === 'string') {
                url += `/news/get?alias=${args}`;
            } else {
                url += `/news/list?page=${page}&limit=${limit}`;
            }*/

            let token = localStorage.getItem("tokenAPI");

            const [data, err] = await fetchData({url, token });
            if (err) reject(err);
            if (data) {
                if (typeof(args) === 'string') {
                    resolve(data);
                } else {
                    resolve({
                        searchResultList: data.list?.length ? data.list : null,
                        pagesTotal: Math.ceil(Number(data.count)/10), //data?.countPage,
                        itemsTotal: Number(data.count),
						q: data.searched,
						
						
                    });
                }
            }
        });
    }	
	
    getBlockNews(args) {
        const page = args?.page || 1;
        const limit = args?.limit || 10; 
        
        return asyncCatcher(async (resolve, reject) => {
            let url = this.#API_URL;
            if (typeof(args) === 'string') {
                url += `/news/get?alias=${args}`;
            } else {
                url += `/news/block-list?page=${page}&limit=${limit}`;
            }
            const [data, err] = await fetchData({ url });
            if (err) reject(err);
            if (data) {
                if (typeof(args) === 'string') {
                    resolve(data);
                } else {
                    resolve({
                        news: data?.news?.length ? data?.news : null,
                       // pagesTotal: data?.countPage,
                       // newsTotal: data?.count_news
                    });
                }
            }
        });
    }	

    chat() {
        return new Chat(this.#config); 
    }

    room(config) {
        return new Room({
            ...this.#config,
            ...config
        });
    }
}

export const initializeApi = (config) => {
    return new FemidaApi(config);
};