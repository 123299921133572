import React, { useCallback, useRef, useState } from "react"
import './styles.scss';
import { motion } from "framer-motion";
import { fadeAnimation } from "../../../utils/animationSetting";

const Image = (props) => {

    const {children, src, alt} = props;
    const [fullView, setFullView] = useState(false);

    return (
        <>
        <div className="article-image-c">
            <img 
            src={src} 
            alt={alt}
            // onClick={() => setFullView(true)} 
            className="article-image" />
            {
                fullView &&
                <motion.div {...fadeAnimation} 
                className="article-image-full-c"
                onClick={() => setFullView(false)}>
                    <img 
                    src={src} 
                    alt={alt}
                    className="article-image" />
                </motion.div>
            }
        </div>
        <small className="text-secondary d-block text-center my-1">{children}</small>
        </>
    );
}

export default Image;