import React, { useEffect } from "react"
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";

const Page = (props) => {
    const {children} = props;

    return (
        <motion.div {...fadeAnimation} {...props}>
            {children}
        </motion.div>
    );
}

export default Page;