import React, { startTransition, useEffect, useState, useContext } from "react";
import Page from '../../Page';
import PageHeader from "../../PageHeader";
import Article from "../../Article";
import Helmet from "react-helmet"

const RequisitesPage = () => {

    return (
	<>
		<Helmet>
			<meta name="robots" content="noindex, nofollow"/>
		</Helmet>
        <Page>
			<PageHeader>Реквизиты</PageHeader> 
            <Article>
				<div className="col-12 mt-3 mb-3 about_wrapper section-padding overflow-hidden">
					<div className="row g-0">
						<noindex>
							<div className="col-12 mt-1">
                                <div id="ce_mainContainer" style={{maxWidth:"100%"}}>
								    <p><b>Полное наименование:</b> Общество с ограниченной ответственностью "ПРОГРАМВЕБ"</p>
									<p><b>Сокращенное наименование:</b> ООО "ПРОГРАМВЕБ"</p>
									<p><b>Юридический адрес:</b> 454080, Россия, Челябинская обл., г. Челябинск, ул. Труда, д.156, помещ. 23, офис 1/1</p>
									<p><b>Почтовый адрес:</b> 454080, Россия, Челябинская обл., г. Челябинск, ул. Труда, д.156, помещ. 23, офис 1/1</p>
									<p><b>Телефон:</b> 8 (800) 550 89 72 (бесплатный, круглосуточный)</p>
									<p><b>ИНН/КПП:</b> 7453339330/745301001</p>
									<p><b>ОГРН:</b> 1217400004623</p>
									<p><b>Директор:</b> Криушичев Александр Георгиевич, Действует на основании Устава</p>
								</div>
							</div>
						</noindex>
					</div>
				</div>	
			</Article>
        </Page>
		</>
    );
}

export default RequisitesPage;