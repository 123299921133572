import React, { startTransition, useContext, useEffect, useState } from "react";
import Page from '../../Page';
import PageHeader from '../../PageHeader';
import SearchBar from '../../SearchBar';
import CategoryList from "../../CategoryList";
import CategoryWrapper from "../../CategoryWrapper";
import CategoryCard from '../../CategoryCard';
import CategoryLine from '../../CategoryLine';
import { useLocation, useNavigate } from "react-router-dom";
import pageData from './data';
import { UserContext } from "../../../context/UserContext";
import PageSearchBar from "../../PageSearchBar";
import { CallCard2 } from "../../CallCard";

const CategoryPage = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const path = location.pathname.split('/')[1];
    const data = pageData[path];
    const {user} = useContext(UserContext);

    useEffect(() => {
        /*if (path === 'docs' && !user) {
            navigate('/login', { replace: true });
            return;
        } 
        if (path === 'doc' && !user) {
            navigate('/login', { replace: true });
            return;
        } 		/**/
    }, []);

    return (
        <Page>
            <PageHeader>{data?.name || ''}</PageHeader>
            {user ? <PageSearchBar sTarget={path} /> : <></>}
			{ /*<SearchBar />*/ }

            
			{path === 'situation' || path === 'docs'
			?
			<>
                <div className={"mt-4"}>
                    <CategoryWrapper>
                        {
                            data?.cards && data?.cards.map(item => <CategoryLine key={item.url} {...item} />)
                        }
                    </CategoryWrapper>
                </div>
                <div className="mt-5 contentpage-wrapper" dangerouslySetInnerHTML={{__html: data.description && data.description}}></div>
			</>
			:
			<>
                <div className={(path ==="doc"?"category-inner-wrapper " :"") + " mt-4"}>                    <CategoryList>
                        {
                            data?.cards && data?.cards.map(item => <CategoryCard key={item.url} {...item} />)
                        }
                    </CategoryList>
                </div>
            </>
			}

            <CallCard2 />
        </Page>
    );
}

export default CategoryPage;