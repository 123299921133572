import React from "react"
import { motion } from "framer-motion";
import { fadeAnimation, slideUpAnimation } from "../../utils/animationSetting";
import { ReactComponent as CloseIcon } from './close.svg';
import './styles.scss';

const SlideUp = (props) => {
    
    const {
        children, 
        title, 
        description, 
        close,
        darken,
        halfScreen
    } = props;
    let dragStart = 0;

    const handleDragStart = (e) => {
        dragStart = e.clientY;
    }

    const handleDragEnd  = (e) => {
        if (e.clientY - dragStart >= 400) {
            close();
        }
    }

    return (
        <motion.div
        {...fadeAnimation}
        className={`slide-up__wrap ${darken?'dark':''}`}>
            <motion.div 
            drag="y"
            dragConstraints={{top: 0, bottom: 0}}
            onDragStart={handleDragStart} 
            onDragEnd={handleDragEnd}
            className={`slide-up ${halfScreen ? 'half' : ''}`}
            {...slideUpAnimation}>
                <div className="d-flex justify-content-between">
                    <div className="row category-title">
                        <div className="cal-12 text-start">{title}</div>
                        {description ? 
                            <div className="cal-12 text-start text-danger mt-3 h6">{description}</div>
                        : <></>}
                        
                    </div>
                    <CloseIcon role="button" onClick={close} />
                </div>
                <div className="slide-up__content">
                    { children }
                </div>
            </motion.div>
        </motion.div>
    );
}

export default SlideUp;