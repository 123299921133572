import { AnimatePresence } from "framer-motion";
import FemidaLoader from "./FemidaLoader"
import React, { useEffect, useRef } from "react"
import { ReactComponent as DeclineIcon } from "./decline.svg";
import Peer from "peerjs";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { femida } from "../../../api/app";
import Person from './person.svg';
import { UserContext } from "../../../context/UserContext";
import CallScreen from "../../CallScreen";
import Notification from "../../Notification";
import Page from "../../Page";
import s from './s.module.scss';
import I from '../ChatPage/assets/icons';
import { ReactMediaRecorder } from "react-media-recorder";

var PagingPeer = null,
    callingProcess = {},
    onCallProcess = 0,//(0 - Ожидание, 1 - Дозвон, 2 - Нам ответили)
    MyRoomIdProcess = null,
    onStreamProcess = false,
    onCallTimerProcess = 0,
    onOperatorSearchingCounter = 0,
    PingIntervalId = {},
    FreeOperatorAvailableProcess = null,
    CordovaJS = {},
    Cordova_Navigator = {},
    MyPeer = null,
    Remote_Peer_id = null
;


const CallPage = (props) => {

    var self = {};
    const {user} = useContext(UserContext);
    //const [room, setRoom] = useState(null);

    const [VideoAvalable, setVideoAvalable] = useState(false);
    const [AudioAvalable, setAudioAvalable] = useState(false);

    const [MyPeerFrontend, setMyPeerFrontend] = useState(null);
    const [StatusCalling, setStatusCalling] = useState("");
    const [CallingPeer, setCallingPeer] = useState(null);
    const [MyPeerId, setMyPeerId] = useState(null);
    const [MyRoomId, setMyRoomId] = useState(null);
    const [notificationText, setNotificationText] = useState("");
    const [preloadText, setPreloadText] = useState("Регистрация на сервере...");
    const [onCall, setOnCall] = useState(false);
    const [onCallTimer, setOnCallTimer] = useState("00:00:00");
    const [FreeOperatorAvailable, setFreeOperatorAvailable] = useState(null);
    const [gotStream, setGotStream] = useState(false);
    //const [chatWarnings, setChatWarnings] = useState(null);
    //const [iteratPingInterval, setPingInterval] = useState(null);
    //const [CordovaMediaCapture, setCordovaMediaCapture] = useState(null);
    //const [mediaAttached, setMediaAttached] = useState(null);
    const navigate = useNavigate();
    const WebRTCStreamRef = useRef(null);
    const lsRef = useRef(null);
    const rsRef = useRef(null);
    useEffect(() => {
        self = this;
        if (!user) {navigate('/login', {replace: true});return;}
        console.log(user);

        let callOptions={
            host:"api.my-advo.cat",
            port:"2087",
            path:'/',
            config:{
                'iceServers': [
                    //{urls:'stun:stun01.sipphone.com'},
                    //{urls:'stun:stun.ekiga.net'},
                    //{urls:'stun:stun.fwdnet.net'},
                    //{urls:'stun:stun.ideasip.com'},
                    //{urls:'stun:stun.iptel.org'},
                    //{urls:'stun:stun.rixtelecom.se'},
                    //{urls:'stun:stun.schlund.de'},
                    //{urls:'stun:turn1.woodycart.ru:3478'},
                    //{urls:'turn:turn1.woodycart.ru:3478'},
                    //{
                        //username: 'turnuser1',
                        //credential: 'tu2pVDfcds',
                        //urls:'stuns:turn1.woodycart.ru:5349'
                    //}//,
                    {urls:'stun:stun1.l.google.com:19302'},
                    {urls:'stun:stun2.l.google.com:19302'},
                    //{urls:'stun:stun3.l.google.com:19302'},
                    //{urls:'stun:stunserver.org'},
                    //{urls:'stun:stun.softjoys.com'},
                    //{urls:'stun:stun.voiparound.com'},
                    //{urls:'stun:stun.voipbuster.com'},
                    //{urls:'stun:stun.voipstunt.com'},
                    //{urls:'stun:stun.voxgratia.org'},
                    //{urls:'stun:stun.xten.com'}
                ]
            },
            //secure: true,
            debug: 3
        };
        MyPeer = new Peer(callOptions);
        console.log("user");
        console.log(user);
        MyPeer.on('open', function (MyPeerId){
            RegisterPeerAbonement(MyPeerId);
            setMyPeerId(MyPeerId);
            console.log("MyPeer set on.open");
            console.log(MyPeerId);
        });
        //setMyPeer(peer);
        //MyPeer = peer;
        setMyPeerFrontend(MyPeer);


        console.log("mobileAndTabletCheck");
        console.log(window.mobileAndTabletCheck());

        ScanFreeOperatorPreCall();
        checkAudioVideo();

        document.addEventListener("deviceready", onDeviceReady, false);

        function onDeviceReady() {
            if (!window.cordova) {
              console.log("React Loaded");
            } else {
              console.log("Cordova Loaded");
              CordovaJS = window.cordova;
              Cordova_Navigator = navigator;
              //ScanFreeOperatorPreCall();
              checkAudioVideo();
            }
        }

    }, []);

    const ScanFreeOperatorPreCall = () => {
        console.log("ScanFreeOperatorPreCall");
        console.log("onCall: "+onCall);
        console.log("onCallProcess: "+onCallProcess);
        if(onCallProcess === 0){
            getOperator()
                .then(res=>res.json())
                .then(response =>{
                    if(!(response.payload.success)){
                        console.log(response.payload.success);
                        setFreeOperatorAvailable(false);
                        FreeOperatorAvailableProcess = false;
                    }else{
                        console.log(response.payload);
                        setFreeOperatorAvailable(response.payload);
                        FreeOperatorAvailableProcess = response.payload;
                        onOperatorSearchingCounter = 0;
                    }
                });
        }
        setTimeout(()=>{
            ScanFreeOperatorPreCall();
        }, 10000);
    }



    const CloseCallRoom = ( RoomId ) => {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + user.token);
        let self = this;
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/close-room/?room_id='+RoomId
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.room.status === 0){
                console.log("Комната Закрыта. room ID: "+response.payload.room.id);
            }else{
                console.log(response.payload.message);
                console.log("Ошибка закрытия комнаты");
            }/**/
        });
    }


    const TimerCounter = () => {
        console.log(onCallTimerProcess);
        //console.log(onCallTimer);
        if (onCallProcess > 0) {
            setTimeout(() => {
                let NowTime = Date.now();

                if(FreeOperatorAvailableProcess === false && onCallProcess === 1){
                    onOperatorSearchingCounter++;
                }else{
                    onOperatorSearchingCounter = 0;
                }
                console.log("Оператор не доступен: ", onOperatorSearchingCounter, " сек.");
                console.dir(MyPeer.connections);
                NowTime = timeFormat(NowTime - onCallTimerProcess + 1);
                setOnCallTimer(NowTime);

                if(onOperatorSearchingCounter > 10 && onCallProcess === 1){
                    disconnect();
                    setNotificationText("Не удалось дозвониться");
                    setTimeout(()=>setNotificationText(""), 4000);
                    return false;
                }
                TimerCounter();
            }, 1000);
          } else {
            onCallTimerProcess = 0; 
            setOnCallTimer("00:00:00");
          }
    }

    const getPereferialDevices = () => {
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
          } else {
            // List cameras and microphones.
            navigator.mediaDevices
              .enumerateDevices()
              .then((devices) => {
                devices.forEach((device) => {
                  console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                });
              })
              .catch((err) => {
                console.error(`${err.name}: ${err.message}`);
              });
          }
    }
    
    const checkAudioVideo = () => {
        console.log("CordovaJS");
        console.log(CordovaJS);

        let ThisPlatform = null;
        if(CordovaJS !== undefined)ThisPlatform = CordovaJS["platformId"];
        
            if(ThisPlatform === "android"){
                console.log("Платформа: ", ThisPlatform);
                console.log("Cordova_Navigator");
                console.log(Cordova_Navigator);
 
                //console.log(CaptureVideoOptions);

                // Ask the user for permission to access the microphone
                /*
                /**/

                //navigator.getMedia = Cordova_Navigator.getUserMedia;
                
/*
                CordovaJS.plugins.CameraStream.capture = function(data){
                    console.log("somthing weet wrong",data);
                    if(data)setVideoAvalable(true);
                    return false;
                }

                CordovaJS.plugins.CameraStream.startCapture('front');
    /**/
                // capture callback
                /*var captureSuccess = function(mediaFiles) {
                    setAudioAvalable(true);
                    MicrophoneRef.srcObject = mediaFiles[0].localURL;
                    //$scope.sound.filePath = mediaFiles[0].fullPath;
                    console.dir(mediaFiles);

                };
                
                // capture error callback
                var captureError = function(error) {
                    if(error.code == 20) setAudioAvalable(true);
                    else setAudioAvalable(false);
                    console.log(error);
                    //console.log('Error code: ' + error.code, null, 'Capture Error');
                };
    
                var CPDoptions = { limit: 1 };
                // start audio capture
                Cordova_Plugin_Device.capture.captureAudio(
                    captureSuccess, 
                    captureError, 
                    CPDoptions
                );
                /**/
/**/
                    window.audioinput.getMicrophonePermission(function(hasPermission, message) {
                        if (hasPermission) setAudioAvalable(true);
                        else setAudioAvalable(false);
                        if(message !== undefined)console.log(message);
                    });
            
                    try {
                        let permissionC = CordovaJS.plugins.permissions;
                        permissionC.requestPermission(
                            permissionC.CAMERA,
                            (status)=>{
                                if ( status.hasPermission ) {
                                    console.log("Yes :D ");
                                    setVideoAvalable(true);
                                } else {
                                    console.warn("No :( ");
                                    setVideoAvalable(false);
                                }
                            },
                            (err)=>{
                                console.log("requestPermission CAMERA err ");
                                console.warn(err);
                            }
                        );
                        /*permissionC.checkPermission(permissionC.CAMERA, 
                            function( status ){
                                if ( status.hasPermission ) {
                                console.log("Yes :D ");
                                navigator.mediaDevices.getUserMedia({video: true}, function() {
                                    console.log("setVideoAvalable",true);
                                    setVideoAvalable(true);
                                }, function(err) {
                                    console.log("setVideoAvalable",false);
                                    setVideoAvalable(false);
                                    console.warn(err);
                                });
                                }
                                else {
                                console.warn("No :( ");
                                setVideoAvalable(false);
                                }
                            });/**/
                        
                        console.log("Check for WEBRTC plugin",navigator.getMedia);
                        getPereferialDevices();


                        /*navigator.getMedia({video: { facingMode: "user" }})
                            .then((stream) => {
                                console.log("setVideoAvalable",true);
                                if(WebRTCStreamRef.current) WebRTCStreamRef.current.srcObject = stream;
                                setVideoAvalable(true);
                            })
                            .catch((err) => {
                                console.log("setVideoAvalable",false);
                                setVideoAvalable(false);
                                console.warn(err);
                            });
                        navigator.getMedia({audio: true})
                        .then((stream) => {
                            console.log("setAudioAvalable",true);
                            setAudioAvalable(true);
                        })
                        .catch((err) => {
                            console.log("setAudioAvalable",false);
                            setAudioAvalable(false);
                            console.warn(err);
                        });/**/
                        
                    } catch (error) {
                        console.warn(error);
                    }

            }else{
            
                navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia
                );
                try {
                    console.log("Check for WEBRTC plugin");
                    console.dir(navigator.getMedia);
                    navigator.getMedia({ audio: true },
                        () => {
                            console.log("setAudioAvalable",true);
                            setAudioAvalable(true);
                        },
                        (err) => {
                            console.error(err);
                            console.log("setAudioAvalable",false);
                            setAudioAvalable(false);
                        });
                    navigator.getMedia({video: true}, function() {
                        console.log("setVideoAvalable",true);
                        setVideoAvalable(true);
                    }, function(err) {
                        console.log("setVideoAvalable",false);
                        setVideoAvalable(false);
                        console.warn(err);
                    });
                } catch (error) {
                    console.warn(error);
                }
    
            }
        return true;
    }

    const [notification, setNotification] = useState(null);
    const hideNotification = () => setNotification(null);
    const getOperator = () => {
        let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + user.token);
            let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/get-free-operator/';
            return fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",
            });
    };
    const setCallKeeperCantAnswer = (peer_id) => {
        if (!peer_id)return false;
        let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + user.token);
            let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/set-call-keeper-cant-answer/?peer_id='+peer_id;
            return fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",
            });
    };


    const call = async (type) => {
        if(onCallProcess === 0 && onCallTimerProcess === 0){
            console.log("TimerCounter");
            onCallProcess = 1;
            onCallTimerProcess = Date.now();
            setOnCallTimer("00:00:00");
            onOperatorSearchingCounter = 0;
            TimerCounter();
        }
        onCallProcess = 1;
        console.log("setOnCall 2");
        console.log(onCall);
        setOnCall(true);
        console.log("onCallProcess 2 1");
        console.log(onCallProcess);
        console.log("onCallProcess 2 2");
        console.log(onCallProcess);
        
        let peer_id = null;
        let userId = null;
        
        let mediaStream = null;
        let UserMedia = {
            audio: true,
            video: false
        };

        if(type === "video"){
            UserMedia = {
                audio: true,
                video: { facingMode: "user" }
            };
        }

        let ThisPlatform = null;

        if(CordovaJS["platformId"])ThisPlatform = CordovaJS["platformId"];
            
        if(ThisPlatform === "android"){
            navigator.getMedia = navigator.mediaDevices.getUserMedia; 
            console.log("navigator.getMedia");
            console.log(navigator.getMedia);
            console.log("navigator.mediaDevices.getUserMedia");
            console.log(navigator.mediaDevices.getUserMedia);
            try {
                console.log("try to get mediaStream with ", UserMedia);
                await navigator.getMedia(UserMedia)
                    .then((res)=>{mediaStream = res});

                console.log("try to get mediaStream 1. Result: ", mediaStream);
                if(mediaStream && lsRef.current) 
                    lsRef.current.srcObject = mediaStream;
            } catch (e) {
                console.log("e1");
                console.log(e);
                mediaStream = null;
            }
            console.log("try to get mediaStream 1_2. Result: ...");
        }else{
            navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia
            );
            console.log("navigator.getMedia");
            console.log(navigator.getMedia);
            try {
                    //UserMedia.audio = constraintsToChrome(UserMedia.audio);
                if(UserMedia.video !== false) UserMedia.video = true;
                await navigator.getMedia(UserMedia, 
                    (res)=>{
                        mediaStream = res;
                        console.log("try to get mediaStream 1. Result: ", res);
                        if(res && lsRef.current){
                            lsRef.current.srcObject = res;
                            console.dir(res);
                        }
                    },
                    (err)=>{
                        console.log("try to get mediaStream 1. Error: ", err);
                        mediaStream = null;
                    });

            } catch (e) {
                console.log("e1");
                console.log(e);
                mediaStream = null;
            }
        }


        try {
            setStatusCalling("Поиск свободного оператора");
            if(FreeOperatorAvailableProcess === false){

                let response = await getOperator().then(res=>res.json());
        
                if(!(await response.payload.success)){
                    console.log(response.payload.message);
                    setTimeout(()=>{
                        if(onCallProcess === 1)call(type);
                    }, 2000);
                    return;
                }

                if((await response) === undefined){
                    return;
                }

                peer_id = await response.payload.peer_id;
                userId = await response.payload.user_id;
            }else{
                peer_id = FreeOperatorAvailable.peer_id;
                userId = FreeOperatorAvailable.user_id;
            }
            console.log(onCallProcess);

            setStatusCalling("Оператор найден. <br /> Ждём ответа оператора...");

            console.log('i call to this '+peer_id);
            console.log('i call to user id '+userId);
            if(!peer_id){
                return;
            }
            Remote_Peer_id = peer_id;
            console.log(MyPeer);
            onOperatorSearchingCounter = 0;

        } catch(e) {
            console.log("e2");
            console.log(e);
        }

        try {



            setTimeout(()=>{
                let callOptions = {metadata: {"type":"media"}};
                console.log("What kind of media tream");
                console.log(mediaStream);
                callingProcess = MyPeer.call(peer_id, mediaStream, callOptions);
                console.log("callingProcess");
                console.log(callingProcess);
                callingProcess.on('stream', function (mediaStreamOperator) { //нам ответили, получим стрим
                    onCallProcess = 2;
                    setStatusCalling(" ");

                    setTimeout(()=>{
                        if (rsRef.current) rsRef.current.srcObject = mediaStreamOperator;
                        console.log('clientVideo & myVideo set stream');
                        console.log(mediaStreamOperator);
                        setGotStream(true);
                        onStreamProcess = true;
                        mediaStreamOperator.oninactive = ()=>{
                            console.log('mediaStreamOperator inactive');
                            disconnect();
                        }
                    }, 500);
                });
                callingProcess.on('close', function() {
                    setFreeOperatorAvailable(false);
                    FreeOperatorAvailableProcess = false;
                    console.log("CallPage - line 261 - MyPeer calling close");
                });
                
                callingProcess.on('error', function(e) {
                    setFreeOperatorAvailable(false);
                    FreeOperatorAvailableProcess = false;
                    console.log("CallPage - line 262 - MyPeer calling error");
                    console.log(e);
                });
                setCallingPeer(callingProcess);
            }, 500);


/*


            let dataOptions = {metadata: {"type":"data"}};
            console.log(MyPeer);
            PagingPeer = await MyPeer.connect(peer_id, dataOptions);
            //PagingPeer = MyPeer.connect(peer_id);
            console.log("PagingPeer");
            console.log(PagingPeer);

            PagingPeer.on('open', function(d) {
                console.log('Соединение установлено:');
                console.log(d);
                console.log(PagingPeer);

                PagingPeer.on('data', function(data) {0
                    console.log('Полученные данные:', data);
                    console.log(data);
                    console.log(typeof(data));
                    let response = JSON.parse(data); 
                    if(response.do !== undefined){
                        switch (response.do){
                            case 'makeCall': 
                                    setMyRoomId(response.room_id);
                                    MyRoomIdProcess = response.room_id;
                                    console.log("Получен room ID:" + response.room_id);
                                    console.log("mediaStream:");
                                    console.log(mediaStream);
                                    if(mediaStream == null){
                                        setFreeOperatorAvailable(false);
                                        FreeOperatorAvailableProcess = false;
                                        alert("Медиапоток не создан, попробуйте дать разрешения на микрофон и камеру");
                                        return false;
                                    }

                                    let callOptions = {metadata: {"type":"media"}};
                                    callingProcess = MyPeer.call(peer_id, mediaStream, callOptions);
                                // Если callingProcess Cannot read properties of undefined
                                // Проверяй mediaStream, скорее всего = null
                                    setTimeout(()=>{
                                        console.log("callingProcess");
                                        console.log(callingProcess);
                                        callingProcess.on('stream', function (mediaStreamOperator) { //нам ответили, получим стрим
                                            onCallProcess = 2;
                                            setStatusCalling(" ");

                                            if (rsRef.current) rsRef.current.srcObject = mediaStreamOperator;
                                            console.log('clientVideo & myVideo set stream');
                                            setGotStream(true);
                                            onStreamProcess = true;
                                        });
                                        callingProcess.on('close', function() {
                                            setFreeOperatorAvailable(false);
                                            FreeOperatorAvailableProcess = false;
                                            console.log("CallPage - line 261 - MyPeer calling close");
                                        });
                                        
                                        callingProcess.on('error', function(e) {
                                            setFreeOperatorAvailable(false);
                                            FreeOperatorAvailableProcess = false;
                                            console.log("CallPage - line 262 - MyPeer calling error");
                                            console.log(e);
                                        });
                                        setCallingPeer(callingProcess);
                                    }, 300);
                                break;
                            case 'closeRoom':
                                clearInterval(PingIntervalId);
                                setTimeout(()=>clearValues(), 300);
                                PagingPeer.close();
                                callingProcess.close();
                                break;
                            case 'postPong':    
                                    console.log(response);
                                break;
                        }
                    }
                });
                console.log(onCallProcess);
                console.log("creatingRoom...")
                setTimeout(()=>{
                    let sendData = JSON.stringify({
                        do: "createRoom", 
                        clientPeerId: MyPeerId, 
                        operatorPeerId: peer_id
                    });
                    console.log(sendData)
                    PagingPeer.send(sendData);
                    console.log("createdRoom.")

                    PingIntervalId = setInterval(function() {
                        if(PagingPeer !== null){
                            let sendData = JSON.stringify({do: "postPing"});
                            console.log(sendData)
                            PagingPeer.send(sendData);
                        }else clearInterval(PingIntervalId);
                        }, 5000);

                    setTimeout(()=>{
                        console.log("long dial");
                        console.log("onCallProcess");
                        console.log(onCallProcess);
                        console.log("MyRoomIdProcess");
                        console.log(MyRoomIdProcess);
                        console.log("onStreamProcess");
                        console.log(onStreamProcess);
                        if(
                            //MyRoomIdProcess !== null &&
                            onCallProcess === 1
                        ){
                            console.log("setCallKeeperCantAnswer");
                            setCallKeeperCantAnswer(peer_id)
                                .then(res => res.json())
                                .then(response => {
                                    if(!(response.payload.success)) {
                                        if(onCallProcess === 1) {
                                            console.log(response.payload);
                                            sendCloseRoom();
                                            setTimeout(()=>{
                                                callingProcess.close();

                                                call(type);
                                            }, 500);
                                        }
                                    }
                                });
                            }
                    }, 10000);
                }, 1000);




                PagingPeer.on('error', function(err) { 
                    //disconnect();
                    console.log("CallPage - line 278 - MyPeer PagingPeer error");
                    console.log(err);
                });
                PagingPeer.on('close', function(err) { 
                    console.log("CallPage - line 284 - MyPeer PagingPeer close");
                    //disconnect();
                    //clearInterval(PingIntervalId);
                });


                
            });/**/

        
            } catch(e) {
                console.log("e3");
                console.log(e);
            }
    }

    
    const sendCloseRoom = async () => {
        console.log("sendCloseRoom");
        /*if(Remote_Peer_id){
            console.log("Remote_Peer_id");
            console.log(Remote_Peer_id);
            let dataOptions = {metadata: {"type":"data"}};
            console.log(MyPeer);
            PagingPeer = await MyPeer.connect(Remote_Peer_id, dataOptions);
            Remote_Peer_id.then(()=>{
                let sendData = JSON.stringify({do: "closeRoom"});
                console.log(sendData)
                PagingPeer.send(sendData);
            });
        }/**/
        setTimeout(()=>clearValues(), 300);
    }

    const timeFormat = (ms) => {
        let num = (val) => {
                val = Math.floor(val);
                return val < 10 ? '0' + val : val;
            }
            , sec = ms / 1000
            , hours = sec / 3600  % 24
            , minutes = sec / 60 % 60
            , seconds = sec % 60;
    
        return num(hours) + ":" + num(minutes) + ":" + num(seconds);
    };
    
    const clearValues = () => {
        console.log("clearValues");
        setFreeOperatorAvailable(false);
        FreeOperatorAvailableProcess = false;
        setGotStream(false);
        setOnCall(false);
        setMyRoomId(null);
        onCallProcess = 0;
        onStreamProcess = false;
        MyRoomIdProcess = null;
        //PagingPeer = null;
    }


    const RegisterPeerAbonement = (peerId)=>{
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + user.token);
        let peer_fetch_Url = 'https://api.my-advo.cat/api/call/call/add-abonent/?peer_id='+peerId;
        fetch(peer_fetch_Url, {
            'headers': headers, 
            'method': "GET",
        })
        .then(res => res.json())
        .then((response) => {
            if(response.payload.success === true){
                console.log(response.payload.message);
                console.log("Получен абонентский номер");
            }else{
                console.log(response.payload.message);
                console.log("Ошибка получения абонентского номера");
            }
        });
    }


    const BlobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    const BlobUrltoWav = async (audioBlob) => {
        var timeInMs = Date.now();
        let blob = await fetch(audioBlob).then(r => r.blob());
        audioBlob = BlobToFile(blob, timeInMs+".wav");
        const audiofile = new File([audioBlob], "audiofile_"+timeInMs+".wav", { type: "audio/wav" });
        return audiofile;
    }

    const disconnect = ()=>{
        clearInterval(PingIntervalId);
        sendCloseRoom();
        onCallTimerProcess = 0;
        setOnCallTimer("00:00:00");
        onOperatorSearchingCounter = 0;
        setTimeout(()=>{
            //if(PagingPeer !== null)PagingPeer.close();
            if(callingProcess !== null)callingProcess.close();
        }, 300);

    }

    
    const SendAudioMessage = async (audioBlob) => {
        setMyPeerId(null);
        setPreloadText("Отправка аудиосообщения и создание обращения...");
            var timeInMs = Date.now();

            let date = new Date(timeInMs);
            let dateText = date.getDate()+"."+
                (date.getMonth()+1)+"."+
                date.getFullYear()+" "+
                date.getHours()+":"+
                date.getMinutes()+":"+
                date.getSeconds();

            console.log(timeInMs);
            let roomtitle = `Аудиосообщение - `+dateText;
            let roommessage = `Вопрос в записи`;

            let body = new FormData();
            body.append('title', roomtitle);
            body.append('message', roommessage);

            console.log(roomtitle);
            console.log(roommessage);
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + user.token);
            fetch('https://api.my-advo.cat/api/chat/chat/add-room', {
                headers, 
                'method': "POST",
                'body': body,
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result.status !== 200) {
                            throw new Error(result.status)
                        }
                        if (result.payload) {
                            let RoomIdAudioMessage = result.payload.roomid;
                            BlobUrltoWav(audioBlob).then(async (audiofile)=>{
                                    console.log("blob CREATED");
                                    let url = 'https://api.my-advo.cat/api/chat/chat/upload-file-to-room?room_id='+RoomIdAudioMessage;
                                    let body = new FormData();
                                    let ThisPlatform = null;
                                    if(CordovaJS["platformId"])ThisPlatform = CordovaJS["platformId"];
                                        
                                    if(ThisPlatform === "android"){
                                        let blob = await fetch(audioBlob).then(r => r.blob());
                                        blob.lastModifiedDate = new Date();
                                        var timeInMs = Date.now();
                                        blob.name = "audiofile_"+timeInMs+".wav";
                                        blob.filename = "audiofile_"+timeInMs+".wav";
                                        console.log("file blob");
                                        console.log(blob);
                                        body.set("file", blob, blob.filename);
                                        //formData.append('file', blob); // добавляем файл в объект FormData
                                    } else {
                                        body.append('file', audiofile); // добавляем файл в объект FormData
                                    }




                                    //body.append('file', audiofile);
                                    fetch(url, {
                                        'headers': headers, 
                                        'method': "POST",
                                        'body': body,
                                    })
                                    
                                    .then(e=>{
                                        console.log(e);
                                        return e.json();
                                    })
                                    .then(function(e){
                                        console.log("try blob SEND");
                                        console.log(e);
                                        if(e.status !== 200){
                                            alert("Файл не отправлен");
                                        }else{
                                            setTimeout(()=>{
                                                console.log("MakeRedirect");
                                                window.location.href = '/chat/'+RoomIdAudioMessage;
                                            }, 500);
                                        }
                                    })
                                    .catch(function (e) {
                                        if (e.message === "401") {
                                            window.location.href = '/login';
                                        }
                                        console.log(e);
                                    });
                            });
                        }
                    }
                )
                .catch(function (e) {
                    if (e.message === "401") {
                        window.location.href = '/login';
                    }
                });
        }
    

    let Style_wrapper = {
        minHeight: "100vh",
        backgroundColor: "#2B3A4C",
        margin:"0 -1rem",
        paddingBottom: "50px"
    };

    let Style_person = {
        margin: "15% calc(50% - 85px) 5%"
    };

    let Style_callkbtn1 = {
        backgroundColor: "rgb(49, 182, 37)",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "100px",
        padding: "12px 15px",
        textAlign: "center",
        width: "55px",
        float: "left",
        //margin: "0 calc(50% - 110px)",
    };

    let Style_callkbtn2 = {
        backgroundColor: "rgb(49, 182, 37)",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "100px",
        padding: "12px 15px",
        textAlign: "center",
        width: "220px",
        float: "left",
        marginLeft: "15px",
        //margin: "0 calc(50% - 110px)",
    };

    let Style_callkbtn2_2 = {
        backgroundColor: "#dddddd",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "100px",
        padding: "12px 15px",
        textAlign: "center",
        width: "220px",
        float: "left",
        marginLeft: "15px",
        //margin: "0 calc(50% - 110px)",
    };
    let Style_callkbtn3 = {
        backgroundColor: "rgb(4, 182, 245)",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "100px",
        padding: "12px 15px",
        textAlign: "center",
        width: "240px",
        margin: "auto",
        color: "#ffffff",
    };

    let Style_callkbtn4_1 = {
        backgroundColor: "rgb(4, 182, 245)",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "100px 0 0 100px",
        padding: "12px 15px",
        textAlign: "center",
        width: "60px",
        float: "left",
        //margin: "0 calc(50% - 110px)",
    };

    let Style_callkbtn4_2 = {
        backgroundColor: "rgb(4, 182, 245)",
        fontWeight: "500",
        letterSpacing: "0.05rem",
        borderRadius: "0 100px 100px 0",
        padding: "12px 15px",
        textAlign: "center",
        width: "60px",
        float: "left",
        //margin: "0 calc(50% - 110px)",
    };

    let Style_callkbtn_wrapper = {
        height: "55px",
        width: "calc(85px + 220px)",
        margin: "auto",
    };

    let Style_btn_position = {
        textAlign: "center",
        width: "150px",
        margin: "0 calc(50% - 75px)"
    };

    return (
            <div style={Style_wrapper}>
                <div class="absolute w-full h-full left-0 top-0 grid place-items-center" >
                    <div class="text-white flex flex-col gap-5 items-center px-3">
                    {onCall ? 
                                        <>
                                            <div>
                                                <video className="call_stream_small" ref={lsRef} muted autoPlay playsInline />
                                                <video className="call_stream_full" ref={rsRef} autoPlay playsInline />
                                                
                                                <div className="position-relative call_stream_wrapper">
                                                    <div class="flex flex-col text-center fw-bold items-center mb-4">
                                                        <div>{onCallTimer}</div>
                                                    </div>
                                                    { !gotStream && 
                                                        <div className={"call_stream_loader"/*s.loader*/}><FemidaLoader /> <p dangerouslySetInnerHTML={{__html:StatusCalling}} /></div> }
                                                    <div className={"call_stream_controls"/*s.controls*/}>
                                                        <DeclineIcon onClick={() => disconnect()} className={s.decline} />
                                                    </div>
                                                </div>
                                            </div> 
                                        </>
                    :
                    <>
                        <img src={Person} alt="" style={Style_person}/>
                        <div class="text-center max-w-[500px]">
                            <h1 class="font-medium tracking-wide text-2xl mb-3">Позвонить</h1><small class="text-gray-300 font-light block mb-2">
                            {(FreeOperatorAvailable === null) && <div class="flex items-center justify-center"><span>Получение статуса операторов...</span></div>}
                            </small>
                            <p class="text-gray-300 font-light tracking-wide">Получите совет по видеозвонку о том, как действовать в экстренной правовой ситуации от ведущих адвокатов и юристов России.</p>
                        </div>
                        <div class="flex flex-col">
                            {MyPeerId === null
                            ? 
                                <>
                                    <div class="flex flex-col text-center items-center mb-4">
                                        {preloadText}
                                    </div>
                                </>
                            :
                                <>
                                    {FreeOperatorAvailable 
                                    ? 
                                        <>
                                            <div class="text-center items-center mb-4" style={Style_callkbtn_wrapper}>
                                                {AudioAvalable ?
                                                <>
                                                    <div onClick={(e) => {e.preventDefault();call('audio');}} role="button" style={Style_callkbtn1} class="inline-flex items-center gap-2 mb-4 select-none s_callbtn__Y8sl2">{/*<span>Позвонить нам</span>/**/}<svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 5.88889C2 4.85749 2.40972 3.86834 3.13903 3.13903C3.86834 2.40972 4.85749 2 5.88889 2H12.2667C12.6746 2.00031 13.0721 2.12891 13.4029 2.3676C13.7337 2.60628 13.9811 2.94297 14.11 3.33L17.0228 12.0664C17.1704 12.5104 17.1529 12.9927 16.9736 13.4249C16.7943 13.857 16.4652 14.21 16.0467 14.4192L11.6581 16.6164C13.8092 21.3774 17.6226 25.1908 22.3836 27.3419L24.5808 22.9533C24.7899 22.5348 25.143 22.2057 25.5751 22.0264C26.0073 21.8471 26.4896 21.8296 26.9336 21.9772L35.67 24.89C36.0573 25.019 36.3943 25.2667 36.633 25.5979C36.8717 25.9291 37.0001 26.327 37 26.7353V33.1111C37 34.1425 36.5903 35.1317 35.861 35.861C35.1317 36.5903 34.1425 37 33.1111 37H31.1667C15.0589 37 2 23.9411 2 7.83333V5.88889Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                    {VideoAvalable ? <div onClick={(e) => {e.preventDefault();call('video');}} role="button" style={Style_callkbtn2} class="inline-flex items-center gap-2 select-none s_callbtn__Y8sl2">
                                                        <span> Видеозвонок </span> 
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" height="28" width="28">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                                        </svg>
                                                    </div> : <>
                                                    <div style={Style_callkbtn2_2} class="inline-flex items-center gap-2 select-none s_callbtn__Y8sl2">
                                                        <span> Камера не доступна </span>
                                                    </div>
                                                    </>}
                                                </>:<>
                                                    <div class="flex flex-col text-center fw-bold items-center mb-4">
                                                        Микрофон не доступен
                                                    </div>
                                                </>}
                                            </div>
                                        </>
                                    : 
                                        <>
                                            <div class="flex flex-col text-center fw-bold items-center mb-4">
                                                Нет свободных операторов...
                                            </div>
                                        </>
                                    }
                                    {AudioAvalable && <>
                                        <ReactMediaRecorder
                                            audio
                                            render={({ status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
                                                return(
                                                                <><div class="text-center items-center mb-4" style={Style_callkbtn_wrapper}>
                                                                        {status!=="recording" ? <>
                                                                            {status!=="stopped" && <><button style={Style_callkbtn3} onClick={startRecording} id="start" className="btn btn-biryza btn-biryza-mod" title="Начать запись аудиосообщения" type="button">
                                                                                &nbsp; Аудиосообщение&nbsp;<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" fill="none"><title>Начать запись аудиосообщения</title><g id="icomoon-ignore"></g>
                                                                                    <path fill="#FFFFFF" d="M240 352c44.183 0 80-35.817 80-80v-192c0-44.183-35.817-80-80-80s-80 35.817-80 80v192c0 44.183 35.818 80 80 80zM352 224v48c0 61.855-50.145 112-112 112s-112-50.145-112-112v-48h-32v48c0 74.119 56.002 135.15 128 143.11v64.89h-64v32h160v-32h-64v-64.89c71.997-7.96 128-68.991 128-143.11v-48h-32z"></path>
                                                                                </svg>&nbsp;
                                                                            </button></>}
                                                                        </>
                                                                        :
                                                                        <><button style={Style_callkbtn3} onClick={() => {stopRecording(); setNotificationText("Запись готова к отправке");setTimeout(()=>setNotificationText(null), 6000);}} id="stop" className="btn btn-biryza btn-biryza-mod" title="Остановить запись аудиосообщения" type="button">
                                                                            &nbsp;Остановить запись&nbsp;<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -20 352 460"><title>Остановить запись аудиосообщения</title><g id="icomoon-ignore"></g>
                                                                            <path fill="#FFFFFF" d="M67.75 236.25l-25.25 25.25c-6.75-16.5-10.5-34.5-10.5-53.5v-32c0-8.75 7.25-16 16-16s16 7.25 16 16v32c0 9.75 1.5 19.25 3.75 28.25zM346.25 85.75l-90.25 90.25v32c0 44-36 80-80 80-9.5 0-18.75-1.75-27.25-4.75l-24 24c15.25 8 32.75 12.75 51.25 12.75 61.75 0 112-50.25 112-112v-32c0-8.75 7.25-16 16-16s16 7.25 16 16v32c0 74-56 135-128 143v33h64c8.75 0 16 7.25 16 16s-7.25 16-16 16h-160c-8.75 0-16-7.25-16-16s7.25-16 16-16h64v-33c-21.25-2.25-41.25-9.5-58.75-20.25l-63.5 63.5c-3.25 3.25-8.25 3.25-11.5 0l-20.5-20.5c-3.25-3.25-3.25-8.25 0-11.5l308.5-308.5c3.25-3.25 8.25-3.25 11.5 0l20.5 20.5c3.25 3.25 3.25 8.25 0 11.5zM251.25 52.75l-155.25 155.25v-128c0-44 36-80 80-80 34.5 0 64 22.25 75.25 52.75z"></path>
                                                                            </svg>&nbsp;
                                                                        </button> </>}

                                                                        
                                                                        {status==="stopped" ? <><audio src={mediaBlobUrl} controls style={{
                                                                            height: "50px",
                                                                            width: "50%",
                                                                            marginLeft: "10px",
                                                                        }} /><button style={Style_callkbtn4_1} onClick={() => {clearBlobUrl();setNotificationText("Запись удалена");setTimeout(()=>{setNotificationText(null);}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" title="Удалить запись" type="button">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 448 512">
                                                                        <path fill="#FFFFFF" d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/></svg>
                                                                        </button><button style={Style_callkbtn4_2} title="Отправить запись сообщением" onClick={() => {SendAudioMessage(mediaBlobUrl); setNotificationText("Запись отправленна");setTimeout(()=>{setNotificationText(null);}, 6000);}} id="sendaudio" className="btn btn-biryza btn-biryza-mod" type="button">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512">
                                                                            <path fill="#FFFFFF" d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                                                                        </button></>:<></>}
                                                                    </div>
                                                                </>
                                                );
                                            }}
                                        />
                                    </>}
                                </>
                            }


                            <div className="w-100">
                                <a href="tel:88006006254" style={Style_callkbtn3} id="call_by_phone" className="d-block btn btn-biryza btn-biryza-mod mx-auto mb-2" title="Позвонить по телефону">
                                        &nbsp; <span style={{fontSize: "17px"}}>8-800-600-62-54</span>&nbsp;<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                                        <path fill="#FFFFFF" d="M11 10c-1 1-1 2-2 2s-2-1-3-2-2-2-2-3 1-1 2-2-2-4-3-4-3 3-3 3c0 2 2.055 6.055 4 8s6 4 8 4c0 0 3-2 3-3s-3-4-4-3z"></path>
                                    </svg>&nbsp;
                                </a>
                                <a className="d-block btn btn-biryza btn-biryza-mod mx-auto mb-2" href="/" style={Style_callkbtn3}>
                                    <span>На главную </span>
                                    <svg height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 17.1767L5.33333 13.8041M5.33333 13.8041L17 2L28.6667 13.8041M5.33333 13.8041V30.6671C5.33333 31.1143 5.50893 31.5432 5.82149 31.8594C6.13405 32.1757 12.7365 31.8594 13.1785 31.8594C13.4911 31.5432 13.6667 31.1143 13.6667 30.6671V23.9219C13.6667 23.4746 13.8423 23.0457 14.1548 22.7295C14.4674 22.4132 14.8913 22.2356 15.3333 22.2356H18.6667C19.1087 22.2356 19.5326 22.4132 19.8452 22.7295C20.1577 23.0457 20.3333 23.4746 20.3333 23.9219V30.6671C20.3333 31.1143 20.5089 31.5432 20.8215 31.8594C-15.5714 31.8594 27.866 32.1757 28.1785 31.8594C28.4911 31.5432 28.6667 31.1143 28.6667 30.6671V13.8041M28.6667 13.8041L32 17.1767" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </a>
                            </div>
                            {/*<webrtc-video ref={WebRTCStreamRef}  alt="webrtc-video" />her webrtc-video/**/}
                        </div>
                    </>
                    }
                    </div>
                </div>
                <AnimatePresence>
                    {notificationText && <Notification>{notificationText}</Notification>}
                </AnimatePresence>
            </div>
    );
}

export default CallPage;