import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../../api/utils";
import { UserContext } from "../../../context/UserContext";

const Auth = (props) => {
    
    const {token} = useParams();
    const navigate = useNavigate();
    const {setUser} = useContext(UserContext);
    
    if (token) {
        (async () => {
            const url = process.env.REACT_APP_API_URL
            + '/api/user/get-profile';
            const [data, err] = await fetchData({url, token});
            if (err) navigate('/login');
            else {
                const user = {
                    ...data,
                    token,
                    id_user: data.id
                }
                setUser(user)
                localStorage.setItem('user_state', JSON.stringify(user))
                navigate('/profile')
            }
        })();
    } else {
        navigate('/login')
    }

    return <></>;
}

export default Auth;