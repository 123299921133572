export const slideInAnimation = {
    variants: {
        hidden: { y: "100%" },
        show: { y: 0 },
        close: { 
            opacity: 0,
            transition: {
                duration: 0.15
            } 
        }
    },
    initial: "hidden",
    animate: "show",
    exit: "close",
    transition: { type: "just" }
}

export const fadeAnimation = {
    variants: {
        hidden: { opacity: 0 },
        show: { 
            opacity: 1, 
        },
        close: { opacity: 0 }
    },
    transition: {
        duration: 0.175
    },
    initial: "hidden",
    animate: "show",
    exit: "close",
};

export const slideUpAnimation = {
    variants: {
        hidden: { y: "100%" },
        show: { y: 0 },
        close: { y: "100%" },
    },
    initial: "hidden",
    animate: "show",
    exit: "close",
    transition: { type: "just" }
}

export const slideDownAnimation = {
    variants: {
        hidden: { y: "-200%" },
        show: { y: 0 },
        close: { y: "-200%" },
    },
    transition: {
        duration: 0.1,
        type: "just"
    },
    initial: "hidden",
    animate: "show",
    exit: "close",
}

export const itemAnimation = (i) => ({
    variants: {
        hidden: { y: "100%", opacity: 0 },
        show: { y: 0, opacity: 1 },
    },
    transition: { 
        delay: i * 0.07,
        type: "just"
    },
    initial: "hidden",
    animate: "show",
})