import React, { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const CheckToken = (props) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);

    useEffect(() => {
        let id_user = localStorage.getItem('id_user');
        if (user !== null) {
            let headers = new Headers();
            headers.append('Authorization', 'Bearer ' + user.token);
            let peer_fetch_Url = 'https://api.my-advo.cat/api/token/check-token/';
            fetch(peer_fetch_Url, {
                'headers': headers, 
                'method': "GET",
            })
            .then(res => res.json())
            .then((response) => {
                //console.log(response);
                if(response.payload === true){
                    console.log("Токен нормальный");
                    let profile_fetch_Url = 'https://api.my-advo.cat/api/user/get-profile/';
                    fetch(profile_fetch_Url, {
                        'headers': headers, 
                        'method': "GET",
                    })
                    .then(res => res.json())
                    .then((response) => {
                        console.log(response);
                        if(response.status === 200){
                            localStorage.setItem('profile', JSON.stringify(response.payload));
                            console.log("Профиль загружен");
                        }
                    });
                }else{
                    console.log("Токен ненормальный");
                    if(id_user){
                        navigate("/");
                        localStorage.clear();
                    }
                }/**/
            });
        }else{
            if(id_user) localStorage.clear()
        }
    });

    return (
        <>
        </>
    );
}

export default CheckToken;