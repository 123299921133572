import React from "react"
import { useState } from "react";
import s from './s.module.scss';
import {ReactComponent as CrossIcon} from './cross.svg';
import I from '../../assets/icons';
import { formatBytes, shortenFileName, translateType } from "./utils";

const MediaPreview = ({data, setMediaAttached}) => {

    const [imgSrc, setImgSrc] = useState(null);
    let type = null;
    try {
        type = data?.type?.toString()?.split('/')[0];
    } catch (error) {
        console.log(error);
    }
    if (type === 'image') {
        const fr = new FileReader();
        fr.onloadend = () => setImgSrc(fr.result);
        fr.readAsDataURL(data);
    }

    return (
        <div className={s.container}>
            {
                type === 'image' ?
                <div 
                className={s.imgPreview}
                style={{backgroundImage: `url(${imgSrc})`}}>
                    <div className={s.shadow}>
                        <CrossIcon onClick={() => setMediaAttached(null)} />
                    </div>
                </div>
                :
                <div className="d-flex align-items-center gap-1 py-1">
                    <I.FileIcon className={s.fillBlue} />
                    <div className="d-flex flex-column flex-grow-1 me-2">
                        <div className="d-flex align-items-center gap-1">
                            <span className={s.semibold}>{translateType(type)}</span>
                            <span>[{formatBytes(data.size)}]</span>
                        </div>
                        <span>{shortenFileName(data?.name)}</span>
                    </div>
                    <CrossIcon
                     className={s.fillBlue}
                    onClick={() => setMediaAttached(null)} />
                </div>
            }
        </div>
    );
}

export default MediaPreview;