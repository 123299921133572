import { ReactComponent as AdministIcon } from './icons/administ.svg';
import { ReactComponent as UgolovIcon } from './icons/ugolov.svg';
import { ReactComponent as NalogIcon } from './icons/nalog.svg';
import { ReactComponent as DocsIcon } from './icons/docs.svg';
import { ReactComponent as GoverIcon } from './icons/gover.svg';

const categories = {
    administ: {
        name: 'Административные дела',
        Icon: AdministIcon,
        url: 'administ'
    },
    ugolov: {
        name: 'Уголовные дела',
        Icon: UgolovIcon,
        url: 'ugolov'
    },
    nalog: {
        name: 'Налоговые дела',
        Icon: NalogIcon,
        url: 'nalog'
    },
    gover: {
        name: 'Гражданские дела',
        Icon: GoverIcon,
        url: 'gover'
    },
    document: {
        name: 'Договора',
        Icon: DocsIcon,
        url: 'document'
    },
    goverDocsV2: {
        name: 'Документы<br/>по гражданским делам',
        Icon: GoverIcon,
        url: 'part10'
    },	
    administDocsV2: {
        name: 'Документы<br/>по административным делам',
        Icon: AdministIcon,
        url: 'part11'
    },
    nalogDocsV2: {
        name: 'Документы<br/>по налоговым делам',
        Icon: NalogIcon,
        url: 'part12'
    },
    ugolovDocsV2: {
        name: 'Документы<br/>по уголовным делам',
        Icon: UgolovIcon,
        url: 'part13'
    },
    documentDocsV2: {
        name: 'Договоры',
        Icon: DocsIcon,
        url: 'part52'
    },	
    administV2: {
        name: 'Практические рекомендации<br/>по КоАП РФ',
        Icon: AdministIcon,
        url: 'administ'
    },
    ugolovV2: {
        name: 'Практические рекомендации<br/>по УК РФ',
        Icon: UgolovIcon,
        url: 'ugolov'
    },
    nalogV2: {
        name: 'Практические рекомендации<br/>по НК РФ',
        Icon: NalogIcon,
        url: 'nalog'
    },
	
    administV3: {
        name: 'Практические рекомендации<br/>по КоАП РФ',
        Icon: AdministIcon,
        url: 'partkoap',
		partid: 3,
    },
    ugolovV3: {
        name: 'Практические рекомендации<br/>по УК РФ',
        Icon: UgolovIcon,
        url: 'partuk',
		partid: 1,
		
    },
    nalogV3: {
        name: 'Практические рекомендации<br/>по НК РФ',
        Icon: NalogIcon,
        url: 'partnalog',
		partid: 2,
    },	
	goverDocs: {
        name: 'Документы по гражданским делам',
        Icon: GoverIcon,
        url: 'gover'
    },
    administDocs: {
        name: 'Документы по административным делам',
        Icon: AdministIcon,
        url: 'administ'
    },
	nalogDocs: {
        name: 'Документы по налоговым делам',
        Icon: NalogIcon,
        url: 'nalog'
    },
    ugolovDocs: {
        name: 'Документы по уголовным делам',
        Icon: UgolovIcon,
        url: 'ugolov'
    },
    documentDocs: {
        name: 'Договоры',
        Icon: DocsIcon,
        url: 'document'
    },	
};


const qa = {
    name: 'Вопрос-ответ',
    cards: [
        categories.administ,
        categories.ugolov,
        categories.nalog,
    ],
	description:  ''
};
const situation = {
    name: 'Практические правовые рекомендации',
    cards: [
        categories.administV3,
        categories.ugolovV3,
        categories.nalogV3,
    ],
	description:  '<p>Раздел содержит практические разъяснения и рекомендации по действиям в экстреных ситуациях для граждан и предпринимателей по всем статьям административного, уголовного и налогового кодексов РФ.</p>'
                + '<p>Этот раздел ориентирован на простых граждан, не имеющих специальных юридических познаний.</p>'
                + '<p>Пользуясь данным разделом, Вы получите реальные и практические рекомендации по поведению в экстренных ситуациях, содержащие советы по действиям для достижения желаемого положительного результата при производстве по уголовным, административным делам.</p>'
                + '<p>Следуя данным рекомендациям, создается правильная правовая ситуация, облегчающая в последующем работу адвокатов по тому или иному делу следующего нашим рекомендациям гражданина или предпринимателя.</p>',
}
const cases = {
    name: 'Практические правовые рекомендации',
    cards: [
        categories.administV2,
        categories.ugolovV2,
        categories.nalogV2,
    ],
	description:  ''
};
const docs = {
    name: 'Формы документов',
    cards: [
        categories.goverDocsV2,
        categories.administDocsV2,
        categories.nalogDocsV2,
        categories.ugolovDocsV2,
        categories.documentDocsV2,
    ],
	description: '<p>В данном разделе можно просмотреть и скачать различные формы заявлений.</p>'
               + '<p>Заявления выводятся структурированным списком. При нажатии на документ открывается окно быстрого просмотра. При нажатии на «иконку» скачивания происходит загрузка данного документа на устройство пользователя.</p>'
               + '<p>В дальнейшем в данной вкладке будет реализована возможность генерации заявления по данным, введенным пользователем. Есть возможность заполнения заявления самостоятельно или оператором системы и пересылка по электронной почте, а также вывод его на печать.</p>',
}
const doc = {
    name: 'Формы документов',
    cards: [
        categories.administDocs,
        categories.ugolovDocs,
        categories.nalogDocs,
        categories.goverDocs,
        categories.document,
    ]
}


const obj = {
    qa,
    cases,
    docs,
	situation,
	doc
}
export default obj;