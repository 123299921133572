import React from "react"
import { Link } from "react-router-dom";
import './styles.scss';

const CategoryCard = (props) => {
    const {id, name, Icon, description, url} = props;
    console.log(props);
    console.log("id", id);
    return (
        <div className={"category-card-wrapper " + 
        ((id !== undefined) ? (((id+1) % 2)?" category-card-up":"category-card-down "): " text-center ") + " " +
        (Icon ? "" : " category-card-wh-120 ")}>
            {(((id+1) % 2)?<>
                <div className="category-card-arrow-left" ></div>
            </>:<>
                <div className="category-card-arrow-right"></div>
            </>)}
            <Link to={url} className="category-card">
                {Icon ? <Icon /> : <><br /></>}
                <b>{name}</b>
                {
                    description && 
                    <span className="text-secondary category-card__description">
                        {description}
                    </span>
                }
            </Link>
        </div>
    );
}

export default CategoryCard;