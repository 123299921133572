import React from "react"
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";

const CategoryContent = (props) => {

    const {title, num, type_node} = props;
	
	let stripTitle = title.toString();

    if((type_node === "article_item")){
        stripTitle = stripTitle.replace(/<\/?[^>]+>/gi, "");
    }
    //stripTitle = stripTitle.replace(/[<>&'"/]/g, '');
    stripTitle = stripTitle.replace(/\s{2,}/g, ' ');
    stripTitle = stripTitle.trim();

    let preffix = ((type_node === "article_item")?("<b>"+(num+1)+")</b> "):"");
            
    return (
	    <>
        
	    {stripTitle.length ?
            <p dangerouslySetInnerHTML={{__html: preffix+stripTitle}}></p>
		:
            <></>
        }
		</>
    );
}

export default CategoryContent;