import React from "react"
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { motion } from "framer-motion";
import { fadeAnimation } from "../../utils/animationSetting";

const SearchItem = (props) => {

    //const { id, title, description, codex_id, parent_url } = props;
    //let url_tmp = new String(codex_id);
	
    let TargetElement = "";
    let TargetElementClass = "";
	
    switch (props.target) {

        case "situation":
            TargetElement = "Практические рекомендации";
            TargetElementClass = "text-warning";		    
		break;
        case "qa":
            TargetElement = "Вопрос-ответ";
            TargetElementClass = "text-danger";
        break;
        case "docs":
            TargetElement = "Документы";
            TargetElementClass = "text-info";
        break;		
        case "codex":
		default:
            TargetElement = "Кодексы";
			TargetElementClass = "text-success";
        break;	
    }
	
    let elText = props.title.trim().replace(/^&nbsp;(.*)&nbsp;$/g, '$1');	
	
    return (
	    <>								 
        <Link to={props.link} id={"bcs_"+props.target+"_"+props.elid}>
            <motion.div {...fadeAnimation}
            className="d-flex bg-white rounded-3 py-3 px-3"> 
                <div className="flex-grow-1 d-flex flex-column gap-1">
                    <b>{TargetElement}: {elText}</b>
                    { 
                        props.content !=='' &&
                        <small className="text-secondary" dangerouslySetInnerHTML={{ __html: props.content}}></small> 
                    }
                </div>
                <div>
                    <ArrowIcon  color={TargetElementClass} />
                </div>
            </motion.div>
        </Link>
		</>
    );
}
export default SearchItem;