import React from "react"
import { Link } from "react-router-dom";
import image from './img.png';
import iconChat from './chat.svg';
import './styles.css';

const Offer = (props) => {
    return (
	<>

        <div role="button" className="p-3 mt-3 call-card msg-card" style={{background: "rgba(4, 182, 245, 0.08) url('/static/media/img.svg') no-repeat", border: "2px solid rgba(4, 182, 245, 0.16)"}}>
		    <div className="d-flex gap-2 call-card__block">
			    <div>
				    <img width="100px" alt="" src="https://my-advo.cat/assets/images/bg_callbtn.png" />
				</div>
				<div><h5 className="call-card__title">Ведущие юристы и адвокаты отвечают в чате на правовые вопросы.</h5></div>
				</div>
				<Link to="/chat/">
				<button className="d-flex gap-2 w-100 justify-content-center femida-btn">
				    <img src={iconChat} alt="Написать в чат" style={{transform: "rotateY(3.142rad)"}} />
					<span>Напишите нам</span>
				</button>
				</Link>

</div>			
	</>	
    );
}

export default Offer;